import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Paths } from "utils/constant";
import { circlePlusDarkPink } from "assestes";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Box, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { getWorkspaceNotes } from "services/workspace";
import EditIcon from "@material-ui/icons/Edit";

const WorkspaceNoteList = (props) => {
  const { item, workspaceNoteId, isEdit } = props;
  const history = useHistory();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [isExpanded, setExpanded] = useState(
    +_.get(location, "state.openWorkspaceId", -1) === item.id ||
      item.id === _.get(props, "match.params.id", -1)
  );
  const [noteList, setNoteList] = useState([]);
  const toggleNoteList = () => {
    setExpanded(!isExpanded);
  };

  const getItems = async () => {
    setLoader(true);
    const notes = await getWorkspaceNotes(item.id);
    setNoteList(notes.data);
    setLoader(false);
  };

  const handleCreateNote = () => {
    history.push(Paths.createWorkspaceNote.replace(":id", item.id));
  };
  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    if (workspaceNoteId === -1 || workspaceNoteId === "-1") getItems();
  }, [workspaceNoteId]);

  return (
    <>
      <>
        {!isEdit && (
          <>
            {isExpanded ? (
              <KeyboardArrowUpIcon
                className="pointer"
                onClick={toggleNoteList}
              />
            ) : (
              <KeyboardArrowDownIcon
                className="pointer"
                onClick={toggleNoteList}
              />
            )}
          </>
        )}
        <Box className="name pointer" onClick={toggleNoteList}>
          {_.get(item, "name", "")}
        </Box>
        {item.isAdmin && (
          <>
            <img
              src={circlePlusDarkPink}
              onClick={handleCreateNote}
              alt="circlePlusDarkPink"
              width={17}
            />
            <div className="manage-section">
              <MoreVertIcon fontSize="17px" style={{ color: "#454041" }} />
              <div
                className="menu-option"
                onClick={() =>
                  history.push(Paths.manageWorkspace.replace(":id", item.id))
                }
              >
                Manage
              </div>
            </div>
          </>
        )}
      </>
      {isExpanded &&
        !loader &&
        (noteList.length > 0 ? (
          <div className="workspace-note-list">
            {_.map(noteList, (note) => (
              <>
                <div
                  className={
                    "workspace-note-list-item" +
                    (+workspaceNoteId === note.id ? " active" : " a")
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    history.push(
                      Paths.teamNoteFullView
                        .replace(":id", item.id)
                        .replace(":noteId", note.id)
                    );
                  }}
                >
                  <div className="text">{note.title}</div>
                  {item.isAdmin && (
                    <EditIcon
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        history.push(
                          Paths.workspaceNote
                            .replace(":id", item.id)
                            .replace(":noteId", note.id)
                        );
                      }}
                      style={{ color: "rgb(142, 142, 147)" }}
                      fontSize="small"
                    ></EditIcon>
                  )}
                </div>
              </>
            ))}
          </div>
        ) : (
          <div className="workspace-note-list empty-msg">
            You currently have no notes in this workspace.{" "}
            {item.isAdmin && (
              <>
                <span onClick={handleCreateNote}>Create one</span> now.
              </>
            )}
          </div>
        ))}
      {isExpanded && loader && (
        <div className="workspace-note-list">
          <CircularProgress
            className="m-a d-f"
            size={"16px"}
            thickness={4.5}
            style={{ color: "#D967BE", display: "flex" }}
          ></CircularProgress>
        </div>
      )}
    </>
  );
};

export default WorkspaceNoteList;
