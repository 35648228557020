import { Menu } from "@material-ui/core";
import { pinkPlus, pinkPlusDark } from "assestes";
import { useState } from "react";
import { trackButtonClick } from "utils";
import "./style.css";

function CreateNewPlus(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const onClickNew = (selected) => {
    if (selected === "general") {
      props.setActiveTab("blogs");
      trackButtonClick("Create New Plus", "General");
    } else if (selected === "story") {
      props.setActiveTab("stories");
      trackButtonClick("Create New Plus", "stories");
    } else if (selected === "article") {
      props.setActiveTab("articles");
      trackButtonClick("Create New Plus", "articles");
    }
    props.setWritePad(true);
    props.setActiveNote(-1);
    handleClose();
  };
  return (
    <>
      <div
        className={`create-new-plus ${anchorEl ? "active" : ""}`}
        onClick={handleOpen}
      >
        {/* <svg
          version="1.1"
          baseProfile="full"
          xmlns="http://www.w3.org/2000/svg"
          id="svg-s-Ellipse_12"
          className="svgContainer"
        >
          <g>
            <g clip-path="url(#clip-s-Ellipse_12276)">
              <ellipse
                id="s-Ellipse_12"
                className="pie ellipse shape firer commentable non-processed"
                customid="Ellipse_2"
                cx="30"
                cy="30"
                rx="30"
                ry="30"
              ></ellipse>
            </g>
          </g>
          <defs>
            <clipPath id="clip-s-Ellipse_12276" className="clipPath">
              <ellipse cx="30" cy="30" rx="30" ry="30"></ellipse>
            </clipPath>
          </defs>
        </svg> */}
        <img src={pinkPlus} alt="plus" className="plus-icon" />
        <img src={pinkPlusDark} alt="plus" className="plus-icon-hover" />
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        keepMounted
        MenuListProps={{ style: { padding: "0px" } }}
        PaperProps={{
          className: "create-new-plus-menu-popup",
          style: { boxShadow: "none", marginTop: "15px !important" },
        }}
        className="create-new-plus-menu"
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className="menu-items">
          <div className="menu-item-list">
            <div className="item" onClick={() => onClickNew("article")}>
              Article
            </div>
            <div className="item" onClick={() => onClickNew("story")}>
              Story
            </div>
            <div className="item" onClick={() => onClickNew("general")}>
              Email
            </div>
          </div>
        </div>
      </Menu>
    </>
  );
}

export default CreateNewPlus;
