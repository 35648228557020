import { useEffect, useContext } from "react";
import { getUser } from "services/auth";
import AuthContext from "contexts/authContext";

const AppInit = () => {
  const { auth, changeAuth } = useContext(AuthContext);

  const getUserData = async () => {
    try {
      let res = await getUser();
      changeAuth({
        isLoggedIn: true,
        userData: { ...auth.userData, ...res.data },
      });
    } catch {
      changeAuth({ isLoggedIn: false, userData: {} });
    }
  };

  useEffect(() => {
    if (auth.isLoggedIn) getUserData();
  }, [auth.userData.token]);

  return <></>;
};

export default AppInit;
