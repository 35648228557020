import DeleteDialog from "component/Dialog/DeleteDialog/DeleteDialog";
import _ from "lodash";
import { deleteWorkspace } from "services/workspace";

const { trackButtonClick } = require("utils");

const SelectAllForWorkspace = (props) => {
  const { isSelected, onSelect, selectedIds, closeEdit, deleteCallback } =
    props;
  return (
    <>
      <div className="select-all">
        <div
          className="icon"
          onClick={() => {
            trackButtonClick("Side Menu", "Select all");
            onSelect();
          }}
        >
          {isSelected ? (
            <>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 12C2.685 12 0 9.315 0 6C0 2.685 2.685 0 6 0C9.315 0 12 2.685 12 6C12 9.315 9.315 12 6 12ZM8.715 4.035C8.53503 3.85503 8.25003 3.85503 8.07005 4.035L5.20505 6.975L3.93 5.65497C3.75003 5.47501 3.46503 5.47501 3.28505 5.65497C3.10508 5.83494 3.10509 6.13503 3.28505 6.315L4.89011 7.96505C5.07008 8.14502 5.35508 8.14502 5.53505 7.96505L8.73005 4.69505C8.89506 4.51498 8.89506 4.21489 8.71499 4.03492L8.715 4.035Z"
                  fill="#CB70BA"
                />
              </svg>
            </>
          ) : (
            <>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="12" height="12" rx="6" fill="white" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="11"
                  height="11"
                  rx="5.5"
                  stroke="black"
                  stroke-opacity="0.3"
                />
              </svg>
            </>
          )}
        </div>
        <div className="text">Select All</div>
        <div className="action">
          {selectedIds.length > 0 ? (
            <DeleteDialog
              type="workspace"
              isTeamMode={true}
              confirmClick={async () => {
                _.forEach(selectedIds, async (wid) => {
                  await deleteWorkspace(wid);
                });
                deleteCallback();
              }}
            >
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="18"
                  height="17"
                  rx="2.5"
                  fill="#B50000"
                  stroke="#B50000"
                />
                <path
                  d="M12.2911 13.0754L12.2911 13.0754C12.5898 12.7761 12.7758 12.3635 12.7758 11.909V6.79263H12.7772C12.928 6.79263 13.05 6.67018 13.05 6.51947V5.39908C13.05 5.24835 12.928 5.12628 12.7772 5.12628H11.2629V4.2228C11.2629 4.07207 11.141 3.95 10.9901 3.95H8.00987C7.85914 3.95 7.73706 4.07198 7.73706 4.2228V5.12628H6.2228C6.07207 5.12628 5.95 5.24826 5.95 5.39908V6.51947C5.95 6.67018 6.07189 6.79263 6.2228 6.79263H6.22427V11.909C6.22427 12.3635 6.40997 12.7764 6.70894 13.0754L6.70897 13.0754C7.00824 13.3741 7.42085 13.5601 7.87539 13.5601H11.1246C11.5792 13.5601 11.9921 13.3744 12.2911 13.0754ZM6.7703 11.909V6.79264H12.2299V11.859H12.2299V11.909C12.2299 12.2125 12.1056 12.4888 11.9051 12.6894C11.7046 12.8899 11.4282 13.0141 11.1247 13.0141H7.87547C7.57196 13.0141 7.29563 12.8899 7.09508 12.6894C6.89453 12.4888 6.7703 12.2125 6.7703 11.909ZM10.9902 7.68656C10.8395 7.68656 10.7174 7.80855 10.7174 7.95936V11.657C10.7174 11.8077 10.8394 11.9298 10.9902 11.9298C11.141 11.9298 11.263 11.8078 11.263 11.657V7.95936C11.263 7.80863 11.1411 7.68656 10.9902 7.68656ZM9.50007 7.68656C9.34934 7.68656 9.22727 7.80855 9.22727 7.95936V11.657C9.22727 11.8077 9.34926 11.9298 9.50007 11.9298C9.6508 11.9298 9.77288 11.8078 9.77288 11.657V7.95936C9.77288 7.80863 9.65089 7.68656 9.50007 7.68656ZM8.0099 7.68656C7.85917 7.68656 7.7371 7.80855 7.7371 7.95936V11.657C7.7371 11.8077 7.85909 11.9298 8.0099 11.9298C8.16063 11.9298 8.28271 11.8078 8.28271 11.657V7.95936C8.28271 7.80863 8.16072 7.68656 8.0099 7.68656ZM10.7175 5.12636H8.28278V4.49569H10.7175V5.12636ZM6.49718 6.24675H6.49529V5.67204H12.5042V6.24675H6.49718Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.1"
                />
              </svg>
            </DeleteDialog>
          ) : (
            <svg
              onClick={() => {
                trackButtonClick("Side Menu", "Close Edit");
                closeEdit();
              }}
              width="19"
              height="18"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 0C4.40875 0 2.88249 0.632176 1.75737 1.75737C0.632176 2.88265 0 4.40875 0 6C0 7.59125 0.632176 9.11751 1.75737 10.2426C2.88265 11.3678 4.40875 12 6 12C7.59125 12 9.11751 11.3678 10.2426 10.2426C11.3678 9.11735 12 7.59125 12 6C11.9981 4.40929 11.3654 2.88412 10.2405 1.75947C9.11572 0.634654 7.59055 0.00191689 6 0ZM8.31382 7.54319V7.54328C8.41902 7.64491 8.47901 7.78453 8.48029 7.93082C8.48157 8.0771 8.42404 8.21781 8.32058 8.32128C8.21712 8.42474 8.07641 8.48227 7.93012 8.48099C7.78383 8.47971 7.64422 8.41972 7.54258 8.31452L6 6.77138L4.45742 8.31452C4.3189 8.4483 4.1201 8.49916 3.93427 8.44821C3.74854 8.39725 3.60345 8.25215 3.55249 8.06642C3.50153 7.88059 3.5524 7.6818 3.68617 7.54327L5.22875 6.00013L3.68617 4.45699C3.58098 4.35535 3.52098 4.21574 3.51971 4.06945C3.51843 3.92316 3.57595 3.78245 3.67942 3.67899C3.78288 3.57552 3.92358 3.518 4.06988 3.51928C4.21616 3.52056 4.35577 3.58055 4.45741 3.68575L5.99999 5.22889L7.54258 3.68575C7.64421 3.58055 7.78382 3.52056 7.93011 3.51928C8.0764 3.518 8.21711 3.57553 8.32058 3.67899C8.42404 3.78245 8.48156 3.92316 8.48028 4.06945C8.47901 4.21574 8.41901 4.35535 8.31382 4.45699L6.77124 6.00013L8.31382 7.54319Z"
                fill="#4E5B6C"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectAllForWorkspace;
