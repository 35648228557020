import { 
  FooterInstagramIcon,
  FooterLinkedInIcon,
  FooterTwitterIcon,
  FooterLogo,
} from "assestes";
import { trackButtonClick } from "utils";
import { useHistory } from "react-router-dom";
import { Paths } from "utils/constant";

import './style.scss';

const FooterNew = () => {
  const history = useHistory();

  return (
    <footer className="footer">
        <div className="container footer__top">
          <div className="row">
            <div className="col-9 footer__nav">
              <a href="/" className="logo">
                <picture>
                  <img src={FooterLogo} alt="" />
                </picture>
              </a>
              <ul className="navigation">
                <li><a href="/" >Home</a></li>
                <li>
                  <a href="#plans" onClick={(e) => {
                    trackButtonClick("Header", "Pricing");
                  }}>Pricing</a>
                </li>
                <li>
                  <a href="#faqs" onClick={(e) => {
                    trackButtonClick("Header", "Pricing");
                  }}>FAQs</a>
                </li>
                <li>
                  <a href="/contact" onClick={(e) => {
                    e.preventDefault();
                    trackButtonClick("Header", "Contact");
                    history.push(Paths.contact);
                  }}>Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-3 footer__social">
              <ul className="social">
                <li className="li social__item">
                  <a href="instagram.com">
                    <img src={FooterInstagramIcon} alt="" />
                  </a>
                </li>
                <li className="li social__item">
                  <a href="linkedin.com">
                    <img src={FooterLinkedInIcon} alt="" />
                  </a>
                </li>
                <li className="li social__item">
                  <a href="twitter.com">
                    <img src={FooterTwitterIcon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <p>© Writely AI 2023 All rights Reserved</p>
              </div>
              <div className="col-6 footer__bottom-links">
                <ul>
                  <li><a href="https://app.termly.io/document/cookie-policy/ecf43297-9c39-4d9f-abff-8546699e6b4b" target="_blank" rel="noreferrer">Cookies</a></li>
                  <li><a href="https://app.termly.io/document/privacy-policy/1df0da24-d9c9-44d7-a9f2-003bfc0ba730" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                  <li><a href="https://app.termly.io/document/terms-of-use-for-website/6e8efdd6-19b4-4324-8a69-f326011728d8" target="_blank" rel="noreferrer">Terms of Service</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default FooterNew;
