import ReactGA from "react-ga";
import _ from "lodash";

export const countWords = (str) => {
  str = str.replace(/(^\s*)|(\s*$)/gi, "");
  str = str.replace(/[ ]{2,}/gi, " ");
  str = str.replace(/\n /, "\n");
  if (str === "") return 0;
  return str.split(" ").length;
};

export const getFirstNWords = (str, n) => {
  str = str.replace(/(^\s*)|(\s*$)/gi, "");
  str = str.replace(/[ ]{2,}/gi, " ");
  str = str.replace(/\n /, "\n");
  if (str === "") return "";
  const arr = str.split(" ");
  return arr.slice(0, n).join(" ");
};

export const scrollTextarea = (textarea, position) => {
  textarea.selectionEnd = textarea.selectionStart = position;
  textarea.blur();
  textarea.focus();
};

export const capitalize = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

export const trackButtonClick = (pageName, buttonName) => {
  ReactGA.event({
    category: pageName,
    action: "Click",
    label: buttonName,
  });
};

export const changeSingleToDoubleEnter = (text) => {
  const marches = text.match(/.\n./g);
  if (marches)
    for (let i = 0; i < marches.length; i++) {
      const newPlace = marches[i].replaceAll("\n", "\n\n");
      text = text.replaceAll(marches[i], newPlace);
    }
  return text;
};

export const addLineBreaks = (text) => {
  const symbolArray = [".", "!", "?"];
  let result = [text];
  for (let i = 0; i < symbolArray.length; i++) {
    const symbol = symbolArray[i];
    let tempResult = [];
    for (let r = 0; r < result.length; r++) {
      let newText = result[r].split(symbol);
      for (let n = 0; n < newText.length - 1; n++) {
        newText[n] = newText[n] + symbol;
      }
      tempResult = [...tempResult, ...newText];
    }
    result = [...tempResult];
  }
  result = _.map(result, _.trim);
  result = _.without(result, "");
  let fr = "";
  if (result.length > 5) {
    for (let j = 1; j <= result.length; j++) {
      fr = fr + " " + result[j - 1];
      if (j % 5 === 0) {
        fr = fr + "\n\n";
      }
    }
  } else {
    return text;
  }
  return fr;
};
