import { useContext } from "react";
import Header from "component/Header";
import AuthContext from "contexts/authContext";
import { useHistory } from "react-router";
import _ from "lodash";
import "./style.css";
import { Paths } from "utils/constant";
import { trackButtonClick } from "utils";

const Welcome = () => {
  const { auth } = useContext(AuthContext);
  const history = useHistory();

  return (
    <div className="welcome-wrapper">
      <Header />
      <div className="welcome">
        <div className="text">
          Welcome aboard, {_.get(auth, "userData.FirstName", "")}!
        </div>
        <div
          className="btn-start hover-zoom"
          onClick={() => {
            trackButtonClick("Welcome", "Start Writing");
            history.push(Paths.storyArticle);
          }}
        >
          Start Writing
        </div>
      </div>
    </div>
  );
};

export default Welcome;
