import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import "./style.css";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import { trackButtonClick } from "utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let outerProgress = 100;
let progressInterval;
const UndoIntroDialog = (props) => {
  const [progress, setProgress] = useState(100);
  const { isOpen = false, setIsOpen } = props;

  useEffect(() => {
    if (isOpen) {
      outerProgress = 100;
      setProgress(100);
      if (progressInterval) {
        clearInterval(progressInterval);
      }
      progressInterval = setInterval(() => {
        if (progress > 0 && outerProgress > 0) {
          setProgress(outerProgress - 0.25);
          outerProgress = outerProgress - 0.25;
        }
      }, 40);
    } else {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (progress <= 0) {
      setIsOpen(false);
    }
  }, [progress]);

  const handleClose = (e) => {
    trackButtonClick("UndoIntroDialog", "Close");
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            zIndex: "5000 !important",
            width: window.innerWidth < 500 ? "90%" : "432px",
            borderRadius: "8px",
            top: "0px",
            position: "absolute",
            filter:
              "drop-shadow(1.414213562373095px 1.4142135623730951px 29px #404040)",
            boxShadow: "none",
            backgroundColor: "#f1f1f1",
          },
        }}
        maxWidth="sm"
        BackdropProps={{
          style: {
            zIndex: "5000 !important",
            opacity: "0.53",
            backgroundColor: "#909090",
          },
        }}
      >
        <DialogContent style={{ zIndex: "5000 !important" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <CloseIcon
              style={{
                height: "18px",
                position: "absolute",
                top: "8px",
                right: "8px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <div className="UndoIntroDialog-dialog">
              <div className="oops">
                Prefer a different completion? Simply undo, and re-write :)
              </div>
              <div className="devider">
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UndoIntroDialog;
