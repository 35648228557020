import { landingPageRephase, mobileLogo, PriceRightIcon } from "assestes";
import Footer from "component/Footer";
import MenuIcon from "@material-ui/icons/Menu";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Paths, paymentData } from "utils/constant";

import "./mobileLandingStyle.css";
import { trackButtonClick } from "utils";
import FreeTrialDialog2 from "component/Dialog/FreeTrialDialog2/FreeTrialDialog2";

const MobileLanding = () => {
  const history = useHistory();
  const [uemail, setUemail] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    if (window.location.href.indexOf("#pricing") > -1) {
      if (document.getElementById("pricing"))
        document.getElementById("pricing").scrollIntoView();
    }
    if (window.location.href.indexOf("#faqs") > -1) {
      if (document.getElementById("faqs"))
        document.getElementById("faqs").scrollIntoView();
    }
  }, [history]);

  return (
    <div className="mobileLandingStyle-wrapper">
      <FreeTrialDialog2 />
      <div className="mobile-header">
        <img src={mobileLogo} alt="mobile-logo" className="mobile-logo" />
        <div className="menu-btn">
          <MenuIcon
            style={{ color: "#282828", width: "40px", height: "40px" }}
            onClick={() => {
              trackButtonClick("Mobile Landing", "Menu Icon");
              setMenuOpen(!menuOpen);
            }}
          />
        </div>
        {menuOpen && (
          <div className={`mobile-menu ` + (menuOpen ? "active" : "")}>
            <div
              className="menu-item"
              onClick={() => {
                trackButtonClick("Mobile Landing", "Pricing");
                window.location.href = "#pricing";
                setMenuOpen(false);
              }}
            >
              Pricing
            </div>
            <div
              className="menu-item"
              onClick={() => {
                trackButtonClick("Mobile Landing", "FAQS");
                window.location.href = "#faqs";
                setMenuOpen(false);
              }}
            >
              FAQS
            </div>
            <div
              className="menu-item"
              onClick={() => {
                trackButtonClick("Mobile Landing", "Contact");
                history.push(Paths.contact);
              }}
            >
              Contact
            </div>
            <div
              className="menu-item"
              onClick={() => {
                trackButtonClick("Mobile Landing", "Log In");
                history.push(Paths.login);
              }}
            >
              Log In
            </div>
            <div
              className="menu-item"
              onClick={() => {
                trackButtonClick("Mobile Landing", "Sign Up");
                history.push(Paths.signUp);
              }}
            >
              Sign Up
            </div>
          </div>
        )}
      </div>
      <div className="mobile-caption-section">
        <div className="text">
          Beat AI Detection:
          <br />
          You Write, <br />
          <span>Writely</span> completes!
        </div>
        <div className="sub-text">
          Write more content, faster than ever before and bypass AI detection.
        </div>
        <div className="trial">
          {!menuOpen ? (
            <input
              className="trial-input"
              id="trial-input-1"
              placeholder="Enter your email"
              onChange={(e) => setUemail(e.target.value)}
              value={uemail}
            />
          ) : (
            <div style={{ height: "62px" }}></div>
          )}
          <button
            className="trial-btn hover-zoom"
            onClick={() => {
              trackButtonClick("Mobile Landing", "Trial");
              history.push(Paths.signUp + "?email=" + uemail);
            }}
          >
            Trial
          </button>
          <div className="no-credit-text"></div>
          <img
            className="rephase-img"
            src={landingPageRephase}
            alt="landing page rephase"
          />
          <div className="whether-you-text">
            Whether you need to cut down on your words, elaborate further or
            simply rephrase a sentence, Writely can help!
          </div>

          <div className="card-wrapper" id="pricing">
            <div className="col">
              <div
                className="card"
                onClick={() => {
                  trackButtonClick("Mobile Landing", "Trial");
                  history.push(Paths.signUp);
                }}
              >
                <div className="title">Monthly Plan</div>
                <div className="price">${paymentData.monthlyPrice}</div>
                <div className="month">/ month</div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">Human like AI completions</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    Commands to help expand your ideas
                  </div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">Collaboration with your team</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">Unlimited usage</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    Access to our partners for promotion (20M followers)*
                  </div>
                </div>
                <div className="btn-get-me">Get me the Monthly!</div>
              </div>
            </div>
            <div className="col">
              <div
                className="card card2"
                onClick={() => history.push(Paths.signUp)}
              >
                <div className="title">Annual Plan</div>
                <div className="price">${paymentData.yearlyPrice / 12}</div>
                <div className="month">/ month</div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">Human like AI completions</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    Commands to help expand your ideas
                  </div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">Collaboration with your team</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">Unlimited usage</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    Access to our partners for promotion (20M followers)*
                  </div>
                </div>
                <div className="btn-get-me">Get me the Annual!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="devider"></div>
      <div className="mobile-faq" id="faqs">
        <div className="section-container">
          <div className="main-title">FAQS</div>
          {/* faq 1 */}
          <div className="question">
            <div className="q-text">How does it actually work?</div>
          </div>
          <div className="question">
            <div className="a-text">
              Machine learning. This is when a machine is fed lots of data,
              guiding it to learn patterns. Using this approach, Writely uses
              models that are trained with a lot of text to generate
              sophisticated outputs, and can therefore closely replicate the
              work of a human being, although not as clever as us!
            </div>
          </div>
          {/* faq 2 */}
          <div className="question">
            <div className="q-text">
              Does the output pass plaigarism checks?
            </div>
          </div>
          <div className="question">
            <div className="a-text">
              The risk of plagiarism on Writely is currently extremely minimal.
              This is because we use deep learning to enable the model to behave
              as close to a human being as possible. However, we will be
              incorporating 100% plagiarism-free outputs as part of our
              offering.
            </div>
          </div>
          {/* faq 3 */}
          <div className="question">
            <div className="q-text">What can I use this for?</div>
          </div>
          <div className="question">
            <div className="a-text">
              You are free to be as creative as you like in terms of how you use
              it. Whether you are a blogger, a marketing manager, university
              student or anyone needing to write, this is the place to get rid
              of any mental block associated to writing.
            </div>
          </div>
          {/* faq 4 */}
          <div className="question">
            <div className="q-text">
              Will the output be detected by anti Al tools?
            </div>
          </div>
          <div className="question">
            <div className="a-text">
              Our humanize feature allows users to bypass Al detection for
              almost all cases. You will have unlimited humanized generations,
              in case you aren't happy with the first result.
            </div>
          </div>
        </div>
        <div className="bottom-border"></div>
      </div>
      <button
        className="trial-btn hover-zoom"
        onClick={() => history.push(Paths.signUp + "?email=" + uemail)}
      >
        {" "}
        Trial{" "}
      </button>

      <Footer />
    </div>
  );
};

export default MobileLanding;
