import React, { useState } from "react";
import Typist from "react-typist";

function TypingLoader() {
  const [index, setIndex] = useState(0);

  const handleTypingDone = () => {
    setIndex(index + 1);
  };
  return (
    <Typist
      onTypingDone={handleTypingDone}
      key={index}
      startDelay={450}
      avgTypingDelay={450}
      cursor={{ show: false }}
    >
      .....
    </Typist>
  );
}

export default TypingLoader;
