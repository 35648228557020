import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import "./style.css";
import CloseIcon from "@material-ui/icons/Close";
import { trackButtonClick } from "utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NoTextDetectedDialog = (props) => {
  const { dialogOpen, setDialogOpen, noData = true } = props;

  const handleClose = (e) => {
    trackButtonClick("NoTextDetectedDialog", "Close");
    e.stopPropagation();
    e.preventDefault();
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            zIndex: "1200 !important",
            // padding: "20px",
            // paddingTop: "0px",
            borderRadius: "32px",
          },
        }}
        maxWidth="xs"
        BackdropProps={{ style: { zIndex: "1200 !important" } }}
      >
        <DialogContent style={{ zIndex: "1200 !important" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="no-text-detected-close" onClick={handleClose}>
              <CloseIcon
                overlineThickness={20}
                strikethroughThickness={7}
                fontSize="28px"
              />
            </div>
            <div className="no-text-detected-dialog">
              <div className="title">
                {noData ? "No text detected" : "Not enough text"}
              </div>
              <div className="sub-title">
                {noData
                  ? "We haven’t detected any text to humanize. Please generate some AI text before using this feature."
                  : "We haven’t detected enough text to humanize. You need at least 50 words to use this feature. Please generate more AI text before using this feature."}
              </div>
              <div className="okay-btn hover-zoom" onClick={handleClose}>
                Okay
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NoTextDetectedDialog;
