import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const loginService = async (data) => {
  return await axiosInstance.post(endPoints.login, data, { hasLoader: true });
};

export const signUpService = async (data) => {
  return await axiosInstance.post(endPoints.register, data, {
    hasLoader: false,
  });
};

export const senOtpService = async (data) => {
  return await axiosInstance.post(endPoints.sendOtp, data, {
    hasLoader: false,
  });
};

export const waitingList = async (email) => {
  return await axiosInstance.post(
    endPoints.waitingList,
    { Email: email },
    {
      hasLoader: true,
    }
  );
};

export const getSubscription = async () => {
  return await axiosInstance.get(endPoints.getSubscription);
};

export const updateSubscription = async (priceId, isAfterSignUp = false) => {
  return await axiosInstance.post(endPoints.updateSubscription, {
    price: priceId,
    from: window.location.protocol + "//" + window.location.host,
    isAfterSignUp,
  });
};

export const getInvoices = async () => {
  return await axiosInstance.get(endPoints.getInvoices);
};

export const getUser = async () => {
  return await axiosInstance.get(endPoints.getUser);
};

export const sendResetPasswordOtp = async (PhoneNumber) => {
  return await axiosInstance.post(endPoints.sendResetPasswordOtp, {
    PhoneNumber,
  });
};

export const validateOtp = async (PhoneNumber, Otp, Flag = "") => {
  const data = { PhoneNumber, Otp };
  if (Flag) {
    data.Flag = Flag;
  }
  return await axiosInstance.post(endPoints.validateOtp, data);
};

export const changePassword = async (PhoneNumber, Otp, Password) => {
  return await axiosInstance.post(endPoints.changePassword, {
    PhoneNumber,
    Otp,
    Password,
  });
};
