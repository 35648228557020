import CloseIcon from "@material-ui/icons/Close";
import { trackButtonClick } from "utils";
import "./style.css";

const HelpPopup = (props) => {
  const { onClose } = props;
  return (
    <div className="help-menu">
      <div className="title"> AI Help </div>
      <div
        className="close"
        onClick={() => {
          trackButtonClick("HelpPopup", "Close");
          onClose();
        }}
      >
        <CloseIcon className="hover-pink" />
      </div>
      <div className="sub-title">
        You can use commands to help you improve your writing. See below:
      </div>
      <div className="pink-divider">
        <span></span>
      </div>
      <div className="sub-title s2">
        To cut down the words in a sentence, simply highlight and select{" "}
        <span className="bold">shorten.</span>
      </div>
      <div className="sub-title s2">
        To reword your sentence, highlight the text and select{" "}
        <span className="bold">rephrase.</span>
      </div>
      <div className="sub-title s2">
        To develop a piece of text further, highlight the sentence and select{" "}
        <span className="bold">expand.</span>
      </div>
      <div className="link">Tell us other commands you'd like to see</div>
    </div>
  );
};

export default HelpPopup;
