import { useContext, useEffect, useLayoutEffect, useState } from "react";
import Header from "component/Header";
import SlidMenu from "component/SideMenu/SideMenu";
import _ from "lodash";
import { useIntercom } from "react-use-intercom";
import AuthContext from "contexts/authContext";
import {
  getWorkspaceNoteWithItems,
  getWorkspacesTeam,
  deleteWorkspaceNote,
  getWorkspaces,
} from "services/workspace";
import { Box, CircularProgress } from "@material-ui/core";
import autosize from "autosize";
import WritingPad from "component/WritingPad/WritingPad";
import "./style.css";
import "../Dashboard/style.css";
import { folderIcon } from "assestes";
import DeleteDialog from "component/Dialog/DeleteDialog/DeleteDialog";
import { useHistory } from "react-router-dom";
import { Paths } from "utils/constant";
import { RootContext } from "contexts/rootContext";

const TeamNoteFullView = (props) => {
  const {
    match: {
      params: { id, noteId },
    },
  } = props;
  const [activeTab, setActiveTab] = useState("workspaces");
  const [activeNote, setActiveNote] = useState(-1);
  const [details, setDetails] = useState({});
  const [team, setTeam] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [color, setColor] = useState("");
  const [open, setOpen] = useState(
    // _.get(location, "state.isRedirectFromRegister", false) ? true : false
    true
  );
  const [onNotePointerUpEvent, setOnNotePointerUpEvent] = useState({});
  const [writePad, setWritePad] = useState(true);

  const history = useHistory();

  useLayoutEffect(() => {
    autosize.update(document.querySelectorAll("textarea"));
  });

  const fetchNoteItems = async () => {
    setLoader(true);
    let res = await getWorkspaces();
    _.get(res, "data.asAdmin", []).forEach((element) => {
      if (element.id === +id) {
        setIsAdmin(true);
      }
    });

    await getWorkspacesTeam(id).then((res) =>
      setTeam([..._.get(res, "data.admin", []), ..._.get(res, "data.team", [])])
    );

    await getWorkspaceNoteWithItems(id, noteId).then((res) =>
      setDetails(res.data)
    );
    setLoader(false);
  };

  const { auth } = useContext(AuthContext);
  const { isNightMode } = useContext(RootContext);

  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (id > 0 && noteId > 0) {
      fetchNoteItems();
      setWritePad(true);
      setActiveNote(-1);
    }
    if (window.innerWidth > 1150)
      boot({
        email: _.get(auth, "userData.Email", ""),
        name:
          _.get(auth, "userData.FirstName", "") +
          " " +
          _.get(auth, "userData.LastName", ""),
      });
    return () => {
      if (window.innerWidth > 1150) shutdown();
    };
  }, [id, noteId]);

  useEffect(() => {
    if (activeNote === -1) {
      if (id > 0 && noteId > 0) {
        fetchNoteItems();
      }
      setWritePad(true);
      setActiveNote(-1);
    } else {
      autosize(document.querySelectorAll("textarea"));
    }
  }, [activeNote]);

  const colorList = ["#35b736", "#ff8c00", "#1eaaf1"];

  let getActiveTitleNote = {};

  const notes = _.filter(_.get(details, "items", []), (note) => {
    return note.id === activeNote;
  });

  if (notes.length > 0) {
    getActiveTitleNote = notes[0];
  }

  return (
    <div className={isNightMode ? "night-mode" : ""}>
      <SlidMenu
        noteList={[]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activeNote={activeNote}
        setActiveNote={setActiveNote}
        search={""}
        setSearch={() => {}}
        setWritePad={() => {}}
        open={open}
        setOpen={setOpen}
        workspaceId={id}
        workspaceNoteId={noteId}
      >
        <Header bgWhite />
        {activeNote === -1 && (
          <div className="team-note-full-view">
            {loader ? (
              <div className="text-center">
                <CircularProgress
                  thickness={4.5}
                  style={{ color: "#D967BE" }}
                />
              </div>
            ) : (
              <>
                {_.get(details, "note.title", "").length > 0 ? (
                  <div className="title d-flex">
                    <span style={{ flex: 1 }}>
                      {_.get(details, "note.title", "")}
                    </span>
                    {isAdmin && (
                      <span>
                        <DeleteDialog
                          type="note"
                          isTeamMode={true}
                          confirmClick={async () => {
                            await deleteWorkspaceNote(id, noteId);
                            history.push(
                              Paths.teamNoteFullView
                                .replace(":id", -1)
                                .replace(":noteId", -1)
                            );
                            setDetails({});
                          }}
                        >
                          <button
                            style={{
                              background: "#c71553",
                              color: "white",
                              border: "none",
                              borderRadius: "6px",
                              padding: "3px 16px",
                              marginLeft: "10px",
                              marginRight: "20px",
                              cursor: "pointer",
                              fontSize: "13.333px",
                            }}
                          >
                            Delete
                          </button>
                        </DeleteDialog>
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="emptyView">
                    <img src={folderIcon} width="300" alt="folder-icon" />
                    <div className="text1">No note selected</div>
                    <div className="text2">
                      Select a note or add a workspaces to get started
                    </div>
                  </div>
                )}
                <div className="team-note-content">
                  {_.map(_.get(details, "items", []), (item, index) => (
                    <div
                      className="team-note-item"
                      key={item.id}
                      style={{
                        opacity:
                          _.get(
                            _.find(team, (t) => t.userId === item.userId),
                            "email",
                            "0"
                          ) === _.get(auth, "userData.Email", "1")
                            ? 1
                            : 0.4,
                        cursor:
                          _.get(
                            _.find(team, (t) => t.userId === item.userId),
                            "email",
                            "0"
                          ) === _.get(auth, "userData.Email", "1")
                            ? "pointer"
                            : "unset",
                      }}
                      onClick={() => {
                        if (
                          _.get(
                            _.find(team, (t) => t.userId === item.userId),
                            "email",
                            "0"
                          ) === _.get(auth, "userData.Email", "1")
                        ) {
                          setColor(colorList[index % colorList.length]);
                          setActiveNote(item.id);
                          setWritePad(true);
                        }
                      }}
                    >
                      <div className="team-note-item-title">{item.title}</div>
                      <div className="team-note-item-details">
                        <div className="text">{item.description}</div>
                        <div
                          className="devider"
                          style={{
                            background: colorList[index % colorList.length],
                          }}
                        ></div>
                        <div className="assignee">
                          <div className="user-name">
                            {_.get(
                              _.find(team, (t) => t.userId === item.userId),
                              "email",
                              "0"
                            ) === _.get(auth, "userData.Email", "1")
                              ? "me"
                              : _.get(
                                  _.find(team, (t) => t.userId === item.userId),
                                  "firstName",
                                  ""
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        {activeNote > -1 && (
          <>
            <Box
              className="Dashboard-wrapper"
              onPointerUp={(e) => {
                setOnNotePointerUpEvent({ ..._.cloneDeep(e) });
              }}
            >
              <WritingPad
                closeSideMenu={() => {
                  if (open) setOpen(false);
                }}
                activeNote={getActiveTitleNote}
                activeNoteId={activeNote}
                editCallBack={() => {}}
                typeId={3} // blog
                deleteCallback={() => {}}
                setWritePad={setWritePad}
                writePad={writePad}
                setActiveNote={setActiveNote}
                setActiveTab={() => {}}
                onNotePointerUpEvent={onNotePointerUpEvent}
                isRedirectFromRegister={false}
                isTeamMode={true}
                teamData={{
                  isSingalOwner: false,
                  workspaceId: id,
                  noteId,
                  noteColor: color,
                  mainTitle: _.get(details, "note.title", ""),
                }}
              />
            </Box>
          </>
        )}
      </SlidMenu>
    </div>
  );
};

export default TeamNoteFullView;
