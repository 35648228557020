import { makeStyles } from "@material-ui/core";

let drawerWidth = 300;
let fullWidth = false;

export default makeStyles((theme) => {
  drawerWidth = window.innerWidth < 550 ? window.innerWidth : drawerWidth;
  fullWidth = window.innerWidth < 550;

  return {
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& .close-icon": {
        "@media (hover: hover)": {
          "&:hover": {
            fill: "black",
          },
        },
      },
      "& .team-mode": {
        display: "flex",
        paddingTop: "40px",
        paddingRight: "30px",
        "& .text": {
          marginLeft: "auto",
          color: "#494949",
          fontWeight: 300,
          fontSize: "12px",
          marginRight: "10px",
        },
      },
      "& .profile-section": {
        paddingTop: "30px",
        paddingLeft: "18px",
        display: "flex",
        "& .profile-image": {
          background: "#CB70BA",
          width: "48px",
          height: "48px",
          display: "flex",
          borderRadius: "50%",
          overflow: "hidden",
          "& .lettered-avatar-wrapper": {
            margin: "auto",
          },
          "& img": {
            margin: "auto",
          },
        },
        "& .profile-name": {
          padding: "0px 10px",
          "& .greeting": {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "14px",
            color: "rgba(0, 0, 0, 0.5)",
            display: "contents",
          },
          "& .name": {
            fontWeight: 600,
            fontSize: "16px",
            color: "#000000",
          },
        },
        "& .close-btn": {
          marginLeft: "auto",
          cursor: "pointer",
        },
      },
      "& .list-wrapper": {
        margin: "0px 16px",
        background: "#ffffff",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "5px",
        overflow: "hidden",
      },
      "& .devider": {
        margin: "10px 8px 0px 8px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
      },
      "& .action-section": {
        margin: "0px 8px 10px 8px",
        "& .edit-section": {
          "& img": {
            margin: "auto 0px",
          },
          "& .edit": {
            paddingLeft: "5px",
          },
          cursor: "pointer",
          padding: "2px 6px",
          display: "flex",
          fontSize: "16px",
          fontWeight: 500,
        },
        "& .select-all": {
          margin: "2px 8px",
          display: "flex",
          transition: "width 0.3s ease-in-out",
          "& .icon": {
            marginRight: "8px",
            cursor: "pointer",
          },
          "& .text": {
            color: "rgba(0, 0, 0, 0.7)",
            fontWeight: 500,
          },
          "& .action": {
            marginLeft: "auto",
            cursor: "pointer",
          },
        },
      },
      "& .create-new-section": {
        margin: "14px 16px",
        padding: "16px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "5px",
        "& .icon": {
          "& img": {
            margin: "auto",
            display: "block",
            cursor: "pointer",
          },
          marginBottom: "8px",
        },
        "& .text": {
          textAlign: "center",
          fontSize: "16px",
          fontWeight: 600,
          cursor: "pointer",
        },
      },
    },
    drawerPaper: {
      width: drawerWidth,
      height: "100vh !important",
      borderRight: "1px solid #E7E7E7",
      background: "#f9f9f9",
      boxShadow: "3px 0px 13px rgba(0, 0, 0, 0.11)",
      borderRadius: "0px 25px 25px 0px",
      zIndex: 1800,
      "& .items-wrapper": {
        paddingTop: "10px",
        paddingBottom: "10px",
        height: "CALC(100vh - 490px)",
        overflow: "auto",
        overflowX: "hidden",
      },
      "& .tab-wrapper": {
        padding: "30px 16px 18px 16px",
        "& .tab-grid": {
          background: "#ffffff",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          borderRadius: "5px",
          overflow: "hidden",
        },
      },
      "& .tab-item": {
        fontWeight: 500,
        fontSize: "13px",
        height: "39px",
        lineHeight: "39px",
        textAlign: "center",
        background: "#ffffff",
        cursor: "pointer",
        padding: "0px 4px",
        position: "relative",
        "&.active": {
          fontWeight: 700,
          background: "rgba(78, 91, 108, 0.15)",
          padding: "4px",
          width: "80px",
          textAlign: "center",
          height: "30px",
          lineHeight: "20px",
          margin: "auto",
          marginTop: "5px",
          borderRadius: "4px",
        },
        "&.team-mode-item": {
          "&.active": {
            width: "120px",
          },
        },
      },
      "& .item": {
        position: "relative",
        background: "#fff",
        padding: "8px 0px",
        margin: "0px 8px",
        borderBottom: "1px solid #e7e7e7",
        cursor: "pointer",
        minHeight: "56px",
        "&.workspace-item:last-child": {
          borderBottom: "none",
        },
        "& .item-content": {
          padding: "8px",
          minHeight: "56px",
          display: "flex",
          "&:hover": {
            background: "rgba(78, 91, 108, 0.05)",
          },
          "&:hover.workspace": {
            background: "unset",
          },
          "&.workspace": {
            minHeight: "38px",
            "& .text-wrapper .name": {
              lineHeight: "24px",
            },
            "& .workspace-note-list": {
              width: "100%",
              color: "#343434",
              fontSize: "13.33px",
              borderTop: "1px solid #e7e7e7",
              marginTop: "7px",
              paddingTop: "5px",
              "& .workspace-note-list-item": {
                padding: "11px 11px",
                display: "flex",
                "& .text": {
                  flex: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
                "& svg:hover": {
                  color: "#6a6a6a !important",
                },
                "&.active": {
                  background: "rgba(78, 91, 108, 0.05)",
                },
                "&:hover": {
                  background: "rgba(78, 91, 108, 0.05)",
                },
              },
              "&.empty-msg": {
                fontSize: "14.66px",
                padding: "13px 26px",
                fontWeight: 300,
                textAlign: "center",
                cursor: "auto",
                "& span": {
                  color: "rgb(217, 103, 190)",
                  textDecoration: "underline",
                  fontWeight: 700,
                  cursor: "pointer",
                },
              },
            },
          },
        },
        "& .delete-icon": {
          position: "absolute",
          right: "5px",
          top: "5px",
        },
        "& .text-wrapper": {
          width: "100%",
        },
        "&.active": {
          "& .item-content": {
            background: "rgba(78, 91, 108, 0.15)",
            color: "black",
            borderRadius: "5px",
            overflow: "hidden",
            "&:hover": {
              background: "rgba(78, 91, 108, 0.15)",
            },
          },
        },
        "& .manage-section": {
          position: "relative",
          paddingLeft: "0px",
          "& svg": {
            paddingTop: "5px",
          },
          "& .menu-option": {
            color: "#333333",
            display: "none",
            position: "absolute",
            left: "-70px",
            padding: "6px 25px",
            zIndex: 1000,
            borderRadius: "7px",
            background: "#f8f8f8",
            fontWeight: 700,
            lineHeight: "20px",
            fontSize: "13px",
            border: "1px solid #d9d9d9",
            boxShadow:
              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            "&:hover": {
              background: "#eeeeee",
            },
          },
          "&:hover .menu-option": {
            display: "block",
          },
        },
        "&.edit-mode": {
          "& .text-wrapper": {
            maxWidth: "CALC(100% - 24px)",
          },
        },

        "& .title": {
          fontSize: "14px",
          minHeight: "16px",
          fontWeight: 600,
          color: "#000000",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& .name": {
          flex: 1,
          fontSize: "14px",
          minHeight: "16px",
          fontWeight: 600,
          color: "#000000",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& .req-name": {
          fontSize: "14px",
          minHeight: "16px",
          fontWeight: 400,
          color: "#000000",
          width: "100%",
          paddingRight: "7px",
        },
        "& .req-btn ": {
          display: "flex",
          width: "100%",
          cursor: "auto",
        },
        "& .req-btn button": {
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          flex: 1,
          margin: "12px",
          fontSize: "14px",
          padding: "7px",
          "&:first-child": {
            marginLeft: "0px",
          },
          "&:last-child": {
            marginRight: "0px",
          },
        },
        "& .note": {
          fontSize: "12px",
          paddingTop: "4px",
          fontWeight: 300,
          color: "rgba(0,0,0,0.8)",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        "& .edit-box": {
          display: "flex",
          width: "0px",
          transition: "width 0.3s ease-in-out",
          "& svg": {
            margin: "auto",
            marginRight: "12px",
          },
          "&.is-edit": {
            width: "24px",
          },
        },
      },
      "& .empty-view": {
        height: "400px",
        "& img": {
          margin: "50px auto 25px auto",
          display: "block",
        },
        "& p": {
          color: "#333333",
          fontSize: "14px",
          fontWeight: 300,
          textAlign: "center",
        },
        "&.empty-search": {
          "& p": {
            color: "#333333",
            fontWeight: 400,
          },
        },
        "&.team-empty": {
          display: "flex",
        },
        "& .team-empty-workspaces": {
          margin: "auto",
          padding: "0px 16px",
          color: "#343434",
          fontWeight: 300,
          fontSize: "13.333px",
        },
      },
      "& .btn-load-more": {
        color: "#434343",
        fontSize: "13.333px",
        fontWeight: 600,
        cursor: "pointer",
        textAlign: "center",
        lineHeight: "38px",
      },
      "& .btn-create-new": {
        position: "relative",
        color: "#FFFFFF",
        fontSize: "13.333px",
        background: "#4a5c6e",
        fontWeight: 600,
        cursor: "pointer",
        textAlign: "center",
        width: "155px",
        borderRadius: "11px",
        margin: "20px auto 60px auto",
        lineHeight: "33px",
        paddingLeft: "15px",
        "& img": {
          position: "absolute",
          top: "50%",
          height: "22px",
          left: "0px",
          transform: "translate(8px, -50%)",
        },
        "&:hover": {
          background: "#4a5c6e",
        },
      },
    },
    drawerHeader: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      overflowY: "hidden",
      height: "100vh",
      overflow: "auto",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      paddingLeft: "35px",
      ["@media (max-width:768px)"]: {
        paddingLeft: "15px",
      },
      "& .arrow-right-div": {
        top: 0,
        left: 0,
        bottom: 0,
        width: "14px",
        background: "#8b8b8b",
        position: "fixed",
        ["@media (max-width:768px)"]: {
          display: "none",
        },
      },
      "& .arrow-right": {
        top: "calc(50% - 17px)",
        left: "6px",
        position: "absolute",
        zIndex: 10,
        ["@media (max-width:768px)"]: {
          left: "-5px",
        },
      },
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      display: fullWidth ? "none" : "block",
      "& .arrow-right-div": {
        display: "none",
      },
      "& .arrow-right": {
        display: "none",
      },
    },
  };
});
