import AuthContext from "contexts/authContext";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Paths } from "utils/constant";

function PasswordChanged() {
  const history = useHistory();
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (auth.isLoggedIn) {
      setTimeout(() => {
        history.push({
          pathname: "/",
        });
      }, 2500);
    } else {
      setTimeout(() => {
        history.push({
          pathname: Paths.login,
          state: { email: _.get(location, "state.email", "") },
        });
      }, 2500);
    }
  }, []);
  return (
    <div
      style={{
        textAlign: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <lottie-player
        src="https://assets1.lottiefiles.com/packages/lf20_y2hxPc.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        autoplay
      ></lottie-player>
      <h1>{_.get(location, "state.text", "Password Changed")}</h1>
      <h4>Redirecting...</h4>
    </div>
  );
}

export default PasswordChanged;
