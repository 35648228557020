import Header from "component/Header";

import "./style.css";

const WaitingListSuccess = () => {
  return (
    <div className="waiting-list-success-wrapper">
      <Header />
      <div className="welcome">
        <div className="text">
          Thank you for your interest, we will be in touch once we go live!
        </div>
      </div>
    </div>
  );
};

export default WaitingListSuccess;
