const URI = Object.freeze("https://api.writelyai.com/v1");
// const URI = Object.freeze("http://localhost:7000/v1");
export const APIURI = URI;

export const endPoints = Object.freeze({
  register: URI + "/signup",
  sendOtp: URI + "/sendOtp",
  login: URI + "/login",
  getAllNotes: URI + "/notes",
  EditNote: URI + "/note",
  WriteNote: URI + "/note/write",
  newWriteNote: URI + "/note/new-write",
  HumanizeNoteRequest: URI + "/note/humanize/request",
  HumanizeNoteFetch: URI + "/note/humanize/fetch",
  DeleteNote: URI + "/note/:id",
  DeleteNotes: URI + "/delete/notes",
  NoteSelectAction: URI + "/note",
  waitingList: URI + "/waiting-list",
  getSubscription: URI + "/subscription",
  getInvoices: URI + "/subscription/invoices",
  getUser: URI + "/getUser",
  updateSubscription: URI + "/subscription/create-checkout-session",
  sendResetPasswordOtp: URI + "/send-reset-password-otp",
  validateOtp: URI + "/validate-otp",
  changePassword: URI + "/change-password",
  generateNoteItem: URI + "/note/:noteId/item/generate",
  getNoteItems: URI + "/note/:noteId/item",
  editNoteItem: URI + "/note/:noteId/item/:itemId",
  chat: {
    getAllChat: URI + "/chats",
    createChat: URI + "/chats",
    updateChatTitle: URI + "/chats",
    addChatMessage: URI + "/chats/message",
    getChatMessages: URI + "/chats/:ChatId/message",
    deleteChat: URI + "/chats/:ChatId",
  },
  workspace: {
    getWorkspaces: URI + "/workspace",
    getWorkspacesById: URI + "/workspace/:workspaceId",
    getWorkspacesTeam: URI + "/workspace/:workspaceId/team",
    createWorkspace: URI + "/workspace",
    inviteTeam: URI + "/workspace/team/invite",
    deleteTeamMember: URI + "/workspace/team/delete",
    deleteWorkspace: URI + "/workspace/delete",
    getPendingInvites: URI + "/team/invites",
    acceptInvite: URI + "/team/invite/accept/:id",
    declineInvite: URI + "/team/invite/decline/:id",
    note: {
      getNotes: URI + "/workspace/:workspaceId/note",
      createNote: URI + "/workspace/:workspaceId/note/create",
      editNote: URI + "/workspace/:workspaceId/note/:noteId",
      deleteNote: URI + "/workspace/:workspaceId/note/:noteId",
      getNoteItems: URI + "/workspace/:workspaceId/note/:noteId",
      createNoteItem: URI + "/workspace/:workspaceId/note/:noteId/item/create",
      editNoteItem: URI + "/workspace/:workspaceId/note/:noteId/item/:itemId",
      deleteNoteItem: URI + "/workspace/:workspaceId/note/:noteId/item/:itemId",
      assignNoteItem:
        URI + "/workspace/:workspaceId/note/:noteId/item/:itemId/assign",
      generateNoteItem:
        URI + "/workspace/:workspaceId/note/:noteId/item/generate",
    },
  },
});
