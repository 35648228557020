import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const getAllChat = async () => {
  return await axiosInstance.get(`${endPoints.chat.getAllChat}`);
};

export const createChat = async (title) => {
  return await axiosInstance.post(`${endPoints.chat.createChat}`, {
    Title: title,
  });
};

export const updateChatTitle = async (title, chatId) => {
  return await axiosInstance.put(`${endPoints.chat.updateChatTitle}`, {
    Title: title,
    ChatId: chatId,
  });
};

export const addChatMessage = async (
  message,
  type,
  chatId,
  IsYTLink = false
) => {
  return await axiosInstance.put(`${endPoints.chat.addChatMessage}`, {
    Message: message,
    Type: type,
    ChatId: chatId,
    IsYTLink,
  });
};

export const getChatMessages = async (chatId) => {
  return await axiosInstance.get(
    `${endPoints.chat.getChatMessages.replace(":ChatId", chatId)}`
  );
};

export const deleteChat = async (chatId) => {
  return await axiosInstance.delete(
    `${endPoints.chat.deleteChat.replace(":ChatId", chatId)}`
  );
};
