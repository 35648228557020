import { Box } from "@material-ui/core";
import _ from "lodash";
import { boxImg1, boxImg2, boxImg3, notFoundSearch } from "assestes";
import React from "react";

const EmptyView = (props) => {
  const { filteredList, loader, activeTab, search, teamMode, typeAlias } =
    props;

  const emptyImage =
    activeTab === "blogs"
      ? boxImg1
      : activeTab === "stories"
      ? boxImg2
      : boxImg3;

  return (
    filteredList.length === 0 &&
    !_.get(loader, activeTab, false) &&
    (search.length > 0 ? (
      <Box className="empty-view empty-search">
        <img src={notFoundSearch} width="150px" alt="notFoundSearch" />
        <p>
          No {teamMode ? "workspace" : "notes"} found from your search,
          <br />
          try again.
        </p>
      </Box>
    ) : (
      <>
        {teamMode ? (
          <>
            <Box className="empty-view team-empty">
              <p className="team-empty-workspaces">
                {activeTab === "workspaces"
                  ? "You currently have no workspaces. Create one to start collaborating! :)"
                  : "You have no new requests."}
              </p>
            </Box>
          </>
        ) : (
          <>
            <Box className="empty-view">
              <img src={emptyImage} width="116px" alt="blogEmptyBackground" />
              <p>
                Your shelf is currently empty,
                <br />
                start your first {typeAlias} now!
              </p>
            </Box>
          </>
        )}
      </>
    ))
  );
};

export default EmptyView;
