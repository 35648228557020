import { circlePlusDarkPink } from "assestes";
import React from "react";

const CreateNewWorkspace = (props) => {
  const { onClick } = props;
  return (
    <div className="create-new-section">
      <div className="icon">
        <img
          src={circlePlusDarkPink}
          alt="circlePlusDarkPink"
          onClick={onClick}
        />
      </div>
      <div className="text" onClick={onClick}>
        Add Workspace
      </div>
    </div>
  );
};

export default CreateNewWorkspace;
