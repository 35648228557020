import { Box, CircularProgress, TextField } from "@material-ui/core";
import { editIcon } from "assestes";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { trackButtonClick } from "utils";
import EmptyView from "./EmptyView";
import SelectAll from "./SelectAll";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { Paths } from "utils/constant";

const NoteList = (props) => {
  const {
    activeNote,
    onNoteClick,
    loader,
    activeTab,
    noteList,
    onLoadMore,
    search,
    teamMode,
    setSearch,
    deleteCallback,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setSelectedIds([]);
  }, [activeTab]);

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };
  const loaderUI = (
    <Box mx="auto" textAlign="center" mt={2}>
      <CircularProgress thickness={4.5} style={{ color: "#D967BE" }} />
    </Box>
  );

  const typeAlias =
    activeTab === "blogs"
      ? "note"
      : activeTab === "stories"
      ? "creative piece"
      : "article";

  const selectNote = (id) => {
    if (isSelected(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const typeId = activeTab === "blogs" ? 1 : activeTab === "stories" ? 2 : 3;

  const filteredList = _.filter(
    _.get(noteList, "rows", []),
    (note) => note.noteType === typeId
  );

  return (
    <>
      {filteredList.length > 0 && (
        <>
          <Box>
            <TextField
              InputProps={{
                disableUnderline: true,
                endAdornment: <SearchIcon style={{ color: "#cbcbcb" }} />,
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for your notes"
              variant="standard"
              className="search-box"
            />
          </Box>
          <div className="action-section">
            {!isEdit ? (
              <div
                className="edit-section"
                onClick={() => {
                  trackButtonClick("Side Menu", "Edit");
                  setIsEdit(!isEdit);
                }}
              >
                <img src={editIcon} alt="editIcon" width="16px" height="16px" />
                <div className="edit">Edit</div>
              </div>
            ) : (
              <SelectAll
                isSelected={filteredList.length === selectedIds.length}
                selectedIds={selectedIds}
                closeEdit={() => setIsEdit(!isEdit)}
                isEdit={isEdit}
                noteType={typeId}
                setSelectedIds={setSelectedIds}
                deleteCallback={deleteCallback}
                onSelect={() => {
                  if (filteredList.length === selectedIds.length) {
                    setSelectedIds([]);
                  } else {
                    setSelectedIds(filteredList.map((item) => item.id));
                  }
                }}
              />
            )}
          </div>
          <div className="devider"></div>
        </>
      )}
      <EmptyView
        filteredList={filteredList}
        loader={loader}
        activeTab={activeTab}
        search={search}
        teamMode={teamMode}
        typeAlias={typeAlias}
      ></EmptyView>
      {filteredList.length > 0 && (
        <>
          <Box className="items-wrapper gray-scroll">
            {_.map(filteredList, (note) => (
              <Box
                className={`item ${
                  _.get(note, "id", 0) === activeNote ? "active" : ""
                } ${isEdit ? "edit-mode" : ""}`}
                key={_.get(note, "id", -1)}
                onClick={() => {
                  trackButtonClick("Side Menu", "Select Note");
                  onNoteClick(note);
                }}
              >
                <div className="item-content">
                  <div
                    className={"edit-box" + (isEdit ? " is-edit" : "")}
                    onClick={() => selectNote(_.get(note, "id", -1))}
                  >
                    {isEdit && (
                      <>
                        {isSelected(_.get(note, "id", -1)) ? (
                          <>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6 12C2.685 12 0 9.315 0 6C0 2.685 2.685 0 6 0C9.315 0 12 2.685 12 6C12 9.315 9.315 12 6 12ZM8.715 4.035C8.53503 3.85503 8.25003 3.85503 8.07005 4.035L5.20505 6.975L3.93 5.65497C3.75003 5.47501 3.46503 5.47501 3.28505 5.65497C3.10508 5.83494 3.10509 6.13503 3.28505 6.315L4.89011 7.96505C5.07008 8.14502 5.35508 8.14502 5.53505 7.96505L8.73005 4.69505C8.89506 4.51498 8.89506 4.21489 8.71499 4.03492L8.715 4.035Z"
                                fill="#CB70BA"
                              />
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="12"
                                height="12"
                                rx="6"
                                fill="white"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="11"
                                height="11"
                                rx="5.5"
                                stroke="black"
                                stroke-opacity="0.3"
                              />
                            </svg>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className="text-wrapper">
                    <Box className="title">{_.get(note, "title", "")}</Box>
                    <Box className="note">{_.get(note, "description", "")}</Box>
                  </div>
                  {note.type === 2 && (
                    <div>
                      <EditIcon
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          history.push(
                            Paths.createAssistedArticleNote.replace(
                              ":noteId",
                              note.id
                            )
                          );
                        }}
                        style={{ color: "rgb(142, 142, 147)" }}
                        fontSize="small"
                      ></EditIcon>
                    </div>
                  )}
                </div>
              </Box>
            ))}
            {!_.get(loader, activeTab, false) &&
              _.get(noteList, "pagination.currentPage", 1) <
                _.get(noteList, "pagination.totalPages", 1) && (
                <Box
                  className="btn-load-more"
                  onClick={() => {
                    trackButtonClick("Side Menu", "Load More");
                    onLoadMore();
                  }}
                >
                  Load More
                </Box>
              )}
            {_.get(loader, activeTab, false) && loaderUI}
          </Box>
        </>
      )}
      {filteredList.length === 0 && _.get(loader, activeTab, false) && loaderUI}
    </>
  );
};

export default NoteList;
