import React, { useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import ContentEditable from "react-contenteditable";
import _ from "lodash";
import { deleteChat, updateChatTitle } from "services/chat";
import DeleteDialog from "component/Dialog/DeleteDialog/DeleteDialog";

function ChatListItem(props) {
  const { onClick, chatTitle, chatId, id } = props;
  const [title, setTitle] = useState(chatTitle);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const contentEditable = React.createRef();
  useEffect(() => {
    if (isEdit) {
      setTitle(_.trim(title));
      setTitle(_.trim(title) + " ");
    } else {
      if (chatTitle !== title) {
        updateChatTitle(title, id);
      }
    }
    contentEditable.current.focus();
  }, [isEdit]);
  return (
    !isDeleted && (
      <>
        <div
          className={`chat-title ${+chatId === +id && "active"} ${
            isEdit ? "edit" : ""
          }`}
          onClick={() => {
            if (!isEdit) onClick();
          }}
        >
          <div className="text">
            <ContentEditable
              innerRef={contentEditable}
              html={title}
              disabled={!isEdit}
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </div>
          {isEdit ? (
            <SaveIcon
              onClick={(e) => {
                e.stopPropagation();
                if (title.length > 0) setIsEdit(false);
              }}
              fontSize="small"
            />
          ) : (
            <>
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEdit(true);
                }}
                className="edit-icon"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12.3333H13"
                  stroke="#515151"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 1.33334C10.2652 1.06813 10.6249 0.919128 11 0.919128C11.1857 0.919128 11.3696 0.955708 11.5412 1.02678C11.7128 1.09785 11.8687 1.20202 12 1.33334C12.1313 1.46466 12.2355 1.62057 12.3066 1.79215C12.3776 1.96373 12.4142 2.14762 12.4142 2.33334C12.4142 2.51906 12.3776 2.70296 12.3066 2.87454C12.2355 3.04612 12.1313 3.20202 12 3.33334L3.66667 11.6667L1 12.3333L1.66667 9.66668L10 1.33334Z"
                  stroke="#515151"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {/* <EditIcon
                style={{ color: "#fff" }}
                fontSize="small"
                title="Edit sub-heading"
              /> */}
            </>
          )}
          <DeleteDialog
            type="chat"
            isTeamMode={false}
            confirmClick={async () => {
              await deleteChat(id);
              setIsDeleted(true);
            }}
          >
            <svg
              className="delete-icon"
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4H2.33333H13"
                stroke="#515151"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.6666 4.00001V13.3333C11.6666 13.687 11.5261 14.0261 11.2761 14.2762C11.026 14.5262 10.6869 14.6667 10.3333 14.6667H3.66659C3.31296 14.6667 2.97382 14.5262 2.72378 14.2762C2.47373 14.0261 2.33325 13.687 2.33325 13.3333V4.00001M4.33325 4.00001V2.66668C4.33325 2.31305 4.47373 1.97392 4.72378 1.72387C4.97382 1.47382 5.31296 1.33334 5.66659 1.33334H8.33325C8.68687 1.33334 9.02601 1.47382 9.27606 1.72387C9.52611 1.97392 9.66658 2.31305 9.66658 2.66668V4.00001"
                stroke="#515151"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </DeleteDialog>
        </div>
      </>
    )
  );
}

export default ChatListItem;
