import { Box } from "@material-ui/core";
import Header from "component/Header";
import React from "react";

const ComingSoon = () => {
  return (
    <>
      <Header />
      <Box textAlign="center">
        <h1>Coming soon....</h1>
      </Box>
    </>
  );
};

export default ComingSoon;
