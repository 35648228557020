import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axiosInstance from "utils/axiosInstance";
import _ from "lodash";
import "./Loader.css";

const Loader = () => {
  const [ajaxCallCount, setAjaxCallCount] = useState(0);

  const handleAxiosCallCount = (number) => {
    setAjaxCallCount((prevCount) => {
      return prevCount + number;
    });
  };

  useEffect(() => {
    let reqInstance = null;
    let resInstance = null;

    reqInstance = axiosInstance.interceptors.request.use((request) => {
      if (request.hasLoader) handleAxiosCallCount(1);
      return request;
    });
    resInstance = axiosInstance.interceptors.response.use(
      (response) => {
        if (response.config.hasLoader) handleAxiosCallCount(-1);
        if (
          (_.get(response, "data.status", 0) === 200 ||
            _.get(response, "data.status", 0) === "success") &&
          _.get(response, "data.message", false)
        ) {
          toast.success(_.get(response, "data.message", ""), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          _.get(response, "data.message", false) &&
          _.get(response, "data.status", 0) !== 200
        ) {
          toast.error(_.get(response, "data.message", ""), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return response;
      },
      (error) => {
        if (error.config.hasLoader) handleAxiosCallCount(-1);
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(reqInstance);
      axiosInstance.interceptors.request.eject(resInstance);
    };
  }, []);

  if (ajaxCallCount === 0) {
    return null;
  }
  return (
    <div className="new-loader">
      <svg viewBox="-2000 -1000 4000 2000">
        <path
          id="inf"
          d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"
        ></path>
        <use
          xlinkHref="#inf"
          strokeDasharray="1570 5143"
          strokeDashoffset="6713px"
        ></use>
      </svg>
    </div>
  );
};

export default Loader;
