
import { useRef } from 'react';
import './style.scss';

const AccordionItem = ({caption, children, className}) => {

  const element = useRef(null);

  const handleClick = () => {
    element.current.classList.toggle('open')
  }

  return (
    <div ref={element} className={"accordion-item " + className}>
      <div 
        className="accordion-item__caption"
        onClick={handleClick}
      >{caption}</div>
      <div className="accordion-item__body">
        {children}
      </div>
    </div>
  )
}

export default AccordionItem;