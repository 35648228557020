import { Box, CircularProgress, TextField } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { trackButtonClick } from "utils";
import EmptyView from "./EmptyView";
import SearchIcon from "@material-ui/icons/Search";
import {
  acceptInvite,
  declineInvite,
  getPendingInvites,
  getWorkspaces,
} from "services/workspace";
import WorkspaceNoteList from "./WorkspaceNoteList";
import { useToasts } from "react-toast-notifications";
import SelectAllForWorkspace from "./SelectAllForWorkspace";
import { editIcon } from "assestes";

const TeamLists = (props) => {
  const {
    activeNote,
    activeTab,
    teamMode,
    newWorkspaceAdded,
    workspaceNoteId,
    workspaceId,
    setPendingRequests,
  } = props;
  const [workspaceList, setWorkspaceList] = useState([]);
  const [requestsList, setRequestsList] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState({
    workspaces: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { addToast } = useToasts();

  const setWorkspaces = async () => {
    if (activeTab === "workspaces") {
      setLoader({ ...loader, workspaces: true });
      let res = await getWorkspaces();
      setWorkspaceList([]);
      setWorkspaceList([
        ..._.get(res, "data.asAdmin", []).map((item) => {
          return { ...item, isAdmin: true };
        }),
        ..._.get(res, "data.asMember", []),
      ]);
      setPendingRequests(_.get(res, "data.invites", []).length);
      setLoader({ ...loader, workspaces: false });
    } else {
      setLoader({ ...loader, requests: true });
      let res = await getPendingInvites();
      setRequestsList([..._.get(res, "data", [])]);
      setLoader({ ...loader, requests: false });
      setPendingRequests(_.get(res, "data", []).length);
    }
  };
  useEffect(setWorkspaces, [newWorkspaceAdded, activeTab]);

  const loaderUI = (
    <Box mx="auto" textAlign="center" mt={2}>
      <CircularProgress thickness={4.5} style={{ color: "#D967BE" }} />
    </Box>
  );

  const filteredList =
    activeTab === "workspaces"
      ? _.filter(workspaceList, (item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        })
      : requestsList;

  const onAcceptInvite = async (id, name) => {
    await acceptInvite(id);
    addToast(`${name} has been added to your workspaces`, {
      appearance: "success",
      autoDismiss: true,
      PlacementType: "top-right",
    });
    setWorkspaces();
  };

  const onDeclineInvite = async (id) => {
    await declineInvite(id);
    setWorkspaces();
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };
  const selectNote = (id) => {
    if (isSelected(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  return (
    <>
      {(filteredList.length > 0 || search.length > 0) &&
        activeTab === "workspaces" && (
          <>
            <Box>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <SearchIcon style={{ color: "#cbcbcb" }} />,
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search for your workspace"
                variant="standard"
                className="search-box"
              />
            </Box>
            <div className="action-section">
              {!isEdit ? (
                <div
                  className="edit-section"
                  onClick={() => {
                    trackButtonClick("Side Menu", "Edit");
                    setIsEdit(!isEdit);
                  }}
                >
                  <img
                    src={editIcon}
                    alt="editIcon"
                    width="16px"
                    height="16px"
                  />
                  <div className="edit">Edit</div>
                </div>
              ) : (
                <SelectAllForWorkspace
                  isSelected={filteredList.length === selectedIds.length}
                  selectedIds={selectedIds}
                  closeEdit={() => setIsEdit(!isEdit)}
                  isEdit={isEdit}
                  setSelectedIds={setSelectedIds}
                  deleteCallback={async () => {
                    await setWorkspaces();
                    setSelectedIds([]);
                  }}
                  onSelect={() => {
                    if (filteredList.length === selectedIds.length) {
                      setSelectedIds([]);
                    } else {
                      setSelectedIds(filteredList.map((item) => item.id));
                    }
                  }}
                />
              )}
            </div>
            <div className="devider"></div>
          </>
        )}
      <EmptyView
        filteredList={filteredList}
        loader={loader}
        activeTab={activeTab}
        search={search}
        teamMode={teamMode}
      ></EmptyView>
      {filteredList.length > 0 && (
        <>
          <Box className="items-wrapper gray-scroll">
            {_.map(filteredList, (item) => (
              <Box
                className={`item workspace-item ${
                  _.get(item, "id", 0) === activeNote ? "active" : ""
                }`}
                key={"Box" + _.get(item, "id", -1)}
                onClick={() => {
                  trackButtonClick("Side Menu", "Select Note");
                }}
              >
                <div className="item-content workspace">
                  <div className="text-wrapper d-flex flex-wrap">
                    {activeTab === "workspaces" ? (
                      <>
                        {item.isAdmin && (
                          <div
                            className={"edit-box" + (isEdit ? " is-edit" : "")}
                            onClick={() => selectNote(_.get(item, "id", 0))}
                          >
                            {isEdit && (
                              <>
                                {isSelected(_.get(item, "id", 0)) ? (
                                  <>
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6 12C2.685 12 0 9.315 0 6C0 2.685 2.685 0 6 0C9.315 0 12 2.685 12 6C12 9.315 9.315 12 6 12ZM8.715 4.035C8.53503 3.85503 8.25003 3.85503 8.07005 4.035L5.20505 6.975L3.93 5.65497C3.75003 5.47501 3.46503 5.47501 3.28505 5.65497C3.10508 5.83494 3.10509 6.13503 3.28505 6.315L4.89011 7.96505C5.07008 8.14502 5.35508 8.14502 5.53505 7.96505L8.73005 4.69505C8.89506 4.51498 8.89506 4.21489 8.71499 4.03492L8.715 4.035Z"
                                        fill="#CB70BA"
                                      />
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="12"
                                        height="12"
                                        rx="6"
                                        fill="white"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="11"
                                        height="11"
                                        rx="5.5"
                                        stroke="black"
                                        stroke-opacity="0.3"
                                      />
                                    </svg>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                        <WorkspaceNoteList
                          key={item.id}
                          item={item}
                          isSelected={isSelected}
                          isEdit={isEdit}
                          selectNote={selectNote}
                          workspaceId={workspaceId}
                          workspaceNoteId={workspaceNoteId}
                        />
                      </>
                    ) : (
                      <>
                        <Box className="req-name">
                          You are invited to join{" "}
                          <b>{_.get(item, "name", "")}</b> workspace
                        </Box>
                        <Box className="req-btn">
                          <button
                            style={{ background: "#1eaaf1" }}
                            onClick={() =>
                              onAcceptInvite(item.id, _.get(item, "name", ""))
                            }
                          >
                            Join
                          </button>
                          <button
                            style={{
                              background: "#fff",
                              color: "black",
                              border: "1px solid #d3d3d",
                            }}
                            onClick={() => onDeclineInvite(item.id)}
                          >
                            Ignore
                          </button>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </Box>
            ))}
            {_.get(loader, activeTab, false) && loaderUI}
          </Box>
        </>
      )}
      {filteredList.length === 0 && _.get(loader, activeTab, false) && loaderUI}
    </>
  );
};

export default TeamLists;
