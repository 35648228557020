import { useLayoutEffect, useState } from "react";
import _ from "lodash";

import Scrollbar from "component/Scrollbar";
import { trackButtonClick } from "utils";

import { Drawer } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { useSwipeable } from "react-swipeable";
import autosize from "autosize";
import ToggleButton from "react-toggle-button";

import style from "./style";
import CreateNewNote from "./CreateNewNote";
import ProfileSection from "./ProfileSection";
import TabWrapper from "./TabWrapper";
import NoteList from "./NoteList";
import CreateNewWorkspace from "./CreateNewWorkspace";
import TeamLists from "./TeamLists";
import CreateWorkspaceDialog from "component/Dialog/CreateWorkspace/CreateWorkspaceDialog";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Paths } from "utils/constant";
import clsx from "clsx";

const SlidMenu = (props) => {
  const {
    activeTab,
    setActiveTab,
    noteList,
    onLoadMore,
    loader,
    activeNote,
    setActiveNote,
    search,
    setSearch,
    deleteCallback,
    setWritePad,
    open,
    setOpen,
    workspaceId,
    workspaceNoteId,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const [teamMode, setTeamMode] = useState(
    _.get(location, "state.teamMode", false)
  );
  const [newWorkspaceAdded, setNewWorkspaceAdded] = useState(null);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [createWorkspaceOpen, setCreateWorkspaceOpen] = useState(false);
  let classes = style();

  const handleDrawerOpen = () => {
    trackButtonClick("Side Menu", "Drawer Open");
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!teamMode && location.pathname === Paths.dashboard) {
      setActiveTab("articles");
      history.push(Paths.dashboard, { teamMode: false });
    } else {
      setActiveTab("workspaces");
      if (
        !(
          location.pathname.indexOf("fullview") > -1 &&
          location.pathname.indexOf("note") > -1 &&
          location.pathname.indexOf("workspace") > -1
        )
      )
        history.push(Paths.teamNoteFullView, { teamMode: true });
    }
  }, [teamMode]);

  useLayoutEffect(() => {
    setTimeout(() => {
      autosize.update(document.querySelectorAll("textarea"));
    }, 400);
  });

  const drawerContent = (
    <>
      <div className="team-mode">
        <div className="text">Team Mode</div>
        <ToggleButton
          value={teamMode}
          activeLabel="On"
          inactiveLabel="Off"
          className="team-mode"
          colors={{
            active: {
              base: "#1eaaf1",
            },
            inactive: {
              base: "#cbcbcb",
            },
          }}
          onToggle={() => {
            if (teamMode) {
              setActiveTab("articles");
              history.push(Paths.dashboard, { teamMode: false });
            }
            setTeamMode(!teamMode);
          }}
        />
      </div>
      <ProfileSection
        onCloseClick={() => {
          trackButtonClick("Side Menu", "Drawer Close");
          handleDrawerClose();
        }}
      ></ProfileSection>
      <TabWrapper
        teamMode={teamMode}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        pendingRequests={pendingRequests}
      />
      <div className="list-wrapper">
        {teamMode ? (
          <TeamLists
            activeNote={activeNote}
            loader={loader}
            activeTab={activeTab}
            onLoadMore={onLoadMore}
            teamMode={teamMode}
            newWorkspaceAdded={newWorkspaceAdded}
            workspaceId={workspaceId}
            workspaceNoteId={workspaceNoteId}
            setPendingRequests={setPendingRequests}
          ></TeamLists>
        ) : (
          <NoteList
            onNoteClick={(note) => {
              setWritePad(true);
              setActiveNote(_.get(note, "id", -1));
              if (window.innerWidth < 550) {
                handleDrawerClose();
              }
            }}
            activeNote={activeNote}
            loader={loader}
            activeTab={activeTab}
            noteList={noteList}
            onLoadMore={onLoadMore}
            search={search}
            teamMode={teamMode}
            setSearch={setSearch}
            deleteCallback={deleteCallback}
          ></NoteList>
        )}
      </div>
      {teamMode ? (
        <CreateNewWorkspace
          onClick={() => setCreateWorkspaceOpen(true)}
        ></CreateNewWorkspace>
      ) : (
        <CreateNewNote
          onClick={() => {
            trackButtonClick("Side Menu", "Create New Note");
            handleDrawerClose();
            setWritePad(true);
            setActiveNote(-1);
          }}
        ></CreateNewNote>
      )}
    </>
  );
  const handlers = useSwipeable({
    onSwipedRight: handleDrawerOpen,
  });

  return (
    <div className={clsx(classes.root, "sidemenu-drawerPaper")}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        BackdropProps={{
          open: false,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerContent}
      </Drawer>
      <main {...handlers} className={classes.content}>
        <Scrollbar>
          <div className="arrow-right-div"></div>
          <ArrowRightIcon
            onClick={handleDrawerOpen}
            fontSize="large"
            className="pointer arrow-right"
            style={{
              color: "rgb(217, 103, 190)",
            }}
          />
          {props.children}
        </Scrollbar>
        {createWorkspaceOpen && (
          <CreateWorkspaceDialog
            added={(res) => {
              setNewWorkspaceAdded(res);
              setCreateWorkspaceOpen(false);
            }}
            handleClose={() => setCreateWorkspaceOpen(false)}
          />
        )}
      </main>
    </div>
  );
};

export default SlidMenu;
