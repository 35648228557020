import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText } from "@material-ui/core";
import _ from "lodash";
import "./style.css";
import Header from "component/Header";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "contexts/authContext";
import { useHistory } from "react-router-dom";
import { senOtpService, signUpService } from "services/auth";
import { Paths } from "utils/constant";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { trackButtonClick } from "utils";
// import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import HeaderNew from "component/Header/index.new";
import FooterNew from "component/Footer/index.new";
import Footer from "component/Footer";

const SignUp = () => {
  const { changeAuth } = useContext(AuthContext);
  const [signUpData, setSignUpData] = useState({});
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [countryState, setCountryState] = useState("us");
  const getCountry = async () => {
    let country = localStorage.getItem("country");
    if (!country) {
      const res = await axios.get("https://ipinfo.io?token=a351270cd0bfaf");
      if (_.get(res, "data.country", "").length > 0) {
        country = _.toLower(res.data.country);
        localStorage.setItem("country", country);
      }
    }
    setCountryState(country);
  };

  useEffect(() => {
    getCountry();
  }, []);

  return (
    <>
      <div className="sign-up">
        {window.innerWidth < 750 ? <Header onlyMobileView /> : <HeaderNew />}
        {_.isEmpty(signUpData) ? (
          <div className="card-wrapper">
            <div className="card">
              <div className="title">Sign Up</div>
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: params.get("email") || "",
                  phoneNumber: "",
                  password: "",
                  confirmpassword: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  firstname: Yup.string()
                    .max(255)
                    .required("First name is required"),
                  lastname: Yup.string()
                    .max(255)
                    .required("Last name is required"),
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  phoneNumber: Yup.string()
                    .required("Telephone number is required")
                    .matches(
                      /^\+[1-9]{1}[0-9]{7,14}$/,
                      "Enter valid telephone number, ex: +11234567890"
                    ),
                  password: Yup.string()
                    .min(6)
                    .max(255)
                    .required("Password is required"),
                  confirmpassword: Yup.string()
                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                    .required("Confirm Password is required"),
                  hCaptcha: Yup.string().required("Captcha is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting, setFieldValue }
                ) => {
                  try {
                    if (senOtpService) {
                      // const token = await executeRecaptcha("SignUp");
                      const userData = await senOtpService({
                        FirstName: values.firstname,
                        LastName: values.lastname,
                        Password: values.password,
                        Email: values.email,
                        PhoneNumber: values.phoneNumber,
                        CaptchaCode: values.hCaptcha,
                      });

                      if (userData) {
                        setSignUpData({
                          FirstName: values.firstname,
                          LastName: values.lastname,
                          Password: values.password,
                          Email: values.email,
                          PhoneNumber: values.phoneNumber,
                        });
                      }
                      captchaRef.current?.resetCaptcha();
                      setFieldValue("hCaptcha", "");
                    }
                  } catch (err) {
                    console.error(err);
                    captchaRef.current?.resetCaptcha();
                    setStatus({ success: false });
                    setErrors({
                      submit: _.get(
                        err,
                        "response.data.title",
                        "Something went wrong"
                      ),
                    });

                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="name-wrapper">
                      <div className="fname">
                        <input
                          placeholder="First Name"
                          className="ui-input"
                          name="firstname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstname}
                        />
                        {Boolean(touched.firstname && errors.firstname) && (
                          <FormHelperText error>
                            {touched.firstname && errors.firstname}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="lname">
                        <input
                          placeholder="Last Name"
                          name="lastname"
                          className="ui-input"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lastname}
                        />
                        {Boolean(touched.lastname && errors.lastname) && (
                          <FormHelperText error>
                            {touched.lastname && errors.lastname}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                    <div className="mt-20">
                      <input
                        placeholder="Email"
                        name="email"
                        className="ui-input"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      {Boolean(touched.email && errors.email) && (
                        <FormHelperText error>
                          {touched.email && errors.email}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20">
                      <PhoneInput
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          autoFocus: true,
                        }}
                        enableSearch={true}
                        name="phoneNumber"
                        country={countryState}
                        onBlur={handleBlur}
                        onChange={(value, country, e, formattedValue) => {
                          e.target.value = "+" + value;
                          handleChange(e);
                        }}
                        value={values.phoneNumber}
                        placeholder="Telephone number (with country code)"
                      />
                      {Boolean(touched.phoneNumber && errors.phoneNumber) && (
                        <FormHelperText error>
                          {touched.phoneNumber && errors.phoneNumber}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20">
                      <input
                        type="password"
                        placeholder="Password"
                        className="ui-input"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                      />
                      {Boolean(touched.password && errors.password) && (
                        <FormHelperText error>
                          {touched.password && errors.password}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20 ">
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        className="ui-input"
                        name="confirmpassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmpassword}
                      />
                      {Boolean(
                        touched.confirmpassword && errors.confirmpassword
                      ) && (
                        <FormHelperText error>
                          {touched.confirmpassword && errors.confirmpassword}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20">
                      <div className="m-a" style={{ display: "table" }}>
                        <HCaptcha
                          ref={captchaRef}
                          sitekey="7d5b28e1-fae1-4675-9d2a-e8d3e3f2fe12"
                          onVerify={(token) => {
                            setFieldValue("hCaptcha", token);
                          }}
                          onExpire={() => {
                            setFieldValue("hCaptcha", "");
                          }}
                        />
                        {Boolean(errors.hCaptcha) && (
                          <FormHelperText error>
                            {errors.hCaptcha}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <button
                        className="btn-login hover-zoom"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Sign Up
                      </button>
                    </Box>
                    <Box className="tnc">
                      By signing up, you are agreeing to our
                      <br />
                      <a href="https://app.termly.io/document/terms-of-use-for-website/6e8efdd6-19b4-4324-8a69-f326011728d8">
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="https://app.termly.io/document/privacy-policy/1df0da24-d9c9-44d7-a9f2-003bfc0ba730">
                        Privacy Policy
                      </a>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <>
            {/* <div className="main-title">You're almost there!</div> */}
            <div className="card-wrapper">
              <div className="card ">
                <div className="title">You're almost there!</div>
                <input
                  className="otp ui-input"
                  placeholder={`Verification code sent to ${signUpData.Email}`}
                  onChange={(e) => {
                    if (
                      ((!isNaN(e.target.value) &&
                        Number.isInteger(parseFloat(e.target.value))) ||
                        _.isEmpty(e.target.value)) &&
                      e.target.value.length <= 6
                    ) {
                      setOtp(e.target.value);
                    }
                  }}
                  value={otp}
                />
                <FormHelperText error>{otpError && otpError}</FormHelperText>
                {/* <GoogleReCaptcha
                  onVerify={(token) => {
                    alert(token);
                    console.log(token);
                  }}
                /> */}
                <Box mt={2}>
                  <button
                    className="btn-login hover-zoom"
                    type="submit"
                    disabled={otp.length !== 6 || isLoading}
                    onClick={async () => {
                      trackButtonClick("SignUp", "Verify");
                      try {
                        if (signUpService) {
                          setIsLoading(true);
                          const userData = await signUpService({
                            ...signUpData,
                            Otp: otp,
                          });
                          if (userData) {
                            try {
                              window.fpr("referral", {
                                email: _.get(userData, "data.Email", ""),
                              });
                            } catch (err) {
                              console.error(
                                "error in firstpromoter referral",
                                err
                              );
                            }
                            setIsLoading(false);
                            changeAuth({
                              isLoggedIn: true,
                              userData: userData.data,
                            });
                            try {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push({
                                event: "sign_up",
                                UserId: userData.data.userId,
                              });
                            } catch (err) {
                              console.log("error in data layer");
                            }
                            history.push({
                              pathname: Paths.signUpDone,
                              state: { isRedirectFromRegister: true },
                            });
                          }
                          setIsLoading(false);
                        }
                      } catch (err) {
                        console.error(err);
                        setIsLoading(false);
                        setOtpError(
                          _.get(
                            err,
                            "response.data.title",
                            "Something went wrong"
                          )
                        );
                      }
                    }}
                  >
                    {isLoading ? "Verifying..." : "Verify"}
                  </button>
                </Box>
              </div>
            </div>
          </>
        )}
        {window.innerWidth < 550 ? <Footer /> : <FooterNew />}
      </div>
    </>
  );
};

export default SignUp;
