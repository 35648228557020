import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import "./style.css";

const Loading = () => {
  return (
    <Box className="fixed-corner-loading">
      <CircularProgress
        style={{ color: "#d967be", display: "block", margin: "0 auto" }}
        size={window.innerWidth < 1250 ? 40 : 30}
        thickness={2.8}
      />
      <span className="text">just a second..</span>
    </Box>
  );
};

export default Loading;
