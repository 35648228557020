import axiosInstance from "utils/axiosInstance";
import { endPoints } from "services/endPoints";

const { workspace } = endPoints;

export const getWorkspaces = async () => {
  return await axiosInstance.get(workspace.getWorkspaces);
};

export const getWorkspacesById = async (id) => {
  return await axiosInstance.get(
    workspace.getWorkspacesById.replace(":workspaceId", id)
  );
};

export const getWorkspacesTeam = async (workspaceId) => {
  return await axiosInstance.get(
    workspace.getWorkspacesTeam.replace(":workspaceId", workspaceId)
  );
};

export const createWorkspace = async (name, team) => {
  return await axiosInstance.post(workspace.createWorkspace, {
    name,
    team,
  });
};

export const inviteTeam = async (workspaceId, team) => {
  return await axiosInstance.post(workspace.inviteTeam, {
    workspaceId,
    team,
  });
};

export const deleteTeamMember = async (teamMemberId) => {
  return await axiosInstance.post(workspace.deleteTeamMember, {
    teamMemberId,
  });
};

export const deleteWorkspace = async (workspaceId) => {
  return await axiosInstance.post(workspace.deleteWorkspace, {
    workspaceId,
  });
};

export const getPendingInvites = async () => {
  return await axiosInstance.get(workspace.getPendingInvites);
};

export const acceptInvite = async (id) => {
  return await axiosInstance.get(workspace.acceptInvite.replace(":id", id));
};

export const declineInvite = async (id) => {
  return await axiosInstance.get(workspace.declineInvite.replace(":id", id));
};

export const getWorkspaceNotes = async (workspaceId) => {
  return await axiosInstance.get(
    workspace.note.getNotes.replace(":workspaceId", workspaceId)
  );
};

export const createWorkspaceNote = async (workspaceId, title) => {
  return await axiosInstance.post(
    workspace.note.createNote.replace(":workspaceId", workspaceId),
    {
      Title: title,
    }
  );
};

export const editWorkspaceNote = async (workspaceId, noteId, title) => {
  return await axiosInstance.put(
    workspace.note.editNote
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId),
    {
      Title: title,
    }
  );
};

export const deleteWorkspaceNote = async (workspaceId, noteId) => {
  return await axiosInstance.delete(
    workspace.note.deleteNote
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId)
  );
};

export const getWorkspaceNoteWithItems = async (workspaceId, noteId) => {
  return await axiosInstance.get(
    workspace.note.getNoteItems
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId)
  );
};

export const createWorkspaceNoteItem = async (
  workspaceId,
  noteId,
  title,
  description
) => {
  return await axiosInstance.post(
    workspace.note.createNoteItem
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId),
    {
      Title: title,
      Description: description,
    }
  );
};

export const editWorkspaceNoteItem = async (
  workspaceId,
  noteId,
  itemId,
  title,
  description,
  briefOn,
  brief,
  noteLength
) => {
  return await axiosInstance.put(
    workspace.note.editNoteItem
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId)
      .replace(":itemId", itemId),
    {
      Title: title,
      Description: description,
      BriefEnable: briefOn,
      Brief: brief,
      NoteLength: noteLength,
    }
  );
};

export const deleteWorkspaceNoteItem = async (workspaceId, noteId, itemId) => {
  return await axiosInstance.delete(
    workspace.note.deleteNoteItem
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId)
      .replace(":itemId", itemId)
  );
};

export const assignWorkspaceNoteItem = async (
  workspaceId,
  noteId,
  itemId,
  userId
) => {
  return await axiosInstance.post(
    workspace.note.assignNoteItem
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId)
      .replace(":itemId", itemId),
    {
      UserId: userId,
    }
  );
};

export const generateWorkspaceNoteItem = async (workspaceId, noteId, Title) => {
  return await axiosInstance.post(
    workspace.note.generateNoteItem
      .replace(":workspaceId", workspaceId)
      .replace(":noteId", noteId),
    { Title: Title }
  );
};
