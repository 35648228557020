import {
  backwardArrow,
  forwardArrow,
  outputLength,
  logoWhite,
  onBoard,
  crossIcon,
  crossIconWhite,
} from "assestes";
import AuthContext from "contexts/authContext";
import React, { useContext, useState } from "react";
import ToggleButton from "react-toggle-button";
import _ from "lodash";
import "./style.css";
import { trackButtonClick } from "utils";

const TourModal = (props) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const { auth } = useContext(AuthContext);
  const [briefOn, setBriefOn] = useState(true);

  const nextSlide = () => {
    trackButtonClick("Tour Modal", "Next");
    setSliderIndex(sliderIndex + 1);
  };
  const prevSlide = () => {
    trackButtonClick("Tour Modal", "Previous");
    setSliderIndex(sliderIndex - 1);
  };
  const skipTour = () => {
    trackButtonClick("Tour Modal", "Skip Tour");
    document.getElementById("register-overlay-modal").style.width = "0%";
  };

  return (
    <div id="register-overlay-modal" className="register-overlay-modal">
      <div className="welcome-board">
        {sliderIndex === 0 && (
          <div className="welcome-box">
            <div className="left-box">
              <div className="page-dots">
                <span className="dot slider-box-1"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
              <div className="left-box-title">
                Hello, {_.get(auth, "userData.FirstName", "")}!
              </div>
              <div className="left-box-desc">
                To make sure you have an easy experience, we've summarised our
                capabilities in just 4 slides.
              </div>
              <div className="left-box-btn">
                <div
                  className="btn-next hover-zoom"
                  onClick={() => {
                    nextSlide();
                  }}
                >
                  Next
                </div>
                <div
                  className="btn-skip"
                  onClick={() => {
                    props.begin && props.begin();
                    skipTour();
                  }}
                >
                  Skip Tour
                </div>
              </div>
            </div>
            <div className="right-box slider-box-1">
              <div className="right-box-text">Welcome Aboard!</div>
              <div className="right-box-bottom-img">
                <img src={logoWhite} width="100%" alt="prev" />
              </div>
              <div className="next-btn-arrow hover-zoom" onClick={nextSlide}>
                <img src={forwardArrow} width="100%" alt="next" />
              </div>
            </div>
            <img
              className="close"
              src={window.innerWidth < 950 ? crossIcon : crossIconWhite}
              onClick={() => {
                props.begin && props.begin();
                skipTour();
              }}
              width="16px"
              height="16px"
              alt="crossIcon"
            />
          </div>
        )}
        {sliderIndex === 1 && (
          <div className="welcome-box">
            <div className="left-box">
              <div className="page-dots">
                <span className="dot"></span>
                <span className="dot slider-box-2"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
              <div className="left-box-title">How it works.</div>
              <div className="left-box-desc text-align-initial pt-0">
                <p>1. Insert your title </p>
                <p>2. Start typing your content </p>
                <p>
                  3. Click <span className="badge">Write</span> and let Writely
                  complete your work!
                </p>
              </div>
              <div className="left-box-btn pt-40">
                <div className="btn-next hover-zoom" onClick={nextSlide}>
                  Next
                </div>
                <div
                  className="btn-skip"
                  onClick={() => {
                    props.begin && props.begin();
                    skipTour();
                  }}
                >
                  Skip Tour
                </div>
              </div>
              <div className="prev-btn-arrow hover-zoom" onClick={prevSlide}>
                <img src={backwardArrow} width="100%" alt="backwardArrow" />
              </div>
            </div>
            <div className="right-box slider-box-2">
              <div className="right-box-text">3 Easy steps</div>
              <div className="right-box-bottom-img">
                <img src={logoWhite} alt={"logoWhite"} />
              </div>
              <div className="next-btn-arrow hover-zoom" onClick={nextSlide}>
                <img src={forwardArrow} width="100%" alt="backwardArrow" />
              </div>
            </div>
            <img
              className="close"
              src={window.innerWidth < 950 ? crossIcon : crossIconWhite}
              onClick={() => {
                props.begin && props.begin();
                skipTour();
              }}
              width="16px"
              height="16px"
              alt="crossIcon"
            />
          </div>
        )}
        {sliderIndex === 2 && (
          <div className="welcome-box">
            <div className="left-box">
              <div className="page-dots">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot slider-box-3"></span>
                <span className="dot"></span>
              </div>
              <div className="left-box-title">Advanced Settings</div>
              <div className="left-box-toggle-button">
                <ToggleButton
                  value={briefOn || false}
                  activeLabel="On"
                  inactiveLabel="Off"
                  colors={{
                    active: {
                      base: "#7ccf77",
                    },
                    inactive: {
                      base: "#cbcbcb",
                    },
                  }}
                  onToggle={(value) => {
                    setBriefOn(!briefOn);
                  }}
                />
              </div>
              <div className="left-box-desc pt-30">
                Enable Advanced Settings to ask for very specific help! :)
              </div>
              <div className="left-box-btn">
                <div className="btn-next hover-zoom" onClick={nextSlide}>
                  Next
                </div>
                <div
                  className="btn-skip"
                  onClick={() => {
                    props.begin && props.begin();
                    skipTour();
                  }}
                >
                  Skip Tour
                </div>
              </div>
              <div className="prev-btn-arrow hover-zoom" onClick={prevSlide}>
                <img src={backwardArrow} width="100%" alt="backwardArrow" />
              </div>
            </div>
            <div className="right-box slider-box-3">
              <div className="right-box-img">
                <img src={outputLength} width="100%" alt="outputLength" />
              </div>
              <div className="right-box-bottom-img">
                <img src={logoWhite} alt="logo" />
              </div>
              <div className="next-btn-arrow hover-zoom" onClick={nextSlide}>
                <img src={forwardArrow} width="100%" alt="backwardArrow" />
              </div>
            </div>
            <img
              className="close"
              src={window.innerWidth < 950 ? crossIcon : crossIconWhite}
              onClick={() => {
                props.begin && props.begin();
                skipTour();
              }}
              width="16px"
              height="16px"
              alt="crossIcon"
            />
          </div>
        )}
        {sliderIndex === 3 && (
          <div className="welcome-box">
            <div className="left-box">
              <div className="page-dots">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot slider-box-4"></span>
              </div>

              <div className="left-box-title">Cool Functions</div>
              <img src={onBoard} width="100%" alt={"onBoard"} />
              <div className="left-box-btn pt-0">
                <div
                  className="btn-next hover-zoom"
                  onClick={() => {
                    props.begin && props.begin();
                    skipTour();
                    trackButtonClick("Tour Modal", "Begin");
                  }}
                >
                  Begin
                </div>
              </div>
              <div className="prev-btn-arrow hover-zoom" onClick={prevSlide}>
                <img src={backwardArrow} width="100%" alt="backwardArrow" />
              </div>
            </div>
            <div className="right-box slider-box-4 flex-direction-column">
              <div className="right-box-text-small">expand.</div>
              <div className="right-box-text-small">shorten.</div>
              <div className="right-box-text-small">rephrase.</div>
              <div className="right-box-bottom-img">
                <img src={logoWhite} alt="logo" />
              </div>
            </div>
            <img
              className="close"
              src={window.innerWidth < 950 ? crossIcon : crossIconWhite}
              onClick={() => {
                props.begin && props.begin();
                skipTour();
              }}
              width="16px"
              height="16px"
              alt="crossIcon"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TourModal;
