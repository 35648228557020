import React from "react";
import { trackButtonClick } from "utils";
import { helpdIcon, helpdIconPink } from "assestes";
import { Menu } from "@material-ui/core";
import HelpPopup from "component/HelpPopup";

function HelpUI(props) {
  const { closeSideMenu, setDrawerOpen, setAnchorEl, open, anchorEl } = props;

  const handleHelpOpen = (event) => {
    trackButtonClick("Write Pad", "Help Open");
    closeSideMenu();
    setAnchorEl(event.currentTarget);
    setDrawerOpen(false);
  };
  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className="btn-action btn-desk hover-zoom"
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleHelpOpen}
        title="help"
      >
        <img
          src={helpdIcon}
          alt="copyIcon"
          className={open ? "icon-img-click" : ""}
        />
        <img
          src={helpdIconPink}
          alt="copyIcon"
          className={"hover-icon" + (open ? "icon-img-click" : "")}
        />
        {open ? (
          <span className="btn-text-hidden-mobile">&nbsp;help</span>
        ) : (
          <span className="btn-text-hidden-mobile">&nbsp;help</span>
        )}
      </div>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        keepMounted
        MenuListProps={{ style: { padding: "0px" } }}
        PaperProps={{
          className: "help-menu-popup",
          style: { top: "115px !important", boxShadow: "none" },
        }}
        className="help-menu-popup"
        onClose={handleHelpClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <HelpPopup onClose={handleHelpClose} />
      </Menu>
    </>
  );
}

export default HelpUI;
// export default React.memo(HelpUI);
