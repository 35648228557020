import React from "react";

function StateUI(props) {
  const { noteTypeId, words, chars, isSaved } = props;
  return (
    <div className="states">
      <div className="mode">
        Mode: {noteTypeId === 1 && "Email"}
        {noteTypeId === 2 && "Story"}
        {noteTypeId === 3 && "Article"}
      </div>
      <div>Words: {words}</div>
      <div>Characters: {chars}</div>
      <div>{isSaved ? "saved." : "saving..."}</div>
    </div>
  );
}

export default StateUI;
// export default React.memo(StateUI);
