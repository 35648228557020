import _ from "lodash";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { paymentData } from "utils/constant";
import { Paths } from "utils/constant";

function PaymentDone() {
  const history = useHistory();

  useEffect(() => {
    try {
      window.dataLayer = window.dataLayer || [];
      let subscription_plan = "Trial";
      let value = 1;
      const url = _.get(window, "location.href", "");
      if (url.indexOf("#monthly_plan") > -1) {
        subscription_plan = "Monthly";
        value = paymentData.monthlyPrice;
      } else if (url.indexOf("#annual_plan") > -1) {
        subscription_plan = "Annual";
        value = paymentData.yearlyPrice;
      }
      window.dataLayer.push({
        event: "purchase",
        subscription_plan: subscription_plan,
        value: value,
      });
    } catch (err) {}
    setTimeout(() => {
      if (
        new URLSearchParams(window.location.search).get("isAfterSignUp") ===
        "true"
      )
        history.push({
          pathname: Paths.signUpDone,
          state: { isRedirectFromRegister: true },
        });
      else history.push("/");
    }, 2500);
  }, []);
  return (
    <div
      style={{
        textAlign: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <lottie-player
        src="https://assets1.lottiefiles.com/packages/lf20_y2hxPc.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        autoplay
      ></lottie-player>
      <h1>Complete!</h1>
      <h4>Redirecting...</h4>
    </div>
  );
}

export default PaymentDone;
