import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import "./style.css";
import { DeleteNote } from "services/note";
import _ from "lodash";
import { trackButtonClick } from "utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog = (props) => {
  const {
    children,
    deleteCallback,
    confirmClick,
    type,
    isSingalOwner = false,
    isTeamMode = false,
  } = props;
  let { noteId } = props;
  if (!_.isArray(noteId)) {
    noteId = [noteId];
  }
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (e) => {
    if (noteId.length > 0) {
      trackButtonClick("DeleteDialog", "Open");
      e.stopPropagation();
      e.preventDefault();
      setOpen(true);
    }
  };

  const handleClose = (e) => {
    trackButtonClick("DeleteDialog", "No");
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const handleBlur = () => {
    if (_.toLower(deleteText) === "delete") {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleDelete = async (e) => {
    if (_.toLower(deleteText) === "delete" || type === "chat") {
      if (!loading) {
        setLoading(true);
        trackButtonClick("DeleteDialog", "Delete");
        if (confirmClick) {
          await confirmClick();
          setOpen(false);
          setLoading(false);
        } else {
          if (noteId.length > 0) {
            e.stopPropagation();
            e.preventDefault();
            const res = await DeleteNote(noteId);
            if (res.data > 0 || _.get(res, "data.value", "") === "ok")
              deleteCallback();
            setOpen(false);
            setLoading(false);
          }
        }
      }
    }
  };

  return (
    <>
      <span onClick={handleClickOpen}>{children}</span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="delete-dialog">
              <div className="delete-title-1">Delete</div>
              <div className="delete-title-2">
                {isSingalOwner ? (
                  <>You are about to delete an article</>
                ) : (
                  <>
                    You are about to delete a {type}{" "}
                    {isTeamMode && <>that may involve other team members</>}
                  </>
                )}
              </div>
              <div className="delete-note-list">
                <ul className="dashed">
                  <li>
                    This action <b>cannot</b> be reversed.
                  </li>
                  <li>
                    You will lose <b>all</b> work associated to this {type}.
                  </li>
                </ul>
              </div>
              {type !== "chat" && (
                <>
                  <div className="confirm-input-div">
                    <input
                      className={"confirm-input"}
                      placeholder={`Please type 'delete' to confirm`}
                      value={deleteText}
                      onChange={(e) => {
                        if (_.toLower(e.target.value) === "delete" && isError) {
                          setIsError(false);
                        }
                        setDeleteText(e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                    {isError && (
                      <span style={{ color: "#d95a4f", fontSize: "14px" }}>
                        Please type 'delete' in the above text area.
                      </span>
                    )}
                  </div>
                </>
              )}
              <div className="d-flex">
                <div className="btn-wrapper">
                  <div
                    className="d-btn btn-delete hover-zoom"
                    onClick={handleDelete}
                  >
                    {loading ? (
                      <CircularProgress
                        size={25}
                        style={{ color: "white", marginTop: "6px" }}
                      />
                    ) : (
                      "Delete"
                    )}
                  </div>
                  <div
                    className="d-btn btn-delete hover-zoom btn-no"
                    onClick={handleClose}
                  >
                    No
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
