import axiosInstance from "utils/axiosInstance";
import { endPoints } from "services/endPoints";
import stringSimilarity from "string-similarity";
import _ from "lodash";

export const getAllNotes = async (page, type, search = "") => {
  return await axiosInstance.get(
    `${endPoints.getAllNotes}?noteType=${type}&perPage=10&page=${page}&search=${search}`
  );
};
export const EditNote = async (data) => {
  if (data.NoteId > 0) return await axiosInstance.put(endPoints.EditNote, data);
  return await axiosInstance.post(endPoints.EditNote, {
    Title: data.Title,
    Description: data.Description,
    NoteType: data.NoteType,
    Type: data.Type ? data.Type : 1,
  });
};

export const requestHumanizeNote = async (data) => {
  const res = await axiosInstance.post(endPoints.HumanizeNoteRequest, {
    Description: data,
  });
  return _.get(res, "data", null);
};

export const getHumanizeNote = async (data) => {
  const res = await axiosInstance.post(endPoints.HumanizeNoteFetch, {
    id: data,
  });
  if (_.get(res, "data.status", "") === "done")
    return _.get(res, "data.output", "");
  else return "";
};

export const WriteNote = async (data, recallCount = 0) => {
  let endPoint = endPoints.WriteNote;
  const inputData = data.Description;
  // if (data.NoteType === 1 || data.NoteType === 2) {
  endPoint = endPoints.newWriteNote;
  if (
    data.Description.length > 0 &&
    (data.Description[data.Description.length - 1] === "." ||
      data.Description[data.Description.length - 1] === '"' ||
      data.Description[data.Description.length - 1] === "?" ||
      data.Description[data.Description.length - 1] === "!")
  ) {
    endPoint = endPoints.WriteNote;
  }
  // }
  const res = await axiosInstance.post(endPoint, data);
  if (
    stringSimilarity.compareTwoStrings(
      inputData,
      res.data.Description.replace(inputData, "")
    ) > 0.9 &&
    recallCount < 4
  ) {
    return WriteNote(data, recallCount + 1);
  }
  return res;
};
export const DeleteNote = async (noteId) => {
  if (_.isArray(noteId)) {
    return await axiosInstance.post(endPoints.DeleteNotes, { noteIds: noteId });
  } else {
    return await axiosInstance.delete(
      endPoints.DeleteNote.replace(":id", noteId)
    );
  }
};
export const NoteSelectAction = async (action, text, NoteType) => {
  return await axiosInstance.post(endPoints.NoteSelectAction + `/${action}`, {
    Text: text,
    NoteType: NoteType,
  });
};

export const generateNoteItem = async (noteId, Title) => {
  return await axiosInstance.post(
    endPoints.generateNoteItem.replace(":noteId", noteId),
    { Title: Title }
  );
};

export const getNoteWithItems = async (noteId) => {
  return await axiosInstance.get(
    endPoints.getNoteItems.replace(":noteId", noteId)
  );
};

export const editNoteItem = async (
  noteId,
  itemId,
  title,
  description,
  briefOn,
  brief,
  noteLength
) => {
  return await axiosInstance.put(
    endPoints.editNoteItem
      .replace(":noteId", noteId)
      .replace(":itemId", itemId),
    {
      Title: title,
      Description: description,
      BriefEnable: briefOn,
      Brief: brief,
      NoteLength: noteLength,
    }
  );
};
