export const Paths = Object.freeze({
  landing: "/",
  dashboard: "/",
  teamNoteFullView: "/workspace/:id/note/:noteId/fullview",
  pricing: "/pricing",
  ourVision: "/our-vision",
  guide: "/guide",
  login: "/login",
  signUp: "/sign-up",
  welcome: "/welcome",
  profile: "/profile",
  storyArticle: "/story-article",
  waitingListSuccess: "/waiting-list-success",
  faqs: "/faqs",
  contact: "/contact",
  billingInfo: "/billing-info",
  upgradeAccount: "/upgrade-account",
  contactUs: "/contact-us",
  resetPassword: "/reset-password",
  paymentDone: "/payment-done",
  signUpDone: "/sign-up-done",
  passwordChanged: "/password-changed",
  otpVerifying: "/otp-verifying",
  manageWorkspace: "/workspace/manage/:id",
  createWorkspaceNote: "/workspace/:id/note/create",
  createAssistedArticleNote: "/assisted/article/:noteId/note",
  workspaceNote: "/workspace/:id/note/:noteId/edit",
  chat: "/chat/:chatId",
  emptyChat: "/chat",
});

export const wordsLimitForAction = {
  shorten: 38,
  rephrase: 40,
  expand: 30,
};

const paymentTest = false;
export const paymentData = paymentTest
  ? Object.freeze({
      monthlyId: "price_1OTibsBauPwI6cg87p0WQ5Hn",
      monthlyPrice: 39,
      yearlyPrice: 420,
      yearlyId: "price_1OTibsBauPwI6cg87p0WQ5Hn",
    })
  : Object.freeze({
      monthlyId: "price_1OTiaBBauPwI6cg80BpUfSkc",
      monthlyPrice: 39,
      yearlyPrice: 420,
      yearlyId: "price_1OTibIBauPwI6cg8SLCRHGDH",
    });
