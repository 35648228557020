import AccountLayout from "pages/Account/AccountLayout";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "utils/constant";
import "./style.css";
import CreateWorkspaceNoteItem from "./CreateNoteItem";
import {
  createWorkspaceNote,
  editWorkspaceNote,
  generateWorkspaceNoteItem,
  getWorkspaceNoteWithItems,
  getWorkspacesTeam,
} from "services/workspace";
import { useEffect } from "react";
import _ from "lodash";
import ConfirmationDialog from "component/Dialog/ConfirmationDialog/ConfirmationDialog";
import { EditNote, generateNoteItem, getNoteWithItems } from "services/note";

const CreateWorkspaceNote = (props) => {
  const {
    match: {
      params: { id, noteId },
    },
  } = props;
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const [workspaceTeam, setWorkspaceTeam] = useState([]);
  const [loader, setLoader] = useState(false);
  const [saveActive, setSaveActive] = useState(false);
  const history = useHistory();
  const location = useLocation();

  let isAssistedArticle = false;
  if (_.toLower(location.pathname.indexOf("assisted/article")) > -1) {
    isAssistedArticle = true;
  }

  const getNoteDetails = async () => {
    if (+noteId) {
      setLoader(true);
      let res = null;
      if (isAssistedArticle) {
        res = await getNoteWithItems(noteId);
        setTitle(_.get(res, "data.note.title", ""));
      } else {
        res = await getWorkspaceNoteWithItems(id, noteId);
        let team = await getWorkspacesTeam(id);
        let users = [];
        _.forEach(team.data.admin, (a) => {
          a.firstName = "Me";
          a.lastName = "";
          users.push(a);
        });
        _.forEach(team.data.team, (a) => {
          if (a.status === "active") users.push(a);
        });
        setWorkspaceTeam(users);
      }
      setTitle(_.get(res, "data.note.title", ""));
      setItems(_.get(res, "data.items", []));

      setLoader(false);
    }
  };
  useEffect(() => {
    getNoteDetails();
  }, [noteId]);

  const createNote = async () => {
    if (+noteId) {
      setLoader(true);
      if (_.get(location, "state.notBegin", false)) {
        if (isAssistedArticle) {
          generateNoteItem(noteId, title).then(() => {
            setSaveActive(false);
            setLoader(false);
            getNoteDetails();
          });
        } else
          generateWorkspaceNoteItem(id, noteId, title).then(() => {
            setSaveActive(false);
            setLoader(false);
            getNoteDetails();
          });
      } else {
        if (isAssistedArticle) {
          EditNote({
            Title: title,
            Description: "",
            NoteType: 3,
            NoteId: noteId,
          }).then(() => {
            setSaveActive(false);
            setLoader(false);
          });
        } else
          editWorkspaceNote(id, noteId, title).then(() => {
            setSaveActive(false);
            setLoader(false);
          });
      }
    } else {
      setLoader(true);
      if (isAssistedArticle) {
        let res = await EditNote({
          Title: title,
          Description: "",
          NoteType: 3,
          Type: 2,
        });
        if (_.get(res, "data.id", -1) > -1) {
          history.push(
            Paths.createAssistedArticleNote.replace(
              ":noteId",
              _.get(res, "data.id", -1)
            ),
            { notBegin: true }
          );
          setLoader(false);
        }
      } else {
        let res = await createWorkspaceNote(id, title);
        if (_.get(res, "data[0][0].id", -1) > -1) {
          history.push(
            Paths.workspaceNote
              .replace(":id", id)
              .replace(":noteId", _.get(res, "data[0][0].id", -1)),
            { notBegin: true }
          );
          setLoader(false);
        }
      }
    }
  };

  return (
    <AccountLayout
      hideFooter
      style={{ background: "#efefef" }}
      backClick={() => {
        if (isAssistedArticle) {
          history.push(Paths.dashboard, {
            teamMode: false,
            openWorkspaceId: id,
          });
        } else
          history.push(Paths.dashboard, {
            teamMode: true,
            openWorkspaceId: id,
          });
      }}
    >
      <div className="create-workspace-note">
        <div style={{ maxWidth: "1068px", margin: "auto", display: "flex" }}>
          <div
            className="title"
            style={{ flex: 1, paddingLeft: items.length > 0 ? "122px" : "0px" }}
          >
            Teamwork, makes the dream work.
          </div>
          {items.length > 0 && (
            <div style={{ display: "inline-block" }}>
              <button
                className="m-a pink-button"
                onClick={() => {
                  if (isAssistedArticle) {
                    history.push(Paths.dashboard, {
                      teamMode: false,
                      noteId: +noteId,
                    });
                  } else
                    history.push(
                      Paths.teamNoteFullView
                        .replace(":id", id)
                        .replace(":noteId", noteId),
                      {
                        teamMode: true,
                        openWorkspaceId: id,
                      }
                    );
                }}
              >
                Begin
              </button>
            </div>
          )}
        </div>
        <div className="sub-title">
          Insert your title, and we'll generate key headlines{" "}
          {isAssistedArticle ? (
            "which you can use to create a coherent piece."
          ) : (
            <>
              which you can assign to your{" "}
              <span
                onClick={() =>
                  history.push(Paths.manageWorkspace.replace(":id", id), {
                    goBack: true,
                  })
                }
              >
                team
              </span>
            </>
          )}
        </div>
        <div className="title-input">
          <input
            placeholder="Enter your note title here"
            type="text"
            value={title}
            onChange={(evt) => {
              setTitle(evt.target.value);
              if (!saveActive) setSaveActive(true);
            }}
          ></input>
          {_.get(location, "state.notBegin", false) ? (
            <ConfirmationDialog
              confirmClick={createNote}
              text={
                "Are you sure want to regenerate? you will lose current output and all changes"
              }
            >
              <button disabled={title.length === 0 || loader}>
                {!loader ? "Generate" : "Generating..."}
              </button>
            </ConfirmationDialog>
          ) : (
            <button
              onClick={createNote}
              disabled={title.length === 0 || (noteId && !saveActive) || loader}
            >
              {+noteId && !_.get(location, "state.notBegin", false)
                ? loader
                  ? "Saving..."
                  : "Save Title"
                : loader
                ? "Generating..."
                : "Generate"}
            </button>
          )}
        </div>
        {_.map(items, (item) => (
          <CreateWorkspaceNoteItem
            key={item.id}
            item={item}
            workspaceId={id}
            workspaceTeam={workspaceTeam}
            isAssistedArticle={isAssistedArticle}
          />
        ))}
      </div>
    </AccountLayout>
  );
};

export default CreateWorkspaceNote;
