import { useHistory } from "react-router-dom";
import { Paths } from "utils/constant";
import "./style.css";

const CustomiseLengthMobile = (props) => {
  const { noteLength, setNoteLength, typeId } = props;
  const history = useHistory();

  return (
    <div className="customise-length-mobile">
      {typeId === 3 && (
        <div className="generate-assisted">
          <div
            className={`button hover-zoom ${typeId === 3 ? "" : "disabled"}`}
            style={typeId === 3 ? {} : { cursor: "unset" }}
            onClick={() => {
              if (typeId === 3)
                history.push(
                  Paths.createAssistedArticleNote.replace(":noteId", 0)
                );
            }}
          >
            Generate Assisted Article
          </div>
          <div className="sub-text">
            {typeId === 3 ? (
              <>
                Get extra help in structuring your article, at a click of a
                button.
              </>
            ) : (
              <>
                Assisted feature only applicable in Article mode. You can still
                make use of the description box.
              </>
            )}
          </div>
        </div>
      )}
      {false && (
        <>
          <h4> Output Length </h4>
          <div className="control">
            <div className="control-item">
              <div
                className={"round" + (noteLength === 1 ? " active" : "")}
                onClick={() => setNoteLength(1)}
              ></div>
              <div className={`text ${noteLength === 1 ? "active" : ""}`}>
                A little
              </div>
            </div>
            <div className="line">
              <div className="line-content"></div>
            </div>
            <div className="control-item">
              <div
                className={"round" + (noteLength === 2 ? " active" : "")}
                onClick={() => setNoteLength(2)}
              ></div>
              <div className={`text ${noteLength === 2 ? "active" : ""}`}>
                More
              </div>
            </div>
            <div className="line">
              <div className="line-content"></div>
            </div>
            <div className="control-item">
              <div
                className={"round" + (noteLength === 3 ? " active" : "")}
                onClick={() => setNoteLength(3)}
              ></div>
              <div className={`text ${noteLength === 3 ? "active" : ""}`}>
                A lot
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomiseLengthMobile;
