import { WriteArticle, WriteStory } from "assestes";
import Header from "component/Header";
import React from "react";
import "./style.css";

const StoryArticle = () => {
  return (
    <div className="story-article-wrapper">
      <Header />
      <div className="story-article">
        <div className="title">
          Select the type of writing you want to begin
        </div>
        <div className="d-flex">
          <div className="card-wrapper">
            <div className="card">
              <div className="title">Write a Story</div>
              <img className="story" src={WriteStory} alt="WriteStory" />
              <div className="btn-start hover-zoom">Start Now</div>
            </div>
            <div className="card">
              <div className="title">Write an Article</div>
              <img className="article" src={WriteArticle} alt="WriteArticle" />
              <div className="btn-start hover-zoom">Start Now</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryArticle;
