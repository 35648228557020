import React, { useContext } from "react";
import AccountLayout from "../AccountLayout";
import "./style.css";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import { capitalize, trackButtonClick } from "utils";
import HeaderNew from "component/Header/index.new";
import FooterNew from "component/Footer/index.new";
import Footer from "component/Footer";
import Header from "component/Header";

function ContactUs(props) {
  const { wantAccountLayout = true } = props;
  const { auth } = useContext(AuthContext);
  const firstName = _.get(auth, "userData.FirstName", "");
  const lastName = _.get(auth, "userData.LastName", "");
  let fullName = "";
  if (firstName && lastName) {
    fullName = `[${firstName} ${lastName}] - `;
  }

  const content = (
    <div className="contact-us-account">
      <div className="title-1">For other queries, please email us at:</div>
      <div className="title-2">info@writelyai.com</div>
      <div className="title-3">We will get back to you within 2 hours</div>
      <div
        className="btn hover-zoom"
        onClick={() => {
          trackButtonClick("ContactUs", "Email Us");
          window.location.href = `mailto:info@writelyai.com?subject=${capitalize(
            fullName + "customer support"
          )}`;
        }}
      >
        Email Us
      </div>
    </div>
  );
  return wantAccountLayout ? (
    <AccountLayout>{content}</AccountLayout>
  ) : (
    <>
      {window.innerWidth < 750 ? <Header /> : <HeaderNew />}
      {content}
      {window.innerWidth < 550 ? <Footer /> : <FooterNew />}
    </>
  );
}

export default ContactUs;
