import { useContext, useEffect } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AuthContext from "contexts/authContext";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import { Paths } from "utils/constant";
import Scrollbar from "component/Scrollbar";
import { trackButtonClick } from "utils";

const Sidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  const { auth, changeAuth } = useContext(AuthContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100vw",
        zIndex: "1500 !important",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <CloseIcon onClick={onMobileClose} style={{ color: "#D967BE" }} />
        </Box>
        <Divider />
        <Box sx={{ mt: 1, ml: 2 }}>
          {!auth.isLoggedIn && (
            <>
              {_.map(
                [
                  { path: Paths.landing, text: "Home" },
                  {
                    path: Paths.landing + "#pricing",
                    text: "Pricing",
                    samePage: true,
                    id: "pricing",
                  },
                  {
                    path: Paths.landing + "#faqs",
                    text: "FAQs",
                    samePage: true,
                    id: "faqs",
                  },
                  { path: Paths.contact, text: "Contact" },
                  { path: Paths.login, text: "Log in" },
                  { path: Paths.signUp, text: "Sign Up" },
                ],
                (item) => (
                  <MenuItem
                    component={RouterLink}
                    to={item.path}
                    onClick={() => {
                      trackButtonClick("Sidebar", item.text);
                      if (item.samePage) {
                        window.location.href = item.path;
                      }
                    }}
                    key={item.text}
                  >
                    <ListItemText
                      primary={
                        <Typography color="textPrimary" variant="body2">
                          {item.text}
                        </Typography>
                      }
                    />
                  </MenuItem>
                )
              )}
            </>
          )}
          {auth.isLoggedIn && (
            <MenuItem
              component={RouterLink}
              onClick={() => {
                trackButtonClick("Sidebar", "Log out");
                changeAuth({ isLoggedIn: false, userData: {} });
              }}
              to={Paths.login}
            >
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="body2">
                    Log out
                  </Typography>
                }
              />
            </MenuItem>
          )}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="up"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            width: "100%",
            style: { zIndex: 1500 },
            sx: {
              backgroundColor: "background.paper",
              width: "100%",
              zIndex: 1500,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
