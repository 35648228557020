import { AuthProvider } from "contexts/authContext";
import RootContextWrapper from "contexts/rootContext";
import MainRoutes from "./routes";
import Loader from "utils/Loader";
import AppInit from "utils/AppInit";
import { ToastContainer } from "react-toastify";
import { ToastProvider } from "react-toast-notifications";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import ReactGA from "react-ga";
import "./App.css";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const INTERCOM_APP_ID = "wozxlwgc";

const App = () => {
  const history = useHistory();
  useEffect(() => {
    ReactGA.initialize("UA-233901470-2");
    history.listen((location) => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  }, []);

  return (
    <div className="App">
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LdHXsohAAAAAMUZvHlpdt8_IcIAi2_YWk5dEzeE"> */}
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <AuthProvider>
          <RootContextWrapper>
            <ToastProvider>
              <AppInit />
              <Loader />
              <ToastContainer />
              <MainRoutes />
            </ToastProvider>
          </RootContextWrapper>
        </AuthProvider>
      </IntercomProvider>
      {/* </GoogleReCaptchaProvider> */}
      <div className="back-white" />
    </div>
  );
};

export default App;
