import { Box, CircularProgress } from "@material-ui/core";
import ConfirmationDialog from "component/Dialog/ConfirmationDialog/ConfirmationDialog";
import InviteTeammemberDialog from "component/Dialog/InviteTeammember/InviteTeammemberDialog";
import _ from "lodash";
import AccountLayout from "pages/Account/AccountLayout";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  deleteTeamMember,
  deleteWorkspace,
  getWorkspacesById,
  getWorkspacesTeam,
} from "services/workspace";
import { Paths } from "utils/constant";
import LetteredAvatar from "react-lettered-avatar";
import ToggleButton from "react-toggle-button";
import "./style.css";
import { deleteIcon2 } from "assestes";
import DeleteDialog from "component/Dialog/DeleteDialog/DeleteDialog";

const ManageWorkspace = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const location = useLocation();
  const history = useHistory();

  const goBack = _.get(location, "state.goBack", false);

  const [workspace, setWorkspace] = useState({});
  const [loader, setLoader] = useState(false);
  const [workspaceTeam, setWorkspaceTeam] = useState({});
  const [roleVisisble, setRoleVisisble] = useState(true);
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);
  useEffect(async () => {
    setLoader(true);
    const res = await getWorkspacesById(id);
    setWorkspace(res.data);
    const team = await getWorkspacesTeam(id);
    setWorkspaceTeam(team.data);
    setLoader(false);
  }, []);

  const loaderUI = (
    <Box mx="auto" textAlign="center" mt={2}>
      <CircularProgress thickness={4.5} style={{ color: "#D967BE" }} />
    </Box>
  );
  return (
    <AccountLayout
      hideFooter
      style={{ background: "#efefef" }}
      backClick={() =>
        goBack
          ? history.goBack()
          : history.push(Paths.dashboard, { teamMode: true })
      }
    >
      <div className="manage-workspace">
        <div className="actions">
          {/* <button
            className="add-team"
            style={{ background: "#039e62" }}
            onClick={() => setInvitePopupOpen(true)}
          >
            Add New Team Member
          </button> */}
          <div className="ws-name">{_.get(workspace, "name", "")}</div>
          <span className="role-key d-flex ml-a mr-5">
            Roles &nbsp;&nbsp;
            <ToggleButton
              value={roleVisisble}
              className="toggle"
              activeLabel="On"
              inactiveLabel="Off"
              colors={{
                active: {
                  base: "#7ccf77",
                },
                inactive: {
                  base: "#cbcbcb",
                },
              }}
              onToggle={(value) => {
                setRoleVisisble(!roleVisisble);
              }}
            ></ToggleButton>
            &nbsp;&nbsp;
          </span>
          <DeleteDialog
            type="workspace"
            isTeamMode={true}
            confirmClick={async () => {
              await deleteWorkspace(id);
              history.push(Paths.dashboard, { teamMode: true });
            }}
          >
            <img
              src={deleteIcon2}
              alt="delete"
              className="delete"
              height={"25px"}
              style={{ marginTop: "4px" }}
            />
            {/* <button style={{ background: "#ef4136" }}>Delete Workspace</button> */}
          </DeleteDialog>
        </div>
        <div className="team-list">
          {loader ? (
            loaderUI
          ) : (
            <>
              <div
                className="team-item add-member"
                onClick={() => setInvitePopupOpen(true)}
              >
                <div className="role" style={{ opacity: 0 }}>
                  A
                </div>
                <LetteredAvatar
                  name={`+`}
                  size={90}
                  backgroundColors={["#ff0000"]}
                  style={{ background: "#ff0000" }}
                />
                <div className="name">Add new</div>
                <div className="status" style={{ opacity: 0 }}>
                  status
                </div>
              </div>
              {_.map(_.get(workspaceTeam, "admin", []), (item) => (
                <div className="team-item">
                  <div
                    className="role"
                    style={{ opacity: roleVisisble ? 1 : 0 }}
                  >
                    Admin
                  </div>
                  <LetteredAvatar
                    name={`${item.firstName} ${item.lastName}`}
                    size={90}
                  />
                  <div className="name">
                    {item.firstName} {item.lastName}
                  </div>
                  <div className="status">{item.status}</div>
                </div>
              ))}

              {_.map(
                _.get(workspaceTeam, "team", []),
                (item) =>
                  item.status !== "deleted" && (
                    <div className="team-item" key={item.id}>
                      <span className="delete-icon">
                        <ConfirmationDialog
                          confirmClick={async () => {
                            setLoader(true);
                            await deleteTeamMember(item.id);
                            const team = await getWorkspacesTeam(id);
                            setWorkspaceTeam(team.data);
                            setLoader(false);
                          }}
                          text={"Are you sure want to delete this member?"}
                        >
                          <img
                            src={deleteIcon2}
                            alt="delete"
                            className="delete"
                            height={"29px"}
                          />
                        </ConfirmationDialog>
                      </span>
                      <div
                        className="role"
                        style={{ opacity: roleVisisble ? 1 : 0 }}
                      >
                        Team member
                      </div>
                      <LetteredAvatar
                        name={`${item.firstName} ${item.lastName}`}
                        size={90}
                      />
                      <div className="name">
                        {item.firstName} {item.lastName}
                      </div>

                      <div className="status">{item.status}</div>
                      {/* <div className="action">
                        <ConfirmationDialog
                          confirmClick={async () => {
                            setLoader(true);
                            await deleteTeamMember(item.id);
                            const team = await getWorkspacesTeam(id);
                            setWorkspaceTeam(team.data);
                            setLoader(false);
                          }}
                          text={"Are you sure want to delete this member?"}
                        >
                          <button>Remove</button>
                        </ConfirmationDialog>
                      </div> */}
                    </div>
                  )
              )}
            </>
          )}
        </div>
      </div>
      {invitePopupOpen && (
        <InviteTeammemberDialog
          workspaceId={id}
          handleClose={() => setInvitePopupOpen(false)}
          added={async () => {
            setLoader(true);
            const team = await getWorkspacesTeam(id);
            setWorkspaceTeam(team.data);
            setInvitePopupOpen(false);
            setLoader(false);
          }}
        ></InviteTeammemberDialog>
      )}
    </AccountLayout>
  );
};

export default ManageWorkspace;
