import React, { useState } from "react";
import _ from "lodash";
import { createContext } from "react";
import { HotKeys } from "react-hotkeys";

export const RootContext = createContext({});

const RootContextWrapper = (props) => {
  const [state, setState] = useState({});

  const setGlobal = (index, newData) => {
    const { oldData } = index;
    if (!_.isEqual(oldData, newData)) {
      setState({
        ...state,
        [index]: newData,
      });
    }
  };

  const setGlobalState = (callback) => {
    setState(callback);
  };

  const keyMap = {
    macNightMode: "alt+n",
    winNightMode: "shift+n",
    winNightModeN: "shift+N",
  };
  const startNightMode = React.useCallback(() => {
    setState((current) => ({
      isNightMode: current.isNightMode ? false : true,
    }));
  }, []);

  const handlers = {
    macNightMode: startNightMode,
    winNightMode: startNightMode,
    winNightModeN: startNightMode,
  };

  const { children } = props;
  return (
    <HotKeys keyMap={keyMap}>
      <RootContext.Provider
        value={{
          ...state,
          setGlobal: setGlobal,
          setGlobalState: setGlobalState,
        }}
      >
        <HotKeys handlers={handlers}>{children}</HotKeys>
      </RootContext.Provider>
    </HotKeys>
  );
};

export default RootContextWrapper;
