import { undoIcon, undoIconPink } from "assestes";
import React from "react";

function CopyUndoUI(props) {
  const { handleUndo } = props;
  return (
    <>
      <div
        className="btn-action btn-undo hover-zoom "
        onClick={handleUndo}
        title="undo"
      >
        <img src={undoIcon} alt="undoIcon" />
        <img src={undoIconPink} alt="undoIcon" className="hover-icon" />
        <span className="btn-text-hidden-mobile">&nbsp;undo</span>
      </div>
    </>
  );
}

export default CopyUndoUI;
// export default React.memo(CopyUndoUI);
