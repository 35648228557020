import { useHistory } from "react-router-dom";
import { Paths } from "utils/constant";
import { HeaderLogoIcon } from "assestes";
import { trackButtonClick } from "utils";

import "./style.scss";

const HeaderNew = (props) => {
  const history = useHistory();
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-3 col-sm-2 col-xl-1 d-flex align-items-center">
            <a
              href="/"
              className="logo m-a"
              onClick={(e) => {
                trackButtonClick("Header", "Logo");
              }}
            >
              <img src={HeaderLogoIcon} alt="" />
            </a>
          </div>
          <div className="col-9 col-sm-7 col-lg-7 col-xl-9 d-flex">
            <ul className="navigation header__navbar m-a">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a
                  href="/#plans"
                  onClick={(e) => {
                    trackButtonClick("Header", "Pricing");
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  href="/#faqs"
                  onClick={(e) => {
                    trackButtonClick("Header", "FAQs");
                  }}
                >
                  FAQs
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  onClick={(e) => {
                    e.preventDefault();
                    trackButtonClick("Header", "Contact");
                    history.push(Paths.contact);
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-3 col-xl-2 d-flex align-items-center">
            <div
              className="login__link"
              onClick={() => {
                trackButtonClick("Header", "Log In");
                history.push(Paths.login);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 5C9.38194 5 8.77775 5.18328 8.26384 5.52666C7.74994 5.87004 7.3494 6.3581 7.11288 6.92911C6.87635 7.50013 6.81447 8.12847 6.93505 8.73466C7.05563 9.34085 7.35325 9.89767 7.79029 10.3347C8.22733 10.7717 8.78415 11.0694 9.39034 11.19C9.99654 11.3105 10.6249 11.2486 11.1959 11.0121C11.7669 10.7756 12.255 10.3751 12.5983 9.86116C12.9417 9.34725 13.125 8.74307 13.125 8.125C13.125 7.2962 12.7958 6.50134 12.2097 5.91529C11.6237 5.32924 10.8288 5 10 5ZM10 10C9.62916 10 9.26665 9.89003 8.95831 9.68401C8.64997 9.47798 8.40964 9.18514 8.26773 8.84253C8.12581 8.49992 8.08868 8.12292 8.16103 7.75921C8.23338 7.39549 8.41195 7.0614 8.67418 6.79917C8.9364 6.53695 9.27049 6.35837 9.63421 6.28603C9.99792 6.21368 10.3749 6.25081 10.7175 6.39273C11.0601 6.53464 11.353 6.77496 11.559 7.08331C11.765 7.39165 11.875 7.75416 11.875 8.125C11.8745 8.62213 11.6768 9.09875 11.3253 9.45028C10.9738 9.8018 10.4971 9.9995 10 10Z"
                  fill="white"
                />
                <path
                  d="M10 1.25C8.26942 1.25 6.57769 1.76318 5.13876 2.72464C3.69983 3.6861 2.57832 5.05267 1.91606 6.65152C1.25379 8.25037 1.08051 10.0097 1.41813 11.707C1.75575 13.4044 2.58911 14.9635 3.81282 16.1872C5.03653 17.4109 6.59563 18.2442 8.29296 18.5819C9.9903 18.9195 11.7496 18.7462 13.3485 18.0839C14.9473 17.4217 16.3139 16.3002 17.2754 14.8612C18.2368 13.4223 18.75 11.7306 18.75 10C18.7474 7.68017 17.8246 5.45611 16.1843 3.81574C14.5439 2.17537 12.3198 1.25265 10 1.25ZM6.25 16.4856V15.625C6.2505 15.1279 6.4482 14.6512 6.79973 14.2997C7.15125 13.9482 7.62788 13.7505 8.125 13.75H11.875C12.3721 13.7505 12.8488 13.9482 13.2003 14.2997C13.5518 14.6512 13.7495 15.1279 13.75 15.625V16.4856C12.612 17.1501 11.3178 17.5003 10 17.5003C8.68218 17.5003 7.38802 17.1501 6.25 16.4856ZM14.995 15.5788C14.9825 14.759 14.6485 13.9769 14.0649 13.401C13.4814 12.8251 12.6949 12.5015 11.875 12.5H8.125C7.30512 12.5015 6.51865 12.8251 5.93506 13.401C5.35147 13.9769 5.01746 14.759 5.005 15.5788C3.87161 14.5667 3.07234 13.2343 2.71303 11.7579C2.35372 10.2815 2.45132 8.73084 2.9929 7.31116C3.53449 5.89148 4.49452 4.66979 5.74586 3.80785C6.99721 2.94592 8.48084 2.48439 10.0003 2.48439C11.5198 2.48439 13.0034 2.94592 14.2548 3.80785C15.5061 4.66979 16.4661 5.89148 17.0077 7.31116C17.5493 8.73084 17.6469 10.2815 17.2876 11.7579C16.9283 13.2343 16.129 14.5667 14.9956 15.5788H14.995Z"
                  fill="white"
                />
              </svg>
              Log in
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNew;
