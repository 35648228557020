import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import "./style.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewWriteDialog = (props) => {
  const { freeTrialEndDialogOpen, setFreeTrialEndDialogOpen, confirmClick } =
    props;

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFreeTrialEndDialogOpen(false);
  };

  const handleConfirm = async (e) => {
    confirmClick();
  };

  return (
    <>
      <Dialog
        open={freeTrialEndDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            zIndex: "5000 !important",
            width: "432px",
            borderRadius: "8px",
            filter:
              "drop-shadow(1.414213562373095px 1.4142135623730951px 29px #404040)",
            boxShadow: "none",
          },
        }}
        maxWidth="sm"
        BackdropProps={{
          style: {
            zIndex: "5000 !important",
            opacity: "0.53",
            backgroundColor: "#909090",
          },
        }}
      >
        <DialogContent style={{ zIndex: "5000 !important" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="ReviewWriteDialog-dialog">
              <div className="oops">
                The best results happen when you work with the AI
              </div>
              <div className="devider"></div>
              <div className="text">
                We've identified <b>several</b> subsequent runs without your
                input. To proceed, please confirm you are happy with the AI's
                output
              </div>
              <div className="d-flex">
                <div className="btn-wrapper">
                  <div
                    className="d-btn m-later btn-delete hover-zoom"
                    onClick={handleClose}
                  >
                    Review
                  </div>
                  <div
                    className="d-btn btn-delete hover-zoom"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReviewWriteDialog;
