import {
  copyToClipboardBtn,
  loginModalImg,
  loginPopupBack,
  regenerateBtn,
} from "assestes";
import "./style.css";
import { Menu, MenuItem } from "@material-ui/core";
import AuthContext from "contexts/authContext";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Paths } from "utils/constant";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import _ from "lodash";
import "./style.css";
import { trackButtonClick } from "utils";
import ChatListItem from "./chatListItem";
import {
  addChatMessage,
  createChat,
  getAllChat,
  getChatMessages,
} from "services/chat";
import { Box, CircularProgress } from "@material-ui/core";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import autosize from "autosize";
import Typist from "react-typist";
import TypingLoader from "./typingLoader";

import FreeTrialDialog from "component/Dialog/FreeTrialDialog/FreeTrialDialog";

const Chat = (props) => {
  const {
    match: {
      params: { chatId },
    },
  } = props;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeView, setActiveView] = useState(
    chatId ? "chat-view" : "chat-list"
  );
  const [lastChatId, setLastChatId] = useState(-1);
  const [chats, setChats] = useState(_.get(location, "state.chats", []));
  const [chatMessages, setChatMessages] = useState(
    _.get(location, "state.chatMessages", [])
  );
  const [msgInput, setMsgInput] = useState([]);
  const [chatListLoader, setChatListLoader] = useState(false);
  const [chatMsgListLoader, setChatMsgListLoader] = useState(false);
  const [msgSending, setMsgSending] = useState(false);
  const [freeTrialEndDialogOpen, setFreeTrialEndDialogOpen] = useState(false);
  const [YTLink, setYTLink] = useState("");

  useEffect(() => {
    if (activeView === "chat-view") {
      gotoEnd();
    }
  }, [activeView]);

  useEffect(() => {
    if (chatId) {
      getChatMessage();
    } else {
      setYTLink("");
    }
  }, [chatId]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (document.querySelectorAll("textarea"))
        autosize.update(document.querySelectorAll("textarea"));
    }, 400);
  });

  const getChatMessage = async () => {
    setChatMsgListLoader(true);
    const chatmessageList = await getChatMessages(chatId);
    setChatMessages(_.get(chatmessageList, "data", []));
    if (_.get(chatmessageList, "data", []).length > 0)
      setLastChatId(
        _.get(chatmessageList, "data", [])[
          _.get(chatmessageList, "data", []).length - 1
        ].id
      );
    setChatMsgListLoader(false);
    gotoEnd();
  };

  useEffect(() => {
    getChats();
  }, []);

  useEffect(() => {
    gotoEnd();
  }, [msgSending]);

  const getChats = async () => {
    setChatListLoader(true);
    const chatList = await getAllChat();

    setChats(_.get(chatList, "data", []));
    setChatListLoader(false);
  };

  const gotoEnd = () => {
    document
      .getElementById("list-bottom")
      .scrollIntoView({ behavior: "smooth" });
  };

  const handleOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { pathname } = useLocation();
  const history = useHistory();
  const { changeAuth, auth } = useContext(AuthContext);

  const handleClick = (path) => {
    history.push(path, { dontWantTour: true });
  };

  const CreateAndSendMessage = async (msg, type, isYTLink = false) => {
    setChatListLoader(true);
    setMsgSending(true);

    setMsgInput(msg);
    gotoEnd();
    const newChatData = await createChat(msg.slice(0, 20));
    setChats([_.get(newChatData, "data[0][0]", {}), ...chats]);
    setChatListLoader(false);
    try {
      const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      if (pattern.test(_.trim(msg)) && _.trim(msg).split(" ").length === 1) {
        isYTLink = true;
      }
      const msgData = await addChatMessage(
        msg,
        type,
        _.get(newChatData, "data[0][0].id", ""),
        isYTLink
      );
      setChatMessages([
        ...chatMessages,
        msgData.data[0][0][0],
        msgData.data[1][0][0],
      ]);
      if (type === 1) {
        setMsgInput("");
      }
      gotoEnd();
      history.push(
        Paths.chat.replace(":chatId", _.get(newChatData, "data[0][0].id", "")),
        {
          chatMessages: [msgData.data[0][0][0], msgData.data[1][0][0]],
          chats,
        }
      );
    } catch (err) {
      if (_.get(err, "response.data.code", "") === "free_plan_limit_exceeded") {
        localStorage.setItem(
          "limit",
          JSON.stringify({
            [`${new Date().getDate()}${new Date().getMonth()}${new Date().getYear()}`]: true,
          })
        );
        // history.push(Paths.upgradeAccount);
        setFreeTrialEndDialogOpen(true);
      }
    }

    setMsgSending(false);
  };

  const sendMessage = async (msg, type, ISYTLink = false) => {
    let isYTLink = ISYTLink;
    if (chatId) {
      setMsgSending(true);
      setMsgInput(msg);
      gotoEnd();
      setTimeout(gotoEnd, 500);
      try {
        const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
        if (pattern.test(_.trim(msg)) && _.trim(msg).split(" ").length === 1) {
          isYTLink = true;
        }
        const msgData = await addChatMessage(msg, type, chatId, isYTLink);
        setChatMessages([
          ...chatMessages,
          msgData.data[0][0][0],
          msgData.data[1][0][0],
        ]);
        if (type === 1) {
          setMsgInput("");
        }
      } catch (err) {
        if (
          _.get(err, "response.data.code", "") === "free_plan_limit_exceeded"
        ) {
          localStorage.setItem(
            "limit",
            JSON.stringify({
              [`${new Date().getDate()}${new Date().getMonth()}${new Date().getYear()}`]: true,
            })
          );
          // history.push(Paths.upgradeAccount);
          setFreeTrialEndDialogOpen(true);
        }
      }
      gotoEnd();
      setMsgSending(false);
    } else {
      CreateAndSendMessage(msg, type, isYTLink);
    }
  };

  const createNewChat = async (title) => {
    setChatListLoader(true);
    const newChatData = await createChat(title);
    setChats([_.get(newChatData, "data[0][0]", {}), ...chats]);
    history.push(
      Paths.chat.replace(":chatId", _.get(newChatData, "data[0][0].id", ""))
    );
    setChatListLoader(false);
  };

  const loaderUI = (
    <Box mx="auto" textAlign="center" mt={2}>
      <CircularProgress thickness={4.5} style={{ color: "#D967BE" }} />
    </Box>
  );
  const menu = (
    <>
      <div className="header">
        <span className="user-mail hide-mobile">
          <ArrowBackIcon
            className="hover-zoom"
            onClick={() => {
              trackButtonClick("Chat", "Back");
              handleClick(Paths.dashboard);
            }}
          />
          <span
            className="hover-zoom"
            onClick={() => {
              trackButtonClick("Chat", "Back");
              handleClick(Paths.dashboard);
            }}
          >
            {" "}
            Dashboard
          </span>
        </span>
        <MenuIcon
          style={{ color: "#454041" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          fontSize="large"
          className="pointer"
          onClick={handleOpenClick}
        />
      </div>
      <Menu
        id="top-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{
          marginTop: "45px",
          marginRight: "50px",
          borderRadius: "110px",
        }}
        disableAutoFocus
        onClose={handleClose}
      >
        <MenuItem
          style={{ width: "180px" }}
          onClick={() => {
            trackButtonClick(
              "Account",
              pathname === Paths.dashboard ? "Account" : "Dashboard"
            );
            handleClick(Paths.dashboard);
            handleClose();
          }}
        >
          {pathname === Paths.dashboard ? "Account" : "Dashboard"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            trackButtonClick(
              "Account",
              _.get(auth, "userData.subsciption[0].status", "") === "active"
                ? "Subscription"
                : "Pricing"
            );
            handleClick(Paths.upgradeAccount);
            handleClose();
          }}
        >
          {_.get(auth, "userData.subsciption[0].status", "") === "active"
            ? "Subscription"
            : "Pricing"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            trackButtonClick("Account", "Password");
            handleClick(Paths.resetPassword);
            handleClose();
          }}
        >
          Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            trackButtonClick("Account", "Contact");
            handleClick(Paths.contactUs);
            handleClose();
          }}
        >
          Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            trackButtonClick("Account", "Log out");
            changeAuth({ isLoggedIn: false, userData: {} });
            handleClick(Paths.login);
            handleClose();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
  const sendIcon = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3334 1.66667L13.6667 16.3333"
        stroke="#CF5FA6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.3334 1.66667L19.0001 28.3333L13.6667 16.3333L1.66675 11L28.3334 1.66667Z"
        stroke="#CF5FA6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const examplePromptIcon = (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.678213 7.75569C0.910496 7.99425 1.26206 7.98797 1.49434 7.75569L2.59925 6.65078C6.61083 2.64548 8.6072 0.661661 9.27266 1.32084C9.74978 1.79796 8.53187 3.19166 6.84939 5.16919C5.14808 7.17812 3.20821 9.33772 4.55168 10.6875C5.73192 11.8803 7.6153 10.3547 9.26011 8.76643C10.6036 7.46691 11.4385 6.68217 11.6834 6.92701C11.8968 7.14046 11.5202 7.60502 11.043 8.4337C10.4466 9.47583 9.66189 10.6875 10.4466 11.4785C11.0556 12.0812 12.0538 11.7484 13.2654 10.5117C13.5416 10.2355 13.5542 9.8839 13.3156 9.64534C13.0833 9.41933 12.7381 9.42561 12.4995 9.64534C11.7148 10.3799 11.3004 10.6812 11.1937 10.5807C11.0744 10.4677 11.3695 10.0157 11.9596 9.04894C12.8448 7.58619 13.1273 6.73239 12.5183 6.12971C11.7148 5.32614 10.4969 5.97277 8.4377 7.93147C6.61083 9.65789 5.72565 10.2166 5.36781 9.86506C4.94091 9.45072 5.6252 8.33326 7.72202 5.92254C10.2583 2.99076 11.1435 1.55312 10.0888 0.504713C8.77043 -0.807368 7.3579 0.259875 1.78312 5.83465L0.678213 6.93956C0.445931 7.17184 0.452209 7.52341 0.678213 7.75569Z"
        fill="#CF5FA6"
      />
    </svg>
  );

  return (
    <div className={`chat-wrapper ${activeView}`}>
      <div className="back-img">
        <img src={loginPopupBack} alt="loginPopupBack" />
      </div>
      <div className="panel-1">
        <div className="logo">
          <img src={loginModalImg} alt="logo" />
        </div>
        <div className="mobile-only mobile-menu">{menu}</div>
        {chatListLoader && loaderUI}
        {_.map(chats, (c) => (
          <ChatListItem
            chatId={chatId}
            id={c.id}
            chatTitle={c.title}
            key={c.id}
            onClick={(e) => {
              history.push(Paths.chat.replace(":chatId", c.id));
              setActiveView("chat-view");
            }}
          />
        ))}
        <div className="new-chat-btn-wrapper">
          <div
            className="new-chat-btn hover-zoom"
            onClick={() => {
              createNewChat(
                `Untitled ` + ("" + new Date().getTime()).slice(-5)
              );
            }}
          >
            New Chat +
          </div>
        </div>
      </div>
      <div className="panel-2">
        {/* <ChromeExtensions /> */}
        <div
          className="mobile-only back-to-list hover-zoom"
          onClick={() => {
            setActiveView("chat-list");
          }}
        >
          <ArrowBackIcon />
        </div>
        {menu}
        <div className="title">
          WritelyCHAT: Where all your amazing ideas begin.
        </div>
        <div
          className={
            "chat-box-area " +
            (chatMessages.length === 0 && !msgSending
              ? "empty-chat-list"
              : "with-msg-chat-list")
          }
        >
          <div className="white-patch-for-with-msg-chat-list"></div>
          <div
            className={
              "chat-list " +
              (chatMessages.length === 0 && !msgSending
                ? "empty-chat-list"
                : "with-msg-chat-list")
            }
          >
            {chatMessages.length === 0 && !msgSending && (
              <div className="example-prompts-wrapper">
                <div
                  className="prompt-item hover-zoom"
                  onClick={() =>
                    setMsgInput(
                      "What is the best way to structure an essay about AI?"
                    )
                  }
                >
                  <div className="prompt-icon">{sendIcon}</div>
                  <div className="text-1">
                    What is the best way to structure an essay about AI?
                  </div>
                  <div className="text-2">
                    {examplePromptIcon} prompt example
                  </div>
                </div>
                <div
                  className="prompt-item hover-zoom"
                  onClick={() =>
                    setMsgInput("Write a poem about stars in the night sky")
                  }
                >
                  <div className="prompt-icon">{sendIcon}</div>
                  <div className="text-1">
                    What a poem about stars in the night sky
                  </div>
                  <div className="text-2">
                    {examplePromptIcon} prompt example
                  </div>
                </div>
                <div
                  className="prompt-item hover-zoom"
                  onClick={() => {
                    if (YTLink) {
                      const pattern =
                        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
                      if (pattern.test(YTLink)) {
                        CreateAndSendMessage(YTLink, 1, true);
                      } else {
                        toast.error("Please paste valid YouTube link", {
                          position: "top-right",
                          autoClose: 1200,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    } else
                      toast.error("Please paste valid YouTube video link", {
                        position: "top-right",
                        autoClose: 1200,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                  }}
                >
                  <div className="going-viral">going viral!</div>
                  <div className="prompt-icon">{sendIcon}</div>
                  <div className="text-1">
                    Summarize a YouTube video in clear notes
                  </div>
                  <div className="input">
                    <input
                      value={YTLink}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onChange={(e) => {
                        if (YTLink.length === 0 && e.target.value.length > 3) {
                          const pattern =
                            /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
                          if (pattern.test(e.target.value)) {
                            CreateAndSendMessage(e.target.value, 1, true);
                          } else {
                            toast.error(
                              "Please paste valid YouTube video link",
                              {
                                position: "top-right",
                                autoClose: 1200,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }
                        }
                        setYTLink(e.target.value);
                      }}
                      className="prompt-input"
                      placeholder="Paste YouTube link here"
                    />
                  </div>
                </div>
                <div
                  className="prompt-item hover-zoom"
                  onClick={() =>
                    setMsgInput("Help me brainstorm a horror story")
                  }
                >
                  <div className="prompt-icon">{sendIcon}</div>
                  <div className="text-1">
                    Help me brainstorm a horror story
                  </div>
                  <div className="text-2">
                    {examplePromptIcon} prompt example
                  </div>
                </div>
                <div
                  className="prompt-item hover-zoom"
                  onClick={() =>
                    setMsgInput(
                      "Write an introduction to a blog post titled..."
                    )
                  }
                >
                  <div className="prompt-icon">{sendIcon}</div>
                  <div className="text-1">
                    Write an introduction to a blog post titled...
                  </div>
                  <div className="text-2">
                    {examplePromptIcon} prompt example
                  </div>
                </div>
                <div
                  className="prompt-item hover-zoom"
                  onClick={() =>
                    setMsgInput(
                      "Write a letter to my friend expressing my graditude"
                    )
                  }
                >
                  <div className="prompt-icon">{sendIcon}</div>
                  <div className="text-1">
                    Write a letter to my friend expressing my graditude
                  </div>
                  <div className="text-2">
                    {examplePromptIcon} prompt example
                  </div>
                </div>
              </div>
            )}
            {(chatMessages.length > 0 || msgSending) && (
              <>
                {_.map(chatMessages, (cm, index) => (
                  <div
                    className={`chat-item ` + (cm.type === 1 ? "self" : "bot")}
                  >
                    {cm.type === 1 && <div className="icon">You</div>}
                    <div className="msg">
                      <>
                        {index === chatMessages.length - 1 &&
                        cm.id !== lastChatId ? (
                          <Typist
                            startDelay={0}
                            avgTypingDelay={0.00001}
                            cursor={{
                              show: true,
                              blink: true,
                              element: "|",
                              hideWhenDone: false,
                            }}
                            onCharacterTyped={() => {
                              gotoEnd();
                              console.log("gotoEnd");
                            }}
                            // onTypingDone={() => setSt1Done(true)}
                          >
                            {_.trimStart(cm.message)}
                          </Typist>
                        ) : (
                          _.trimStart(cm.message)
                        )}
                      </>
                    </div>
                    {cm.type !== 1 && (
                      <div className="icon">
                        <img src={loginModalImg} alt="loginPopupBack" />
                      </div>
                    )}
                  </div>
                ))}
                {msgSending && (
                  <>
                    <div className="chat-item self">
                      <div className="icon">You</div>
                      <div className="msg">{_.trim(msgInput)}</div>
                    </div>
                    <div className="chat-item bot">
                      <div className="msg">
                        <TypingLoader />
                      </div>
                      <div className="icon">
                        <img src={loginModalImg} alt="loginPopupBack" />
                      </div>
                    </div>
                  </>
                )}
                {chatMessages.length > 1 && !msgSending && (
                  <>
                    <div className="chat-item bot">
                      <div className="regenerate-wrapper">
                        <div className="regenerae">
                          <img
                            src={regenerateBtn}
                            className="hover-zoom"
                            alt="regenerateBtn"
                            onClick={() => {
                              sendMessage(
                                chatMessages[chatMessages.length - 2].message,
                                1
                              );
                            }}
                          />
                        </div>
                        <div className="regenerae ">
                          <img
                            src={copyToClipboardBtn}
                            className="hover-zoom"
                            alt="copyToClipboardBtn"
                            onClick={() => {
                              copy(
                                chatMessages[chatMessages.length - 1].message
                              );
                              toast.success("Copied", {
                                position: "top-right",
                                autoClose: 1200,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {chatMsgListLoader && loaderUI}
            <div id="list-bottom"></div>
          </div>
          <div className="chat-input-wrapper">
            <div className="chat-input">
              <textarea
                value={msgSending ? "" : msgInput}
                onChange={(e) => {
                  if (!msgSending) setMsgInput(e.target.value);
                }}
                rows={1}
                placeholder="Ask me anything ..."
                onKeyDown={(e) => {
                  if (e.shiftKey && e.keyCode === 13) {
                    // setMsgInput(e.target.value + "\n");
                  } else if (e.altKey && e.keyCode === 13) {
                    setMsgInput(e.target.value + "\n");
                  } else if (e.keyCode === 13) {
                    if (chatId) {
                      if (msgInput.length > 0 && !msgSending)
                        sendMessage(msgInput, 1);
                    } else if (msgInput.length > 0 && !msgSending)
                      CreateAndSendMessage(msgInput, 1);
                  }
                }}
              ></textarea>
              <div
                className="btn-chat hover-zoom"
                onClick={() => {
                  if (chatId) {
                    if (msgInput.length > 0 && !msgSending)
                      sendMessage(msgInput, 1);
                  } else if (msgInput.length > 0 && !msgSending)
                    CreateAndSendMessage(msgInput, 1);
                }}
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#CF5FA6" />
                  <path
                    d="M24.3317 11L15.8984 19.4333"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.3332 11L18.9666 26.3332L15.9 19.4333L9 16.3666L24.3332 11Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreeTrialDialog
        dialogOpen={freeTrialEndDialogOpen}
        setDialogOpen={setFreeTrialEndDialogOpen}
      />
    </div>
  );
};

export default Chat;
