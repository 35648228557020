import CustomiseLengthMobile from "component/CustomiseLengthMobile/CustomiseLengthMobile";
import React from "react";
// import { helpIcon } from "assestes";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Drawer } from "@material-ui/core";
import { trackButtonClick } from "utils";
import CloseIcon from "@material-ui/icons/Close";

function MobileBottomDrawer(porps) {
  const {
    drawerOpen,
    setDrawerOpen,
    brief,
    setBrief,
    noteTypeId,
    noteLength,
    setNoteLength,
    handleTooltipiItem,
  } = porps;
  return (
    <>
      <div className="mobile-bottom">
        {/* <img
          src={helpIcon}
          alt="helpIcon"
          width="36px"
          className={open ? "help-icon icon-img-click" : "help-icon"}
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleHelpOpen}
        />
        <span className={drawerOpen ? "more-icon icon-click" : "more-icon"}>
          <MoreHorizIcon
            onClick={() => {
              trackButtonClick("Write Pad", "More");
              setDrawerOpen(!drawerOpen);
              setAnchorEl(null);
            }}
          />
        </span> */}
      </div>
      <Drawer
        variant="persistent"
        anchor="bottom"
        open={drawerOpen}
        BackdropProps={{
          open: false,
        }}
      >
        <div className="drawer-wrapper">
          <div className="close-icon">
            <CloseIcon
              style={{ color: "#db91c9" }}
              onClick={() => {
                trackButtonClick("Write Pad", "Close");
                setDrawerOpen(false);
              }}
            />
          </div>
          <h4>Description</h4>
          <textarea
            value={brief}
            onChange={(e) => setBrief(e.target.value)}
            className="brief mobile-brief"
            placeholder={
              noteTypeId === 1
                ? `Type here to tell the AI what email you would like it to generate for you. for example, 'Write me an email to ask for a promotion'`
                : `Type here to tell the AI a little bit more about your writing so it can tailor it's results to you. E.g "Give me a list."`
            }
            name="title"
            id="title"
          ></textarea>

          <div className="length">
            <CustomiseLengthMobile
              typeId={noteTypeId}
              noteLength={noteLength}
              setNoteLength={setNoteLength}
            />
          </div>
        </div>
      </Drawer>
      <div
        className="note-text-selection-tooltip"
        id="note-text-selection-tooltip"
      >
        <span
          className="note-tooltip-item"
          onClick={() => handleTooltipiItem("expand")}
        >
          expand
        </span>
        <span className="note-tooltip-divider"></span>
        <span
          className="note-tooltip-item"
          onClick={() => handleTooltipiItem("shorten")}
        >
          shorten
        </span>
        <span className="note-tooltip-divider"></span>
        <span
          className="note-tooltip-item bottom-arrow"
          onClick={() => handleTooltipiItem("rephrase")}
        >
          rephrase
        </span>
      </div>
    </>
  );
}

export default MobileBottomDrawer;
// export default React.memo(MobileBottomDrawer);
