import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText } from "@material-ui/core";
import _ from "lodash";
import "./style.css";
import Header from "component/Header";
import AuthContext from "contexts/authContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { loginService } from "services/auth";
import { Paths } from "utils/constant";
import { useLocation } from "react-router-dom";
import { trackButtonClick } from "utils";
import FooterNew from "component/Footer/index.new";
import HeaderNew from "component/Header/index.new";
import Footer from "component/Footer";

const Login = () => {
  const { changeAuth } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className="login">
        {window.innerWidth < 750 ? <Header onlyMobileView /> : <HeaderNew />}
        <div className="card-wrapper">
          <div className="card">
            <div className="title">Log in</div>
            <Formik
              initialValues={{
                email: _.get(location, "state.email", ""),
                password: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  const userData = await loginService({
                    Email: values.email,
                    Password: values.password,
                  });

                  if (userData) {
                    changeAuth({ isLoggedIn: true, userData: userData.data });
                    setStatus({ success: true });
                    setSubmitting(false);
                    history.push({
                      pathname: "/",
                      state: {
                        isRedirectFromRegister: false,
                        isRedirectFromLogin: true,
                      },
                    });
                    try {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        UserId: userData.data.userId,
                      });
                    } catch (err) {
                      console.log("error in data layer");
                    }
                  } else {
                    setStatus({ success: false });
                    setErrors({
                      submit:
                        "Email or password is invalid, please check and try again",
                    });
                  }
                } catch (err) {
                  console.error(err);
                  setStatus({ success: false });
                  if (
                    _.get(err, "response.data.code", "") ===
                    "otp_verification_required"
                  ) {
                    history.push(Paths.otpVerifying);
                  }
                  setErrors({
                    submit: _.get(
                      err,
                      "response.data.title",
                      "Something went wrong"
                    ),
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div>
                    <input
                      placeholder="Email Address"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                    {Boolean(touched.email && errors.email) && (
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    )}
                  </div>
                  <div className="mt-20">
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                    />
                    {Boolean(touched.password && errors.password) && (
                      <FormHelperText error>
                        {touched.password && errors.password}
                      </FormHelperText>
                    )}
                  </div>
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                  <Box mt={2}>
                    <button
                      className="btn-login hover-zoom"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Log in
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
            <div className="links">
              <div
                className="link"
                onClick={() => {
                  trackButtonClick("Login", "Forgot Password");
                  history.push(Paths.resetPassword);
                }}
              >
                Forgot password?
              </div>
              <div
                className="link"
                onClick={() => {
                  trackButtonClick("Login", "Not a member yet?");
                  history.push(Paths.signUp);
                }}
              >
                Not a member yet?
              </div>
            </div>
          </div>
        </div>
        {window.innerWidth < 550 ? <Footer /> : <FooterNew />}
      </div>
    </>
  );
};

export default Login;
