import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import "./style.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailNoteDialog = (props) => {
  const { handleClose, open } = props;
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: { zIndex: "5000 !important", borderRadius: "8px" },
        }}
        maxWidth="xs"
        BackdropProps={{ style: { zIndex: "5000 !important" } }}
      >
        <DialogContent
          style={{ zIndex: "5000 !important", background: "#f4f4f4" }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <div
              className="email-note-dialog close"
              onClick={handleClose}
            ></div>
            <div className="email-note-dialog">
              Please write a prompt in the description box about the content of
              your desired email{" "}
              <div className="okay-btn hover-zoom" onClick={handleClose}>
                Okay
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailNoteDialog;
