import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import CustomiseLengthMobile from "component/CustomiseLengthMobile/CustomiseLengthMobile";
import Loading from "component/Loading/Loading";
import CreateNewPlus from "component/CreateNewPlus/CreateNewPlus";
import UndoIntroDialog from "component/Dialog/undoIntroDialog/UndoIntroDialog";
import DeleteDialog from "component/Dialog/DeleteDialog/DeleteDialog";
import ReviewWriteDialog from "component/Dialog/ReviewWriteDialog/ReviewWriteDialog";

import {
  addLineBreaks,
  changeSingleToDoubleEnter,
  countWords,
  getFirstNWords,
  scrollTextarea,
  trackButtonClick,
} from "utils";
import { Paths, wordsLimitForAction } from "utils/constant";
import AuthContext from "contexts/authContext";
import {
  EditNote,
  NoteSelectAction,
  WriteNote,
  editNoteItem,
  getHumanizeNote,
  requestHumanizeNote,
} from "services/note";

import {
  deleteIcon,
  logo,
  expandIcon,
  shortenIcon,
  rephraseIcon,
  expandIconPink,
  shortenIconPink,
  rephraseIconPink,
  backwardArrow,
  humanizeIcon,
} from "assestes";

import autosize from "autosize";
import { useToasts } from "react-toast-notifications";
import ToggleButton from "react-toggle-button";

import "./style.css";
import { editWorkspaceNoteItem } from "services/workspace";
// import { APIURI } from "services/endPoints";
import { Export2Word } from "component/Export";
import EmailNoteDialog from "component/Dialog/EmailNoteDialog/EmailNoteDialog";
import FreeTrialDialog from "component/Dialog/FreeTrialDialog/FreeTrialDialog";
import HelpUI from "./HelpUI";
import MobileBottomDrawer from "./MobileBottomDrawer";
import StateUI from "./StateUI";
import CopyUndoUI from "./CopyUndoUI";
import PDFDownload from "./PDFDownload";
import NoTextDetectedDialog from "component/Dialog/NoTextDetectedDialog/NoTextDetectedDialog";
import HumanizeInfoDialog from "component/Dialog/HumanizeInfoDialog/HumanizeInfoDialog";

// const { Sapling } = require("@saplingai/sapling-js/sapling/observer");

const debounceEditNote = _.debounce(async (data, cb) => {
  let res = await EditNote(data);
  cb(res);
}, 400);

const debounceEditTeamNoteItem = _.debounce(
  async (
    workspaceId,
    noteId,
    itemId,
    title,
    description,
    briefOn,
    brief,
    noteLength,
    cb
  ) => {
    let res = await editWorkspaceNoteItem(
      workspaceId,
      noteId,
      itemId,
      title,
      description,
      briefOn,
      brief,
      noteLength
    );
    cb(res);
  },
  400
);

const debounceEditNoteItem = _.debounce(
  async (
    noteId,
    itemId,
    title,
    description,
    briefOn,
    brief,
    noteLength,
    cb
  ) => {
    let res = await editNoteItem(
      noteId,
      itemId,
      title,
      description,
      briefOn,
      brief,
      noteLength
    );
    cb(res);
  },
  400
);

let writeClicked = false;
let pageDownX = 0;
let pageDownY = 0;
let pageUpX = 0;
let pageUpY = 0;
let selectedNoteStart = 0;

const WritingPad = (props) => {
  const {
    activeNoteId,
    editCallBack,
    typeId,
    deleteCallback,
    setWritePad,
    writePad,
    closeSideMenu,
    setActiveNote,
    setActiveTab,
    onNotePointerUpEvent,
    isRedirectFromRegister,
    isTeamMode = false,
    teamData = { workspaceId: 0, noteId: 0, noteColor: "" },
  } = props;
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [selectedNoteText, setSelectedNoteText] = useState("");
  const [oldNote, setOldNote] = useState([]);
  const [brief, setBrief] = useState("");
  const [words, setWords] = useState(0);
  const [chars, setChars] = useState(0);
  const [isSaved, setIsSaved] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [writeLoading, setWriteLoading] = useState(false);
  const [humanizeLoading, setHumanizeLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState("");
  const [, setWriteCalled] = useState(false);
  const [noteLength, setNoteLength] = useState(1);
  const [freeTrialEndDialogOpen, setFreeTrialEndDialogOpen] = useState(false);
  const [reviewWriteDialogOpen, setReviewWriteDialogOpen] = useState(false);
  const [undoIntroDialogOpen, setUndoIntroDialogOpen] = useState(false);
  const [continueWriteCount, setContinueWriteCount] = useState(0);
  const [briefOnState, setBriefOn] = useState(false);
  const [created, setCreated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailNoteDialogOpen, setEmailNoteDialogOpen] = useState(false);
  const [noTextDialogOpen, setNoTextDialogOpen] = useState(false);
  const [humanizeInfoDialogOpen, setHumanizeInfoDialogOpen] = useState(false);
  const briefOn =
    _.get(props, "activeNote.noteType", typeId) === 1 ? true : briefOnState;

  const history = useHistory();
  const { addToast } = useToasts();
  const { auth, changeAuth } = useContext(AuthContext);

  const open = Boolean(anchorEl);

  useEffect(() => {
    onNotePointerUp(onNotePointerUpEvent);
  }, [onNotePointerUpEvent]);

  useEffect(() => {
    setChars(note.length);
    setWords(countWords(note));
    if (
      (_.get(props, "activeNote.title", "") !== title ||
        _.get(props, "activeNote.description", "") !== note ||
        _.get(props, "activeNote.brief", "") !== brief ||
        (_.get(props, "activeNote.id", -1) !== -1 &&
          (_.get(props, "activeNote.briefEnable", false) !== briefOn ||
            _.get(props, "activeNote.noteLength", 1) !== noteLength))) &&
      !writePad
    )
      if (created === false || _.get(props, "activeNote.id", -1) !== -1) {
        setCreated(true);
        triggerCall();
        autosize.update(document.querySelectorAll("textarea"));
      }
  }, [title, note, briefOn, noteLength, brief]);

  useEffect(() => {
    if (activeNoteId === -1) setCreated(false);
    writeClicked = false;
    return () => {
      debounceEditNote.flush();
      debounceEditTeamNoteItem.flush();
    };
  }, [activeNoteId]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      if (document.getElementsByTagName("main")[0])
        document.getElementsByTagName("main")[0].style.height =
          window.innerHeight + "px";
      window.addEventListener("resize", () => {
        if (document.getElementsByTagName("main")[0])
          document.getElementsByTagName("main")[0].style.height =
            window.innerHeight + "px";
      });
    }
    window.addEventListener("mouseup", () => {
      let selection = window.getSelection().toString();
      if (selection.length <= 0) {
        const note = document.getElementById("note-text-selection-tooltip");
        if (note) {
          note.style.display = "none";
        }
      }
    });
  }, []);

  useEffect(() => {
    if (writePad) {
      setWritePad(false);
      setContinueWriteCount(0);
      setTitle(_.get(props, "activeNote.title", ""));
      setNote(
        changeSingleToDoubleEnter(_.get(props, "activeNote.description", ""))
      );
      setBriefOn(
        _.get(
          props,
          "activeNote.briefEnable",
          isRedirectFromRegister ? false : true
        )
      );
      setBrief(_.get(props, "activeNote.brief", ""));
      setNoteLength(_.get(props, "activeNote.noteLength", 1));
      setWriteCalled(false);
    }
  }, [writePad]);

  useLayoutEffect(() => {
    autosize.update(document.querySelectorAll("textarea"));
  });

  const triggerCall = () => {
    setIsSaved(false);
    if (!isTeamMode)
      debounceEditNote(
        {
          NoteId: _.get(props, "activeNote.id", -1),
          Title: title,
          Description: note,
          NoteType: _.get(props, "activeNote.noteType", typeId),
          BriefEnable: briefOn,
          Brief: brief,
          NoteLength: noteLength,
        },
        (res) => {
          setIsSaved(true);
          editCallBack(res.data);
          if (selectedNoteText.length >= 1) {
            let input = document.getElementById("note");
            let fisrtIndex = note.lastIndexOf(selectedNoteText);
            let lastIndex =
              note.lastIndexOf(selectedNoteText) + selectedNoteText.length;
            if (fisrtIndex > -1) {
              input.select();
              input.setSelectionRange(fisrtIndex, lastIndex);
            }
            setSelectedNoteText("");
          }
        }
      );
    else if (teamData && teamData.isSingalOwner) {
      debounceEditNoteItem(
        teamData.noteId,
        _.get(props, "activeNote.id", -1),
        title,
        note,
        briefOn,
        brief,
        noteLength,
        (res) => {
          setIsSaved(true);
          editCallBack(res.data);
          if (selectedNoteText.length >= 1) {
            let input = document.getElementById("note");
            let fisrtIndex = note.lastIndexOf(selectedNoteText);
            let lastIndex =
              note.lastIndexOf(selectedNoteText) + selectedNoteText.length;
            input.select();
            input.setSelectionRange(fisrtIndex, lastIndex);
            setSelectedNoteText("");
          }
        }
      );
    } else
      debounceEditTeamNoteItem(
        teamData.workspaceId,
        teamData.noteId,
        _.get(props, "activeNote.id", -1),
        title,
        note,
        briefOn,
        brief,
        noteLength,
        (res) => {
          setIsSaved(true);
          editCallBack(res.data);
          if (selectedNoteText.length >= 1) {
            let input = document.getElementById("note");
            let fisrtIndex = note.lastIndexOf(selectedNoteText);
            let lastIndex =
              note.lastIndexOf(selectedNoteText) + selectedNoteText.length;
            input.select();
            input.setSelectionRange(fisrtIndex, lastIndex);
            setSelectedNoteText("");
          }
        }
      );
  };

  const checkContinueWrite = () => {
    if (continueWriteCount >= 7) {
      setReviewWriteDialogOpen(true);
      return false;
    }
    return true;
  };

  const handleHumanize = async () => {
    setHumanizeLoading(true);
    try {
      let limit = localStorage.getItem("limit");
      if (limit) {
        try {
          limit = JSON.parse(limit);
          if (
            limit[
              `${new Date().getDate()}${new Date().getMonth()}${new Date().getYear()}`
            ] &&
            _.get(auth, "userData.subsciption[0].status", "") !== "active" &&
            _.get(auth, "userData.subsciption[0].status", "") !== "trialing"
          ) {
            // history.push(Paths.upgradeAccount);
            setFreeTrialEndDialogOpen(true);
            return;
          }
        } catch (err) {
          console.error(err);
        }
      }
      trackButtonClick("Write Pad", "Humanize");
      closeSideMenu();
      if (note.length > 0 && note.split(" ").length > 50) {
        const result = await requestHumanizeNote(note);

        if (result && result.id) {
          const resNote = await fetchHumanizeNote(result.id);
          if (resNote) {
            setOldNote([...oldNote, note]);
            setNote(changeSingleToDoubleEnter(resNote));
          }
        }
        if (result && result.data) {
          setOldNote([...oldNote, note]);
          setNote(changeSingleToDoubleEnter(result.data));
          setHumanizeInfoDialogOpen(true);
        }
      } else {
        setNoTextDialogOpen(true);
      }
    } catch (err) {
    } finally {
      setHumanizeLoading(false);
    }
  };

  const fetchHumanizeNote = async (reqId, waitTime = 5000) => {
    const humanNote = await getHumanizeNote(reqId);
    if (humanNote) {
      return humanNote;
    }
    await new Promise((res) => setTimeout(res, waitTime));
    return fetchHumanizeNote(
      reqId,
      waitTime + 1000 < 10001 ? waitTime + 1000 : 10000
    );
  };

  const handleWrite = async (storySentences = 5) => {
    if (
      !brief &&
      !title &&
      !note &&
      _.get(props, "activeNote.noteType", typeId) === 1 // email
    ) {
      setEmailNoteDialogOpen(true);
      setBriefOn(true);
      closeSideMenu();
      return;
    }
    let limit = localStorage.getItem("limit");
    if (limit) {
      try {
        limit = JSON.parse(limit);
        if (
          limit[
            `${new Date().getDate()}${new Date().getMonth()}${new Date().getYear()}`
          ] &&
          _.get(auth, "userData.subsciption[0].status", "") !== "active" &&
          _.get(auth, "userData.subsciption[0].status", "") !== "trialing"
        ) {
          // history.push(Paths.upgradeAccount);
          setFreeTrialEndDialogOpen(true);
          return;
        }
      } catch (err) {
        console.error(err);
      }
    }
    trackButtonClick("Write Pad", "Write");
    closeSideMenu();
    let resContinueWrite = checkContinueWrite();
    if (!writeLoading && !humanizeLoading && resContinueWrite) {
      setWriteLoading(true);
      const selectionStart = document.getElementById("note").selectionEnd;
      setContinueWriteCount(continueWriteCount + 1);
      let newDescription = note.substring(0, selectionStart);
      let newDescriptionForReplace = note.substring(0, selectionStart);
      if (
        _.get(props, "activeNote.noteType", typeId) === 2 &&
        note === newDescription
      ) {
        newDescription = newDescription.trim().replaceAll("!", "!.");
        let hasLastDot = false;
        if (newDescription[newDescription.length - 1] === ".") {
          newDescription = newDescription.slice(0, -1);
          hasLastDot = true;
        }
        const splitArr = newDescription.split(".");
        let sicount = 0;
        if (splitArr.length > storySentences) {
          newDescription = "";
          let index = 0;
          while (sicount < storySentences && index < splitArr.length) {
            newDescription =
              splitArr[splitArr.length - (index + 1)] + "." + newDescription;
            if (splitArr[splitArr.length - (index + 1)].length > 2) {
              sicount++;
            }
            index++;
          }
          if (!hasLastDot) newDescription = newDescription.slice(0, -1);
        } else {
          if (hasLastDot) newDescription = newDescription + ".";
        }
        newDescription = newDescription.replaceAll("!.", "!");
      }

      let res = await WriteNote({
        Title: isTeamMode ? teamData.mainTitle : title,
        SubHeading: isTeamMode ? title : "",
        Description: newDescription,
        Brief: brief,
        NoteType: _.get(props, "activeNote.noteType", typeId),
        isTeamMode,
      }).catch((err) => {
        if (
          _.get(err, "response.data.code", "") === "free_plan_limit_exceeded"
        ) {
          localStorage.setItem(
            "limit",
            JSON.stringify({
              [`${new Date().getDate()}${new Date().getMonth()}${new Date().getYear()}`]: true,
            })
          );
          // history.push(Paths.upgradeAccount);
          setFreeTrialEndDialogOpen(true);
        } else if (
          _.get(err, "response.data.code", "") === "too_many_requests_in_min"
        ) {
          changeAuth({ isLoggedIn: false, userData: {} });
          history.push(Paths.otpVerifying);
          setReviewWriteDialogOpen(true);
        }
      });
      if (!_.get(res, "data.IsSafe", true)) {
        addToast(`Output may be inappropriate. Please try again.`, {
          autoDismissTimeout: 7000,
          appearance: "warning",
          autoDismiss: true,
          PlacementType: "top-right",
        });
      } else if (_.get(res, "data.Description")) {
        if (_.get(res, "data.Description").length === 0 && storySentences > 3) {
          return handleWrite(storySentences - 1);
        }
        setWriteCalled(true);
        setOldNote([...oldNote, note]);
        writeClicked = true;
        const newDescriptionWithFilter = _.get(res, "data.Description", "")
          .replaceAll(_.trim(newDescription), "###OldInput###")
          .replaceAll("###OldInput###", _.trim(newDescription));
        const newRes = newDescriptionForReplace.replace(
          _.trim(newDescription),
          newDescriptionWithFilter
        );
        let selectionEnd = newRes.length;
        setWriteLoading(false);
        if (note.substring(selectionStart, note.length) === "") {
          const newData = changeSingleToDoubleEnter(
            _.get(props, "activeNote.noteType", typeId) === 1
              ? addLineBreaks(newRes.replace(note, ""))
              : newRes.replace(note, "")
          );
          selectionEnd = note.length + newData.length;
          for (let i = 0; i <= Math.ceil(newData.length / 4); i++) {
            setNote(note + newData.substring(0, i * 4));
            await new Promise((resolve) => setTimeout(resolve, 0.5));
          }
        } else {
          const newData = changeSingleToDoubleEnter(
            _.get(props, "activeNote.noteType", typeId) === 1
              ? addLineBreaks(
                  newRes.replace(note.substring(0, selectionStart), "")
                )
              : newRes.replace(note.substring(0, selectionStart), "")
          );
          selectionEnd =
            note.substring(0, selectionStart).length + newData.length;
          for (let i = 0; i <= newData.length / 4; i++) {
            setNote(
              note.substring(0, selectionStart) +
                newData.substring(0, i * 4) +
                note.substring(selectionStart, note.length)
            );
            await new Promise((resolve) => setTimeout(resolve, 0.5));
          }
        }
        let writingPad = document.getElementById("writing-pad");
        writingPad.scrollTop = writingPad.scrollHeight;
        let noteDiv = document.getElementById("note");
        noteDiv.scrollTop = noteDiv.scrollHeight;
        scrollTextarea(noteDiv, selectionStart);
        document.getElementById("note").focus();
        document.getElementById("note").selectionEnd = selectionEnd;
        document.getElementById("note").selectionStart = selectionEnd;

        if (isRedirectFromRegister && !localStorage.getItem("undoIntroDone")) {
          setUndoIntroDialogOpen(true);
          localStorage.setItem("undoIntroDone", true);
        }
      }
      setWriteLoading(false);
    }
  };

  const handleTooltipiItem = async (action) => {
    trackButtonClick("Write Pad", action);
    closeSideMenu();
    setActionLoading(action);
    let replacementText = "";
    const limit = wordsLimitForAction[action];
    let resContinueWrite = checkContinueWrite();
    if (resContinueWrite) {
      if (countWords(selectedNoteText) > limit) {
        addToast(
          `Your selected text is too long. ${action} function has a limit of ${limit} words, see example below`,
          {
            autoDismissTimeout: 7000,
            appearance: "warning",
            autoDismiss: true,
            PlacementType: "top-right",
          }
        );
        replacementText = getFirstNWords(selectedNoteText, limit);
        setSelectedNoteText(replacementText);
        setNote(note + " ");
      } else {
        setContinueWriteCount(continueWriteCount + 1);
        let res = await NoteSelectAction(
          action,
          selectedNoteText,
          _.get(props, "activeNote.noteType", typeId)
        ).catch((err) => {
          if (
            _.get(err, "response.data.code", "") === "free_plan_limit_exceeded"
          ) {
            // history.push(Paths.upgradeAccount);
            setFreeTrialEndDialogOpen(true);
          }
        });
        if (selectedNoteText.length > 0) {
          if (_.get(res, "data.Text")) {
            replacementText = _.get(res, "data.Text", selectedNoteText);
          }
          let newNoteText = changeSingleToDoubleEnter(
            note.substr(0, selectedNoteStart) +
              note
                .substr(selectedNoteStart, note.length)
                .replace(selectedNoteText, replacementText)
          );

          setSelectedNoteText(replacementText);
          setOldNote([...oldNote, note]);
          setNote(newNoteText);
        }
      }
    }
    setActionLoading("");
  };

  const onNoteTextSelect = (event) => {
    console.count("onNoteTextSelect");
    let text = event.target.value;
    let indexStart = event.target.selectionStart;
    let indexEnd = event.target.selectionEnd;
    let selection = text.substring(indexStart, indexEnd);
    selectedNoteStart = indexStart;
    setSelectedNoteText(selection);
    const noteTP = document.getElementById("note-text-selection-tooltip");

    if (selection.length === note.length) {
      const noteY = _.get(
        document.getElementById("note").getBoundingClientRect(),
        "y",
        0
      );
      pageUpY = noteY;
    }
    if (selection.length > 0) {
      let pageY = pageDownY;
      let pageX = pageDownX;

      if (pageDownY > pageUpY) {
        pageY = pageUpY;
        pageX = pageUpX;
      }

      const textAreaPostion = event.target.getBoundingClientRect();
      let diffY = pageUpY - pageDownY;
      if (diffY < 0) {
        diffY = diffY * -1;
      }

      const finalY = pageY - textAreaPostion.y;
      if (diffY < 18) {
        pageX = (pageUpX + pageDownX) / 2;
      } else {
        pageX = (pageX + textAreaPostion.x + textAreaPostion.width) / 2;
      }
      const tempX = pageX <= 130 ? pageX : pageX - 149;

      if (textAreaPostion.x) {
      }
      const finalX = tempX < textAreaPostion.x ? textAreaPostion.x : tempX;

      noteTP.style.top =
        parseInt(finalY / 18) * 18 + textAreaPostion.y - 36 + "px";
      noteTP.style.left = finalX + "px";
      noteTP.style.position = "absolute";
      noteTP.style.display = "flex";
    } else {
      noteTP.style.display = "none";
    }
  };

  const onNotePointerDown = (e) => {
    closeSideMenu();
    pageDownX = e.pageX; // <= 130 ? e.pageX : e.pageX - 149;
    pageDownY = e.pageY; //  - 50;
  };

  const onNotePointerUp = (e) => {
    const noteY = _.get(
      document.getElementById("note").getBoundingClientRect(),
      "y",
      0
    );
    pageUpX = e?.pageX || 0; // <= 130 ? e.pageX : e.pageX - 149;
    pageUpY = noteY > (e?.pageY || 0) ? noteY : e?.pageY || 0; // - 50;
  };

  const handleUndo = async () => {
    closeSideMenu();
    trackButtonClick("Write Pad", "Undo");
    if (oldNote.length > 0) {
      setNote(_.cloneDeep(oldNote).pop());
      setOldNote(_.cloneDeep(oldNote).slice(0, oldNote.length - 1));
    } else {
      setNote("");
    }
  };

  const handleHelpOpen = (event) => {
    trackButtonClick("Write Pad", "Help Open");
    closeSideMenu();
    setAnchorEl(event.currentTarget);
    setDrawerOpen(false);
  };

  const welcomeMobileTitleUI = (
    <span className="mobile-title mobile-only">
      Welcome {isRedirectFromRegister ? "aboard" : "back"},{" "}
      {_.get(auth, "userData.FirstName", "")}!
    </span>
  );

  const expandShortenRephraseTopBtnUI = (
    <>
      <div
        className={`btn-action btn-desk hover-zoom ${
          actionLoading === "expand" ? "action-btn loading" : ""
        }`}
        title="expand"
        onClick={() => handleTooltipiItem("expand")}
      >
        <img src={expandIcon} alt="expandIcon" />
        <img src={expandIconPink} alt="expandIconPink" className="hover-icon" />
        <span className={`btn-text-hidden-mobile`}>&nbsp;expand</span>
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
      <div
        className={`btn-action btn-desk hover-zoom ${
          actionLoading === "shorten" ? "action-btn loading" : ""
        }`}
        title="shorten"
        onClick={() => handleTooltipiItem("shorten")}
      >
        <img src={shortenIcon} alt="shortenIcon" />
        <img
          src={shortenIconPink}
          alt="shortenIconPink"
          className="hover-icon"
        />
        <span className="btn-text-hidden-mobile">&nbsp;shorten</span>
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
      <div
        className={`btn-action btn-desk hover-zoom ${
          actionLoading === "rephrase" ? "action-btn loading" : ""
        }`}
        title="rephrase"
        onClick={() => handleTooltipiItem("rephrase")}
      >
        <img src={rephraseIcon} alt="rephraseIcon" />
        <img src={rephraseIconPink} alt="rephraseIcon" className="hover-icon" />
        <span className="btn-text-hidden-mobile">&nbsp;rephrase</span>
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </>
  );

  const writeDeskBtn = (
    <>
      <div
        className={`btn-write-desk hover-zoom hide-mobile ${
          writeLoading || humanizeLoading ? " loading" : ""
        }`}
        onClick={() => handleWrite()}
      >
        {writeLoading ? <span>Writing...</span> : <span>Write</span>}
      </div>
    </>
  );

  const humanizeBtn = (
    <>
      <div
        className={`btn-humanize-desk hover-zoom hide-mobile ${
          writeLoading || humanizeLoading ? " loading" : ""
        }`}
        onClick={() => handleHumanize()}
      >
        <img src={humanizeIcon} alt="humanizeIcon" />
        {humanizeLoading ? <span>Humanizing...</span> : <span>Humanize</span>}
      </div>
    </>
  );

  const humanizeMobileBtn = (
    <>
      <div
        className={`btn-humanize-desk btn-humanize hover-zoom mobile-only ${
          writeLoading || humanizeLoading ? " loading" : ""
        }`}
        onClick={() => handleHumanize()}
      >
        <img src={humanizeIcon} alt="humanizeIcon" />
        {humanizeLoading ? <span>Humanizing...</span> : <span>Humanize</span>}
      </div>
    </>
  );

  const writeMobileBtn = (
    <div
      className={`btn-write hover-zoom mobile-only writeLoading ${
        writeLoading || humanizeLoading ? " loading" : ""
      }`}
      onClick={() => handleWrite()}
    >
      {writeLoading ? "Writing..." : "Write"}
    </div>
  );

  return (
    <>
      <>
        <div style={{ display: "none" }}>
          <PDFDownload title={title} note={note} />
          {/* <PDFDownloadLink
            fileName={title.slice(0, 16) || "writelyai"}
            key={`${(title || "").length}-${(note || "").length}`}
            document={<ExportPDF title={title} note={note} />}
          >
            {() => {
              return (
                <>
                  <div id="PDFDownloadLink">writelyai.pdf</div>
                </>
              );
            }}
          </PDFDownloadLink> */}
          <button
            id="Export2Word"
            onClick={() =>
              Export2Word(title, note, title.slice(0, 16) || "writelyai")
            }
          >
            export doc
          </button>
        </div>
      </>
      {/* <ChromeExtensions /> */}
      <div
        className={"writing-pad-wrapper" + (briefOn ? " brief-enable" : "")}
        onPointerUp={(e) => {
          onNotePointerUp(e);
        }}
      >
        {welcomeMobileTitleUI}
        <StateUI
          noteTypeId={_.get(props, "activeNote.noteType", typeId)}
          words={words}
          chars={chars}
          isSaved={isSaved}
        />
        {isTeamMode && (
          <div className="team-note-view-full-article">
            <span onClick={() => setActiveNote(-1)}>
              <img src={backwardArrow} alt="backwardArrow" /> &nbsp; view full
              article
            </span>
          </div>
        )}
        <div className="btn-header">
          <CopyUndoUI handleUndo={handleUndo} />
          <div className="pink-line"></div>
          {expandShortenRephraseTopBtnUI}
          <HelpUI
            closeSideMenu={closeSideMenu}
            setDrawerOpen={setDrawerOpen}
            setAnchorEl={setAnchorEl}
            open={open}
            anchorEl={anchorEl}
          />
          <div className="pink-line"></div>
          {/* <ExportUI
            exportAnchorEl={exportAnchorEl}
            setExportAnchorEl={setExportAnchorEl}
            open={open}
            title={title}
            note={note}
          /> */}
          {writeDeskBtn}
          {humanizeBtn}
          {!isTeamMode && (
            <div className="btn-delete hover-zoom ">
              <DeleteDialog
                type="note"
                isTeamMode={false}
                noteId={activeNoteId}
                deleteCallback={() => {
                  if (activeNoteId !== -1) {
                    deleteCallback(
                      _.get(props, "activeNote.id", -1),
                      _.get(props, "activeNote.noteType", typeId)
                    );
                  }
                }}
              >
                <img src={deleteIcon} alt="copyIcon" />
                <span className="btn-text-hidden-mobile">&nbsp;delete</span>
              </DeleteDialog>
            </div>
          )}

          <div className="brief">
            {_.get(props, "activeNote.noteType", typeId) !== 1 && (
              <>
                <span className="text" style={{ fontWeight: 300 }}>
                  {"Advanced"}
                </span>
                <ToggleButton
                  value={briefOn || false}
                  activeLabel="On"
                  inactiveLabel="Off"
                  colors={{
                    active: {
                      base: "#7ccf77",
                    },
                    inactive: {
                      base: "#cbcbcb",
                    },
                  }}
                  onToggle={(value) => {
                    closeSideMenu();
                    setBriefOn(
                      _.get(props, "activeNote.noteType", typeId) === 1
                        ? true
                        : !briefOn
                    );
                  }}
                />
              </>
            )}
            {!isTeamMode && (
              <DeleteDialog
                type="note"
                isTeamMode={false}
                noteId={activeNoteId}
                deleteCallback={() => {
                  if (activeNoteId !== -1)
                    deleteCallback(
                      _.get(props, "activeNote.id", -1),
                      _.get(props, "activeNote.noteType", typeId)
                    );
                }}
              >
                <img
                  src={deleteIcon}
                  width={"28px"}
                  height="28px"
                  alt="toggleOn"
                  className="hover-zoom1"
                  onClick={closeSideMenu}
                />
              </DeleteDialog>
            )}
          </div>
        </div>
        {briefOn && (
          <div className="length-customizer-wrapper">
            <h4>Description</h4>
            <textarea
              value={brief}
              onChange={(e) => setBrief(e.target.value)}
              className="brief brief-desk"
              placeholder={
                _.get(props, "activeNote.noteType", typeId) === 1
                  ? `Type here to tell the AI what email you would like it to generate for you. for example, 'Write me an email to ask for a promotion'`
                  : `Type here to tell the AI a little bit more about your writing so it can tailor it's results to you. E.g "Give me a list."`
              }
              name="title"
              id="title"
              rows={11}
            ></textarea>
            <div className="length-customizer">
              <CustomiseLengthMobile
                typeId={_.get(props, "activeNote.noteType", typeId)}
                noteLength={noteLength}
                setNoteLength={setNoteLength}
              />
            </div>
            <div className="logo-space">
              <img src={logo} alt="logo" width="76px" />
              <span>© Writely AI 2024</span>
            </div>
          </div>
        )}
        <div
          className={"writing-pad "}
          id="writing-pad"
          onScroll={(e) => {
            const note = document.getElementById("note-text-selection-tooltip");
            if (note) {
              note.style.display = "none";
            }
          }}
        >
          <textarea
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onPointerDown={() => closeSideMenu()}
            className="title"
            placeholder="Insert title here.."
            name="title"
            id="title"
            disabled={isTeamMode}
          ></textarea>
          <div className={isTeamMode ? "d-flex" : ""}>
            <textarea
              value={note}
              onSelect={onNoteTextSelect}
              onPointerDown={onNotePointerDown}
              onPointerUp={onNotePointerUp}
              onChange={(e) => {
                if (
                  !writeLoading &&
                  !humanizeLoading &&
                  actionLoading.length === 0
                ) {
                  if (writeClicked) {
                    setOldNote([
                      ...oldNote,
                      e.target.value.substring(0, e.target.value.length - 1),
                    ]);
                    writeClicked = false;
                  }
                  if (note.length > e.target.value.length) {
                    setNote(e.target.value);
                  } else {
                    setNote(changeSingleToDoubleEnter(e.target.value));
                  }
                  if (continueWriteCount > 0) {
                    setContinueWriteCount(0);
                  }
                }
              }}
              onKeyUp={(e) => {
                if (e.ctrlKey && (e.key === "a" || e.key === "A")) {
                  onNotePointerUp({ pageUpX: 0, pageUpY: 0 });
                }
              }}
              className={
                "note" +
                (briefOn ? " brief-enable" : "") +
                (writeLoading || humanizeLoading || actionLoading.length > 0
                  ? " loading"
                  : "")
              }
              placeholder={
                _.get(props, "activeNote.noteType", typeId) === 1
                  ? `Write a description of your email on the left, or put your own prompt here followed by clicking 'Write'`
                  : `Type a sentence and click 'Write' to complete`
              }
              name="note"
              id="note"
              rows={15}
            ></textarea>
            {isTeamMode && (
              <>
                <div
                  className="devider"
                  style={{ background: teamData.noteColor }}
                ></div>
                {!teamData.isSingalOwner && <div className="m-a">me</div>}
              </>
            )}
          </div>
          {writeMobileBtn}
          {humanizeMobileBtn}
        </div>
        <MobileBottomDrawer
          open={open}
          handleHelpOpen={handleHelpOpen}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          setAnchorEl={setAnchorEl}
          brief={brief}
          setBrief={setBrief}
          noteTypeId={_.get(props, "activeNote.noteType", typeId)}
          noteLength={noteLength}
          setNoteLength={setNoteLength}
          handleTooltipiItem={handleTooltipiItem}
        />
        {(writeLoading || humanizeLoading || actionLoading.length > 0) && (
          <Loading />
        )}
        {!(writeLoading || humanizeLoading || actionLoading.length > 0) && (
          <CreateNewPlus
            setWritePad={setWritePad}
            setActiveNote={setActiveNote}
            setActiveTab={setActiveTab}
          />
        )}
        <NoTextDetectedDialog
          dialogOpen={noTextDialogOpen}
          noData={note.length === 0}
          setDialogOpen={setNoTextDialogOpen}
        />
        <FreeTrialDialog
          dialogOpen={freeTrialEndDialogOpen}
          setDialogOpen={setFreeTrialEndDialogOpen}
        />
        {/* <FreeTrialEndDialog
          freeTrialEndDialogOpen={freeTrialEndDialogOpen}
          setFreeTrialEndDialogOpen={setFreeTrialEndDialogOpen}
        /> */}
        <ReviewWriteDialog
          freeTrialEndDialogOpen={reviewWriteDialogOpen}
          setFreeTrialEndDialogOpen={setReviewWriteDialogOpen}
          confirmClick={() => {
            setContinueWriteCount(0);
            setReviewWriteDialogOpen(false);
          }}
        />
        <UndoIntroDialog
          isOpen={undoIntroDialogOpen}
          setIsOpen={setUndoIntroDialogOpen}
        />
        <EmailNoteDialog
          open={emailNoteDialogOpen}
          handleClose={() => {
            setEmailNoteDialogOpen(false);
          }}
        />
        <HumanizeInfoDialog
          dialogOpen={humanizeInfoDialogOpen}
          setDialogOpen={setHumanizeInfoDialogOpen}
        />
      </div>
    </>
  );
};

export default WritingPad;
