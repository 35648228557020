import { useEffect, useLayoutEffect, useState } from "react";
import _ from "lodash";
import { Box, CircularProgress } from "@material-ui/core";
import autosize from "autosize";
import WritingPad from "component/WritingPad/WritingPad";
import "./style.css";
import "../Dashboard/style.css";
import DeleteDialog from "component/Dialog/DeleteDialog/DeleteDialog";
import { DeleteNote, getNoteWithItems } from "services/note";

const NoteFullView = (props) => {
  const { noteId, deleteCallback } = props;
  const [activeNote, setActiveNote] = useState(-1);
  const [details, setDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [color, setColor] = useState("");
  const [open, setOpen] = useState(true);
  const [onNotePointerUpEvent, setOnNotePointerUpEvent] = useState({});
  const [writePad, setWritePad] = useState(true);

  useLayoutEffect(() => {
    autosize.update(document.querySelectorAll("textarea"));
  });

  const fetchNoteItems = async () => {
    setLoader(true);
    await getNoteWithItems(noteId).then((res) => setDetails(res.data));
    setLoader(false);
  };

  useEffect(() => {
    if (noteId > 0) {
      fetchNoteItems();
      setWritePad(true);
      setActiveNote(-1);
    }
  }, [noteId]);

  useEffect(() => {
    if (activeNote === -1) {
      if (noteId > 0) {
        fetchNoteItems();
      }
      setWritePad(true);
      setActiveNote(-1);
    } else {
      autosize(document.querySelectorAll("textarea"));
    }
  }, [activeNote]);

  const colorList = ["#35b736", "#ff8c00", "#1eaaf1"];

  let getActiveTitleNote = {};

  const notes = _.filter(_.get(details, "items", []), (note) => {
    return note.id === activeNote;
  });

  if (notes.length > 0) {
    getActiveTitleNote = notes[0];
  }

  return (
    <>
      {activeNote === -1 && (
        <div className="team-note-full-view">
          {loader ? (
            <div className="text-center">
              <CircularProgress thickness={4.5} style={{ color: "#D967BE" }} />
            </div>
          ) : (
            <>
              <div className="title d-flex">
                <span style={{ flex: 1 }}>
                  {_.get(details, "note.title", "")}
                </span>
                <span>
                  <DeleteDialog
                    isTeamMode={false}
                    isSingalOwner={true}
                    type="note"
                    confirmClick={async () => {
                      await DeleteNote(noteId);
                      deleteCallback(noteId, 3);
                    }}
                  >
                    <button
                      style={{
                        background: "#c71553",
                        color: "white",
                        border: "none",
                        borderRadius: "6px",
                        padding: "3px 16px",
                        marginLeft: "10px",
                        marginRight: "20px",
                        cursor: "pointer",
                        fontSize: "13.333px",
                      }}
                    >
                      Delete
                    </button>
                  </DeleteDialog>
                </span>
              </div>
              <div className="team-note-content">
                {_.map(_.get(details, "items", []), (item, index) => (
                  <div
                    className="team-note-item"
                    key={item.id}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setColor(colorList[index % colorList.length]);
                      setActiveNote(item.id);
                      setWritePad(true);
                    }}
                  >
                    <div className="team-note-item-title">{item.title}</div>
                    <div className="team-note-item-details">
                      <div className="text">{item.description}</div>
                      <div
                        className="devider"
                        style={{
                          background: colorList[index % colorList.length],
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {activeNote > -1 && (
        <>
          <Box
            className="Dashboard-wrapper"
            onPointerUp={(e) => {
              setOnNotePointerUpEvent({ ..._.cloneDeep(e) });
            }}
          >
            <WritingPad
              closeSideMenu={() => {
                if (open) setOpen(false);
              }}
              activeNote={getActiveTitleNote}
              activeNoteId={activeNote}
              editCallBack={() => {}}
              typeId={3}
              deleteCallback={() => {}}
              setWritePad={setWritePad}
              writePad={writePad}
              setActiveNote={setActiveNote}
              setActiveTab={() => {}}
              onNotePointerUpEvent={onNotePointerUpEvent}
              isRedirectFromRegister={false}
              isTeamMode={true}
              teamData={{
                isSingalOwner: true,
                noteId,
                noteColor: color,
                mainTitle: _.get(details, "note.title", ""),
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default NoteFullView;
