import { Document, HeadingLevel, Paragraph, Packer, TextRun } from "docx";
import { saveAs } from "file-saver";
import {
  Page,
  Text,
  View,
  StyleSheet,
  Document as PDFDoc,
} from "@react-pdf/renderer";

export const createDocx = (title, note) => {
  let para = [];
  const slitArray = (note || "").split("\n\n");
  for (let i = 0; i < slitArray.length; i++) {
    const text = slitArray[0];
    para.push(
      new Paragraph({
        children: [
          new TextRun({
            font: "Arial",
            text: text,
            size: 24,
          }),
        ],
        heading: HeadingLevel.Document,
      })
    );
    para.push(
      new Paragraph({
        children: [],
        heading: HeadingLevel.Document,
      })
    );
  }

  const document = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            heading: HeadingLevel.TITLE,
            children: [
              new TextRun({
                font: "Arial",
                text: title,
                size: 48,
                color: "d967be",
              }),
            ],
          }),
          new Paragraph({
            children: [],
          }),
          new Paragraph({
            children: [],
          }),
          ...para,
        ],
      },
    ],
  });

  return document;
};

export const exportDocx = (title, note) => {
  const doc = createDocx(title, note);
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "writelyai.docx");
  });
};

export const ExportPDF = (props) => {
  const styles = StyleSheet.create({
    page: { padding: 30 },
    title: { fontSize: 24, paddingBottom: 20, color: "#d967be" },
    note: { fontSize: 12 },
  });
  return (
    <PDFDoc>
      <Page size={"A4"} style={styles.page}>
        <View>
          <Text style={styles.title}>{props.title}</Text>
        </View>
        <View>
          <Text style={styles.note}>{props.note}</Text>
        </View>
      </Page>
    </PDFDoc>
  );
};

export const Export2Word = (title, note, filename = "") => {
  var preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  var postHtml = "</body></html>";
  let titleHtml = `<div style="font-family:Arial;font-size:32pt;color:#d967be;padding-bottom:20px">${title}</div><br/><br/>`;
  let noteHtml = "";
  const slitArray = (note || "").split("\n\n");
  for (let i = 0; i < slitArray.length; i++) {
    noteHtml += `<div style="font-family:Arial;font-size:16pt">${slitArray[i]}</div><br/>`;
  }
  var html = preHtml + titleHtml + noteHtml + postHtml;

  var blob = new Blob(["\ufeff", html], {
    type: "application/msword",
  });

  // Specify link url
  var url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  filename = filename ? filename + ".doc" : "document.doc";

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
  }

  document.body.removeChild(downloadLink);
};
