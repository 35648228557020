import { forwardRef, useRef, useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import "./style.css";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { teamIcon } from "assestes";
import { createWorkspace } from "services/workspace";
import { useToasts } from "react-toast-notifications";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateWorkspaceDialog = (props) => {
  const { addToast } = useToasts();
  const [emailList, setEmailList] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const ref = useRef(null);
  let { handleClose, added } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 30000);
    }
  }, [error]);

  const onEmailInput = (e) => {
    const { value } = e.target;
    if (
      value &&
      value.length > -1 &&
      [",", " "].indexOf(value[value.length - 1]) > -1
    ) {
      setEmailList([...emailList, value.substring(0, value.length - 1)]);
      setEmail("");
    } else {
      setEmail(value);
    }
  };

  const onAdd = async () => {
    if (loading) return;
    setLoading(true);
    let emailListClone = [...emailList];
    if (email.length > 0) {
      emailListClone.push(email);
    }
    try {
      let res = await createWorkspace(name, emailListClone);
      if (res.data.id) {
        added(res.data);
        setError("");
        addToast(`${name} is added to the workspace tab`, {
          appearance: "success",
          autoDismiss: true,
          PlacementType: "top-right",
        });
      }
    } catch (e) {
      setError(_.get(e, "response.data.title", ""));
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: { zIndex: "5000 !important", borderRadius: "8px" },
        }}
        maxWidth="xs"
        BackdropProps={{ style: { zIndex: "5000 !important" } }}
      >
        <DialogContent
          style={{ zIndex: "5000 !important", background: "#f4f4f4" }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <div
              className="create-workspace-dialog close"
              onClick={handleClose}
            >
              <CloseIcon
                overlineThickness={20}
                strikethroughThickness={7}
                style={{ color: "#1eaaf1" }}
              />
            </div>
            <div className="create-workspace-dialog">
              <div className="title">
                Add a workspace
                <img src={teamIcon} alt="teamIcon" />
              </div>
              <div className="name-input">
                <input
                  placeholder="Workspace name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div className="email-input" onClick={() => ref.current.focus()}>
                {_.map(emailList, (email, index) => (
                  <span className="tag" key={index}>
                    {email}
                    <span
                      className="remove-tag"
                      onClick={() => {
                        const emailListClone = [...emailList];
                        emailListClone.splice(index, 1);
                        setEmailList(emailListClone);
                      }}
                    >
                      <CloseIcon
                        overlineThickness={20}
                        strikethroughThickness={7}
                        fontSize="small"
                        style={{ color: "#fff" }}
                      />
                    </span>
                  </span>
                ))}
                <input
                  ref={ref}
                  onInput={onEmailInput}
                  value={email}
                  placeholder="Write the email(s) of your team seperated by a comma"
                ></input>
              </div>
              <div className="d-flex">
                <div className="btn-wrapper">
                  <button
                    className={
                      "d-btn hover-zoom team-bg" +
                      (loading || name.length === 0 ? " disabled" : "")
                    }
                    onClick={onAdd}
                    disabled={loading || name.length === 0}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            {error && <div className="error-top">{error}</div>}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateWorkspaceDialog;
