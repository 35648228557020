import { PriceRightIcon } from "assestes";
import { useState, useEffect, useContext } from "react";
import { getSubscription, updateSubscription } from "services/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import AccountLayout from "../AccountLayout";
import "./style.css";
import { paymentData } from "utils/constant";
import { trackButtonClick } from "utils";
import AuthContext from "contexts/authContext";

const UpgradeAccount = () => {
  const [card, setCard] = useState({});
  const [loading, setLoading] = useState("");
  const [mainLoading, setMainLoading] = useState(true);
  const { auth } = useContext(AuthContext);

  useEffect(async () => {
    let res = await getSubscription();
    if (
      _.get(auth, "userData.subsciption[0].status", "") === "active" ||
      _.get(auth, "userData.subsciption[0].status", "") === "trialing"
    ) {
      const portalUrl = _.get(res, "data.portalSessionUrl", "");
      if (portalUrl) {
        window.location.href = portalUrl;
      }
      setCard(_.get(res, "data[0]", {}));
    }
    setMainLoading(false);
  }, []);
  const currentPricingId = _.get(card, "currentPricingId", "");

  const getUpgread = async (id) => {
    setLoading(id);
    let res = await updateSubscription(id);
    window.location.href = res.data.url;
    setLoading("");
  };
  return (
    <AccountLayout>
      {mainLoading ? (
        <div className="UpgradeAccount">
          <div className="new-loader">
            <svg viewBox="-2000 -1000 4000 2000">
              <path
                id="inf"
                d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"
              ></path>
              <use
                xlinkHref="#inf"
                strokeDasharray="1570 5143"
                strokeDashoffset="6713px"
              ></use>
            </svg>
          </div>
        </div>
      ) : (
        <div className="upgrade-account">
          <div className="main-title">
            Focus on other non-writing tasks, by letting AI write for you.
          </div>
          <div className="card-wrapper">
            <div className="col">
              <div className="card">
                <div className="title">Monthly Plan</div>
                <div className="price">${paymentData.monthlyPrice}</div>
                <div className="month">/ month</div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">AI assisted auto-writing.</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    Commands to help expand your ideas.
                  </div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    1 on 1 buddy scheme with our engineers*
                  </div>
                </div>
                {paymentData.monthlyId === currentPricingId ? (
                  <div className="btn-get-me current">Current Plan</div>
                ) : (
                  <div
                    className="btn-get-me"
                    onClick={() => {
                      trackButtonClick("Upgrade Account", "Get me the Monthly");
                      getUpgread(paymentData.monthlyId);
                    }}
                  >
                    {loading !== paymentData.monthlyId ? (
                      "Get me the Monthly!"
                    ) : (
                      <CircularProgress
                        size={22}
                        style={{ color: "white", marginTop: "10px" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <div className="card card2">
                <div className="title">Annual Plan</div>
                <div className="price">${paymentData.yearlyPrice / 12}</div>
                <div className="month">/ month</div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">AI assisted auto-writing.</div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    Commands to help expand your ideas.
                  </div>
                </div>
                <div className="lines-wrapper">
                  <img src={PriceRightIcon} alt="PriceRightIcon" />
                  <div className="lines">
                    1 on 1 buddy scheme with our engineers*
                  </div>
                </div>

                {paymentData.yearlyId === currentPricingId ? (
                  <div className="btn-get-me current">Current Plan</div>
                ) : (
                  <div
                    className="btn-get-me"
                    onClick={() => {
                      trackButtonClick("Upgrade Account", "Get me the Annual");
                      getUpgread(paymentData.yearlyId);
                    }}
                  >
                    {loading !== paymentData.yearlyId ? (
                      "Get me the Annual!"
                    ) : (
                      <CircularProgress
                        size={22}
                        style={{ color: "white", marginTop: "10px" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </AccountLayout>
  );
};

export default UpgradeAccount;
