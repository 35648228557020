import React, { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ContentEditable from "react-contenteditable";
import {
  assignWorkspaceNoteItem,
  editWorkspaceNoteItem,
} from "services/workspace";
import { CircularProgress, Menu } from "@material-ui/core";
import _ from "lodash";
import { editNoteItem } from "services/note";

const CreateWorkspaceNoteItem = (props) => {
  const { workspaceId, workspaceTeam, isAssistedArticle } = props;
  let { item } = props;
  const contentEditable = React.createRef();
  const [title, setTitle] = useState(item.title);
  const [itemData, setItemData] = useState(item);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [userSearch, setUserSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isEdit) {
      setTitle(_.trim(title));
      setTitle(_.trim(title) + " ");
    }
  }, [isEdit]);

  const activeUser = _.find(workspaceTeam, (w) => w.userId === itemData.userId);

  const handleAssign = async (userId) => {
    setLoader(true);
    const res = await assignWorkspaceNoteItem(
      workspaceId,
      item.teamNoteId,
      item.id,
      userId
    );
    setItemData(res.data[0]);
    setLoader(false);
    setAnchorEl(null);
  };
  return (
    <div className="note-item">
      <div className="title-section">
        <div className="item-title">
          <ContentEditable
            innerRef={contentEditable}
            html={title}
            disabled={!isEdit}
            onChange={(evt) => setTitle(evt.target.value)}
          />
        </div>
        <div
          className="edit-icon"
          onClick={() => setIsEdit(!isEdit)}
          title={isEdit ? "Save sub-heading" : "Edit sub-heading"}
        >
          {isEdit ? (
            <SaveIcon
              onClick={() => {
                if (isAssistedArticle)
                  editNoteItem(
                    itemData.noteId,
                    itemData.id,
                    title,
                    itemData.description
                  );
                else
                  editWorkspaceNoteItem(
                    workspaceId,
                    itemData.teamNoteId,
                    itemData.id,
                    title,
                    itemData.description
                  );
              }}
              style={{ color: "rgb(142, 142, 147)" }}
              fontSize="small"
            />
          ) : (
            <EditIcon
              style={{ color: "rgb(142, 142, 147)" }}
              fontSize="small"
              title="Edit sub-heading"
            />
          )}
        </div>
      </div>
      {!isAssistedArticle && (
        <div className="assign-section">
          <span>Assigned to </span>
          <span
            className="assing-drop-down"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <div className="user-text">
              {_.get(activeUser, "firstName", "")}{" "}
              {_.get(activeUser, "lastName", "")}
            </div>
            <div className="arrow">
              <svg
                version="1.1"
                baseProfile="full"
                xmlns="http://www.w3.org/2000/svg"
                id="svg-s-Triangle_1"
                className="svgContainer"
                style={{ width: "7.6px", height: "6.33333px" }}
              >
                <g>
                  <g clip-path="url(#clip-s-Triangle_14199)">
                    <path
                      id="s-Triangle_1"
                      className="triangle shape firer commentable non-processed"
                      customid="Triangle 1"
                      d="M 3 0 L 7.599999999999909 6.333333333333314 L 0 6.333333333333314 Z"
                      style={{
                        transform: "rotate(0deg)",
                        fill: "rgb(255, 255, 255)",
                      }}
                    ></path>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip-s-Triangle_14199" className="clipPath">
                    <path d="M 3 0 L 7.599999999999909 6.333333333333314 L 0 6.333333333333314 Z"></path>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </span>

          <Menu
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            style={{
              marginTop: "75px",
              borderRadius: "110px",
            }}
            disableAutoFocus
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <div className="assign-popup">
              <>
                <div className="search">
                  <input
                    type="text"
                    value={userSearch}
                    placeholder={"Search"}
                    onChange={(e) => setUserSearch(e.target.value)}
                  ></input>
                </div>
                {isLoader && (
                  <CircularProgress
                    className="d-flex"
                    size={22}
                    thickness={4.5}
                    style={{ color: "#D967BE", display: "block !important" }}
                  />
                )}
                {!isLoader &&
                  _.map(
                    _.filter(
                      workspaceTeam,
                      (w) =>
                        userSearch === "" ||
                        _.toLower(w.firstName).indexOf(_.toLower(userSearch)) >
                          -1 ||
                        _.toLower(w.lastName).indexOf(_.toLower(userSearch)) >
                          -1
                    ),
                    (team) => (
                      <div
                        className={
                          "user" +
                          (_.get(activeUser, "userId", -2) === team.userId
                            ? " active"
                            : "")
                        }
                        onClick={() => handleAssign(team.userId)}
                      >
                        {team.firstName} {team.lastName}
                      </div>
                    )
                  )}
              </>
            </div>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default CreateWorkspaceNoteItem;
