import AuthContext from "contexts/authContext";
import BillingInformation from "pages/Account/BillingInformation";
import ContactUs from "pages/Account/ContactUs";
import UpgradeAccount from "pages/Account/UpgradeAccount";
import Chat from "pages/Chat";
import ComingSoon from "pages/ComingSoon";
import Dashboard from "pages/Dashboard";
import LandingNew from "pages/Landing/index.new";
import MobileLanding from "pages/Landing/mobileLanding";
import Login from "pages/Login";
import OtpVerifying from "pages/OtpVerifying";
import PasswordChanged from "pages/PasswordChanged";
import PaymentDone from "pages/PaymentDone";
import ResetPasswordNew from "pages/ResetPassword";
import SignUp from "pages/SignUp";
import SignUpDone from "pages/SignUpDone";
import StoryArticle from "pages/StoryArticle";
import TeamNoteFullView from "pages/TeamNoteFullView/TeamNoteFullView";
import WaitingListSuccess from "pages/WaitingListSuccess";
import Welcome from "pages/Welcome";
import CreateWorkspaceNote from "pages/Workspace/CreateNote/CreateWorkspaceNote";
import ManageWorkspace from "pages/Workspace/Manage/ManageWorkspace";
import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { Paths } from "utils/constant";

const MainRoutes = () => {
  const { auth } = useContext(AuthContext);
  return (
    <Switch>
      <>
        {!auth.isLoggedIn && (
          <>
            <Route exact path={Paths.pricing} component={ComingSoon} />
            <Route exact path={Paths.ourVision} component={ComingSoon} />
            <Route exact path={Paths.guide} component={ComingSoon} />
            <Route exact path={Paths.contact}>
              <ContactUs wantAccountLayout={false} />
            </Route>
            <Route exact path={Paths.login} component={Login} />
            <Route exact path={Paths.signUp} component={SignUp} />
            <Route
              exact
              path={Paths.landing + "mobile"}
              component={MobileLanding}
            />
            <Route
              exact
              path={Paths.landing}
              component={window.innerWidth < 550 ? MobileLanding : LandingNew}
            />

            <Route
              exact
              path={Paths.waitingListSuccess}
              component={WaitingListSuccess}
            />
          </>
        )}
        {auth.isLoggedIn && (
          <>
            <Route exact path={Paths.welcome} component={Welcome} />
            <Route exact path={Paths.dashboard} component={Dashboard} />
            <Route
              exact
              path={Paths.teamNoteFullView}
              component={TeamNoteFullView}
            />

            {/* <Route
              exact
              path={Paths.billingInfo}
              component={BillingInformation}
            /> */}
            <Route
              exact
              path={Paths.upgradeAccount}
              component={UpgradeAccount}
            />
            <Route exact path={Paths.storyArticle} component={StoryArticle} />
            <Route exact path={Paths.contactUs} component={ContactUs} />
            <Route exact path={Paths.paymentDone} component={PaymentDone} />
            <Route
              exact
              path={Paths.manageWorkspace}
              component={ManageWorkspace}
            />
            <Route
              exact
              path={Paths.createWorkspaceNote}
              component={CreateWorkspaceNote}
            />
            <Route
              exact
              path={Paths.createAssistedArticleNote}
              component={CreateWorkspaceNote}
            />
            <Route
              exact
              path={Paths.workspaceNote}
              component={CreateWorkspaceNote}
            />
            <Route exact path={Paths.chat} component={Chat} />
            <Route exact path={Paths.emptyChat} component={Chat} />
          </>
        )}
        <Route exact path={Paths.resetPassword} component={ResetPasswordNew} />
        <Route exact path={Paths.signUpDone} component={SignUpDone} />
        <Route exact path={Paths.passwordChanged} component={PasswordChanged} />
        <Route exact path={Paths.otpVerifying} component={OtpVerifying} />
      </>
    </Switch>
  );
};
export default MainRoutes;
