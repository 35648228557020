import { Box, FormHelperText } from "@material-ui/core";
import Footer from "component/Footer";
import Header from "component/Header";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendResetPasswordOtp, validateOtp } from "services/auth";
import { Paths } from "utils/constant";
import PhoneInput from "react-phone-input-2";
import AccountLayout from "../Account/AccountLayout";
import "./style.css";
import { trackButtonClick } from "utils";

function OtpVerifying() {
  const { auth } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpError, setOtpError] = useState("");
  const [secondToRedirect, setSecondToRedirect] = useState(10);

  const resetError = () => {
    setOtpError("");
  };

  const content = (
    <>
      {step !== 3 && (
        <div className="main-title">
          We have suspected unusual activity with your account, please verify
          your phone number again to continue using
        </div>
      )}
      {step === 3 && (
        <div className="main-title">
          Account activated, you can now continue by login
        </div>
      )}
      {step === 1 && (
        <div className="card-wrapper mt-30">
          <div className="card low-pad">
            <div className="title1">Please enter your mobile number</div>
            <PhoneInput
              className={phoneNumber.length === 0 ? "disabled" : ""}
              inputProps={{
                name: "phoneNumber",
                required: true,
                autoFocus: true,
              }}
              enableSearch={true}
              name="phoneNumber"
              country={"us"}
              onChange={(value, country, e, formattedValue) => {
                if (value) setPhoneNumber("+" + value);
                else setPhoneNumber("");
              }}
              value={phoneNumber}
              placeholder="Your registered number here.."
            />
            <FormHelperText error>{otpError && otpError}</FormHelperText>
            <Box mt={2}>
              <button
                className="btn-login hover-zoom"
                type="submit"
                disabled={phoneNumber.length === 0 || isLoading}
                onClick={async () => {
                  trackButtonClick("OtpVerifying", "Verify");
                  try {
                    if (sendResetPasswordOtp) {
                      setIsLoading(true);

                      const res = await sendResetPasswordOtp(phoneNumber);
                      if (res.data.result === "OTP sent") {
                        setIsLoading(false);
                        setStep(2);
                        resetError();
                      }
                      setIsLoading(false);
                    }
                  } catch (err) {
                    console.error(err);
                    setIsLoading(false);
                    setOtpError(
                      _.get(err, "response.data.title", "Something went wrong")
                    );
                  }
                }}
              >
                {isLoading ? "Verifying..." : "Verify"}
              </button>
            </Box>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="card-wrapper mt-30">
          <div className="card low-pad">
            <div className="title1">
              Please enter the code sent to your email.
            </div>
            <input
              className={otp.length !== 6 ? "disabled otp" : "otp"}
              placeholder={`Code sent to ${phoneNumber}`}
              onChange={(e) => {
                if (
                  ((!isNaN(e.target.value) &&
                    Number.isInteger(parseFloat(e.target.value))) ||
                    _.isEmpty(e.target.value)) &&
                  e.target.value.length <= 6
                ) {
                  setOtp(e.target.value);
                }
              }}
              value={otp}
            />
            <FormHelperText error>{otpError && otpError}</FormHelperText>
            <Box mt={2}>
              <button
                className="btn-login hover-zoom"
                type="submit"
                disabled={otp.length !== 6 || isLoading}
                onClick={async () => {
                  trackButtonClick("OtpVerifying", "Send");
                  try {
                    if (validateOtp) {
                      setIsLoading(true);
                      const userData = await validateOtp(
                        phoneNumber,
                        otp,
                        "AccountActivation"
                      );
                      if (userData.data.result === "OTP Valid") {
                        setIsLoading(false);
                        history.push({
                          pathname: Paths.passwordChanged,
                          state: {
                            text: "Account activated",
                          },
                        });
                        setStep(3);
                        resetError();
                        let startTimeMS = new Date().getTime();
                        setTimeout(() => {
                          history.push(Paths.login);
                        }, 10000);
                        setInterval(function () {
                          getTimeLeft();
                        }, 1000);
                        function getTimeLeft() {
                          setSecondToRedirect(
                            Math.ceil(
                              (10000 - (new Date().getTime() - startTimeMS)) /
                                1000
                            )
                          );
                        }
                      }
                      setIsLoading(false);
                    }
                  } catch (err) {
                    console.error(err);
                    setIsLoading(false);
                    setOtpError(
                      _.get(err, "response.data.title", "Something went wrong")
                    );
                  }
                }}
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            </Box>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="card-wrapper mt-30">
          <div className="card low-pad">
            <div className="title1">
              Redirecting to login in {secondToRedirect} second...
            </div>
          </div>
        </div>
      )}
    </>
  );
  return (
    <div className="otp-verifying">
      {!auth.isLoggedIn ? (
        <>
          <Header />
          {content}
          <Footer />
        </>
      ) : (
        <AccountLayout>{content}</AccountLayout>
      )}
    </div>
  );
}

export default OtpVerifying;
