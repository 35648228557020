import { PDFDownloadLink } from "@react-pdf/renderer";
import { ExportPDF } from "component/Export";
import React, { useEffect, useState } from "react";

function PDFDownload(props) {
  const { title, note } = props;
  const [downloading, setDownloading] = useState(false);
  useEffect(() => {
    if (downloading) {
      setTimeout(() => {
        document.getElementById("PDFDownloadLink-down").click();
        setDownloading(false);
      }, 700);
    }
  }, [downloading]);
  return (
    <>
      <div id="PDFDownloadLink" onClick={() => setDownloading(true)}>
        writelyai.pdf
      </div>
      {downloading && (
        <PDFDownloadLink
          fileName={title.slice(0, 16) || "writelyai"}
          key={`${(title || "").length}-${(note || "").length}`}
          document={<ExportPDF title={title} note={note} />}
        >
          {() => {
            return (
              <>
                <div
                  id="PDFDownloadLink-down"
                  style={{ opacity: 0, width: "0px", height: "0px" }}
                >
                  writelyai.pdf
                </div>
              </>
            );
          }}
        </PDFDownloadLink>
      )}
    </>
  );
}

export default PDFDownload;
