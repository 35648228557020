import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import "./style.css";
import { trackButtonClick } from "utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {
  const { children, confirmClick, text, loading = false } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    trackButtonClick("ConfirmationDialog", "Open");
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    trackButtonClick("ConfirmationDialog", "No");
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleClickOpen}>{children}</span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="confirm-dialog">
              <div className="text" style={{ textAlign: "center" }}>
                {text}
              </div>
              <div className="d-flex">
                <div className="btn-wrapper">
                  <div
                    className="d-btn btn-delete hover-zoom"
                    onClick={() => {
                      confirmClick();
                      setOpen(false);
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        size={25}
                        style={{ color: "white", marginTop: "6px" }}
                      />
                    ) : (
                      "Yes"
                    )}
                  </div>
                  <div
                    className="d-btn btn-delete hover-zoom btn-no"
                    onClick={handleClose}
                  >
                    No
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
