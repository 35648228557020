import { Box, FormHelperText } from "@material-ui/core";
import Header from "component/Header";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  changePassword,
  sendResetPasswordOtp,
  validateOtp,
} from "services/auth";
import { Paths } from "utils/constant";
import PhoneInput from "react-phone-input-2";
import AccountLayout from "../Account/AccountLayout";
import "./style.css";
import { trackButtonClick } from "utils";
import HeaderNew from "component/Header/index.new";
import FooterNew from "component/Footer/index.new";
import Footer from "component/Footer";

function ResetPasswordNew() {
  const { auth } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const resetError = () => {
    setOtpError("");
    setPasswordError("");
    setConfirmPasswordError("");
  };

  const progressBar = (
    <div className="progress-bar">
      <div
        className={
          "item " +
          (step >= 1 ? "active-item " : "") +
          (step === 1 ? "active-text" : "")
        }
      >
        Verify Phone
      </div>
      <div
        className={
          "item " +
          (step >= 2 ? "active-item " : "") +
          (step === 2 ? "active-text" : "")
        }
      >
        Send Code
      </div>
      <div
        className={
          "item " +
          (step >= 3 ? "active-item " : "") +
          (step === 3 ? "active-text" : "")
        }
      >
        New Password
      </div>
    </div>
  );

  const content = (
    <>
      {/* <div className="main-title">Reset Password (Step {step} of 3)</div> */}
      {step === 1 && (
        <div className="card-wrapper mt-30">
          <div className="card low-pad">
            <div className="title1">Reset Password</div>
            {progressBar}
            <div className="input-title">Enter your phone number</div>
            <PhoneInput
              className={phoneNumber.length === 0 ? "disabled" : ""}
              inputProps={{
                name: "phoneNumber",
                required: true,
                autoFocus: true,
              }}
              enableSearch={true}
              name="phoneNumber"
              country={"us"}
              onChange={(value, country, e, formattedValue) => {
                if (value) setPhoneNumber("+" + value);
                else setPhoneNumber("");
              }}
              value={phoneNumber}
              placeholder="Your registered number here.."
            />
            <FormHelperText error>{otpError && otpError}</FormHelperText>
            <Box mt={2}>
              <button
                className="btn-login hover-zoom"
                type="submit"
                disabled={phoneNumber.length === 0 || isLoading}
                onClick={async () => {
                  trackButtonClick("ResetPassword", "Verify");
                  try {
                    if (sendResetPasswordOtp) {
                      setIsLoading(true);

                      const res = await sendResetPasswordOtp(phoneNumber);
                      if (res.data.result === "OTP sent") {
                        setIsLoading(false);
                        setStep(2);
                        resetError();
                      }
                      setIsLoading(false);
                    }
                  } catch (err) {
                    console.error(err);
                    setIsLoading(false);
                    setOtpError(
                      _.get(err, "response.data.title", "Something went wrong")
                    );
                  }
                }}
              >
                {isLoading ? "Verifying..." : "Verify"}
              </button>
            </Box>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="card-wrapper mt-30">
          <div className="card low-pad">
            <div className="title1">Reset Password</div>
            {progressBar}
            <div className="input-title">Enter the code sent to your email</div>
            <input
              className={otp.length !== 6 ? "disabled otp" : "otp"}
              placeholder={`Code sent to your email`}
              onChange={(e) => {
                if (
                  ((!isNaN(e.target.value) &&
                    Number.isInteger(parseFloat(e.target.value))) ||
                    _.isEmpty(e.target.value)) &&
                  e.target.value.length <= 6
                ) {
                  setOtp(e.target.value);
                }
              }}
              value={otp}
            />
            <FormHelperText error>{otpError && otpError}</FormHelperText>
            <Box mt={2}>
              <button
                className="btn-login hover-zoom"
                type="submit"
                disabled={otp.length !== 6 || isLoading}
                onClick={async () => {
                  trackButtonClick("ResetPassword", "Send");
                  try {
                    if (validateOtp) {
                      setIsLoading(true);

                      const userData = await validateOtp(phoneNumber, otp);
                      if (userData.data.result === "OTP Valid") {
                        setIsLoading(false);
                        setStep(3);
                        resetError();
                      }
                      setIsLoading(false);
                    }
                  } catch (err) {
                    console.error(err);
                    setIsLoading(false);
                    setOtpError(
                      _.get(err, "response.data.title", "Something went wrong")
                    );
                  }
                }}
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            </Box>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="card-wrapper mt-30">
          <div className="card low-pad">
            <div className="title1">Reset Password</div>
            {progressBar}
            <div className="input-title">Create your new password</div>
            <Box mb={2}>
              <input
                type="password"
                placeholder={`Enter your new password`}
                className={password.length < 6 ? "disabled otp" : "otp"}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length >= 6) {
                    setPasswordError("");
                  }
                }}
                onBlur={() => {
                  if (password.length < 6) {
                    setPasswordError("Password must be at least 6 characters");
                  } else {
                    setPasswordError("");
                  }
                }}
                value={password}
              />
              <FormHelperText error>
                {passwordError && passwordError}
              </FormHelperText>
            </Box>
            <input
              placeholder={`Confirm your new password`}
              type="password"
              className={
                confirmPassword.length < 6 || password !== confirmPassword
                  ? "disabled otp"
                  : "otp"
              }
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                if (password === e.target.value) {
                  setConfirmPasswordError("");
                }
              }}
              onBlur={() => {
                if (password !== confirmPassword) {
                  setConfirmPasswordError("Password do not matching");
                } else if (confirmPassword.length < 6) {
                  setConfirmPasswordError(
                    "Password must be at least 6 characters"
                  );
                } else {
                  setConfirmPasswordError("");
                }
              }}
              value={confirmPassword}
            />
            <FormHelperText error>
              {confirmPasswordError && confirmPasswordError}
            </FormHelperText>
            <Box mt={2}>
              <button
                className="btn-login hover-zoom"
                type="submit"
                disabled={
                  password.length < 6 ||
                  password !== confirmPassword ||
                  isLoading
                }
                onClick={async () => {
                  trackButtonClick("ResetPassword", "Confirm");
                  try {
                    if (changePassword) {
                      setIsLoading(true);
                      const userData = await changePassword(
                        phoneNumber,
                        otp,
                        password
                      );
                      if (userData.data.result === "Password changed") {
                        setIsLoading(false);
                        history.push({
                          pathname: Paths.passwordChanged,
                          state: { email: userData.data.email },
                        });
                      }
                      setIsLoading(false);
                    }
                  } catch (err) {
                    console.error(err);
                    setIsLoading(false);
                    setOtpError(
                      _.get(err, "response.data.title", "Something went wrong")
                    );
                  }
                }}
              >
                {isLoading ? "Confirming..." : "Confirm"}
              </button>
            </Box>
          </div>
        </div>
      )}
    </>
  );
  return (
    <div className="reset-password-new">
      {!auth.isLoggedIn ? (
        <>
          {window.innerWidth < 750 ? <Header /> : <HeaderNew />}
          {content}
          {window.innerWidth < 550 ? <Footer /> : <FooterNew />}
        </>
      ) : (
        <AccountLayout>{content}</AccountLayout>
      )}
    </div>
  );
}

export default ResetPasswordNew;
