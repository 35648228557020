import { useContext, useState } from "react";
import AuthContext from "contexts/authContext";
import { useLocation, useHistory } from "react-router-dom";
import { Paths } from "utils/constant";
import MenuIcon from "@material-ui/icons/Menu";
import "./style.css";
import Sidebar from "./Sidebar";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { headerLogo, mobileLogo } from "assestes";
import _ from "lodash";
import { trackButtonClick } from "utils";
import { RootContext } from "contexts/rootContext";

const Header = (props) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(true);
  const { bgWhite, onlyMobileView } = props;
  const { pathname } = useLocation();
  const history = useHistory();
  const { auth, changeAuth } = useContext(AuthContext);
  const { isNightMode, setGlobal } = useContext(RootContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleClick = (event) => {
    trackButtonClick("Header", "Menu click");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (onlyMobileView && window.innerWidth < 550) {
    return (
      <>
        <div className="mobile-header">
          <img
            src={mobileLogo}
            alt="mobile-logo"
            className="mobile-logo mw-133"
            onClick={() => {
              trackButtonClick("Header", "Mobile Logo");
              history.push(Paths.landing);
            }}
          />
          <div className="menu-btn">
            <MenuIcon
              style={{ color: "#282828", width: "40px", height: "40px" }}
              onClick={() => {
                trackButtonClick("Header", "Mobile Menu Icon");
                setMobileMenuOpen(!mobileMenuOpen);
              }}
            />
          </div>
          {mobileMenuOpen && (
            <div className={`mobile-menu ` + (mobileMenuOpen ? "active" : "")}>
              <div
                className="menu-item"
                onClick={() => {
                  trackButtonClick("Header", "Pricing");
                  window.location.href = "/#pricing";
                  setMobileMenuOpen(false);
                }}
              >
                Pricing
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  trackButtonClick("Header", "FAQS");
                  window.location.href = "/#faqs";
                  setMobileMenuOpen(false);
                }}
              >
                FAQS
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  trackButtonClick("Header", "Contact");
                  history.push(Paths.contact);
                }}
              >
                Contact
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  trackButtonClick("Header", "Log In");
                  history.push(Paths.login);
                }}
              >
                Log In
              </div>
              <div
                className="menu-item"
                onClick={() => {
                  trackButtonClick("Header", "Sign Up");
                  history.push(Paths.signUp);
                }}
              >
                Sign Up
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className={bgWhite ? "header-wrapper bgWhite" : "header-wrapper"}>
      {!auth.isLoggedIn && (
        <>
          <Sidebar
            onMobileClose={() => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          />
          <div
            className="menu mobile-only"
            onClick={() => {
              trackButtonClick("Header", "Menu Menu Icon");
              setIsSidebarMobileOpen(true);
            }}
          >
            <MenuIcon style={{ color: "#D967BE" }} />
          </div>
          <div
            className={
              auth.isLoggedIn
                ? "header ml-a desktop-only"
                : "header desktop-only"
            }
          >
            <>
              <div>
                <img
                  onClick={() => {
                    trackButtonClick("Header", "Logo");
                    history.push("/");
                  }}
                  className="header-logo"
                  src={headerLogo}
                  alt="header-logo"
                />
              </div>
              <div
                className="header-item hover-btext"
                onClick={() => {
                  trackButtonClick("Header", "Home");
                  history.push(Paths.landing);
                }}
              >
                {pathname === Paths.landing ? (
                  <>
                    <b>Home</b>
                  </>
                ) : (
                  "Home"
                )}
                {pathname === Paths.landing && (
                  <div className="active-item"></div>
                )}
              </div>
              <div
                className="header-item hover-btext"
                onClick={() => {
                  trackButtonClick("Header", "Pricing");
                  if (pathname === Paths.landing)
                    window.location.href = "#pricing";
                  else history.push(Paths.landing + "#pricing");
                }}
              >
                Pricing
                {pathname === Paths.pricing && (
                  <div className="active-item"></div>
                )}
              </div>
              <div
                className="header-item hover-btext"
                onClick={() => {
                  trackButtonClick("Header", "FAQs");
                  if (pathname === Paths.landing)
                    window.location.href = "#faqs";
                  else history.push(Paths.landing + "#faqs");
                }}
              >
                FAQs
                {pathname === Paths.faqs && <div className="active-item"></div>}
              </div>
              <div
                className="header-item hover-btext"
                onClick={() => {
                  trackButtonClick("Header", "Contact");
                  history.push(Paths.contact);
                }}
              >
                {pathname === Paths.contact ? (
                  <>
                    <b>Contact</b>
                  </>
                ) : (
                  "Contact"
                )}
                {pathname === Paths.contact && (
                  <div className="active-item"></div>
                )}
              </div>
              {pathname !== Paths.login && (
                <div
                  className="btn-header-login hover-zoom"
                  onClick={() => {
                    trackButtonClick("Header", "Log in");
                    history.push(Paths.login);
                  }}
                >
                  Log in
                </div>
              )}
              {pathname === Paths.login && (
                <div
                  className="btn-header-login hover-zoom"
                  onClick={() => {
                    trackButtonClick("Header", "Sign Up");
                    history.push(Paths.signUp);
                  }}
                >
                  Sign Up
                </div>
              )}
            </>
          </div>
        </>
      )}
      {auth.isLoggedIn && (
        <>
          <Box
            textAlign="right"
            display="block"
            position="relative"
            width="100%"
            m={5}
            mb={0}
          >
            <MenuIcon
              style={{ color: "#454041" }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              fontSize="large"
              className="pointer"
              onClick={handleClick}
            />
            <span
              style={{
                position: "absolute",
                top: "-3px",
                right: "-6px",
                background: "red",
                width: "8px",
                height: "8px",
                borderRadius: "100%",
              }}
            ></span>
          </Box>
          <Menu
            id="top-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            style={{
              marginTop: "45px",
              marginRight: "50px",
              borderRadius: "110px",
            }}
            disableAutoFocus
            onClose={handleClose}
          >
            {pathname !== Paths.dashboard && (
              <MenuItem style={{ width: "180px" }} onClick={handleClose}>
                {pathname === Paths.dashboard ? "Account" : "Dashboard"}
              </MenuItem>
            )}
            <MenuItem
              style={{ width: "180px" }}
              onClick={() => {
                trackButtonClick("Header", "Subscription");
                history.push(Paths.upgradeAccount);
                handleClose();
              }}
            >
              {_.get(auth, "userData.subsciption[0].status", "") === "active" ||
              _.get(auth, "userData.subsciption[0].status", "") === "trialing"
                ? "Subscription"
                : "Pricing"}
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackButtonClick("Header", "Password");
                history.push(Paths.resetPassword);
                handleClose();
              }}
            >
              Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackButtonClick("Header", "Contact");
                history.push(Paths.contactUs);
                handleClose();
              }}
            >
              Contact
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackButtonClick("Header", "Chat");
                history.push(Paths.emptyChat);
                handleClose();
              }}
            >
              New: WritelyCHAT
              <span
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "19px",
                  background: "red",
                  width: "5px",
                  height: "5px",
                  borderRadius: "100%",
                }}
              ></span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setGlobal("isNightMode", isNightMode ? false : true);
              }}
            >
              {isNightMode ? "Light" : "Night"} Mode
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackButtonClick("Header", "Log out");
                changeAuth({ isLoggedIn: false, userData: {} });
                history.push(Paths.login);
                handleClose();
              }}
            >
              Log out
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default Header;
