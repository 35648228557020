import {
  LandingFileTextIcon,
  LandingPenToolIcon,
  LandingEmailIcon,
  LandingChatIcon,
  LandingWritersImg,
  LandingPresentationImg,
} from "assestes";
import AccordionItem from "component/AccordionItem";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { trackButtonClick } from "utils";
import { Paths, paymentData } from "utils/constant";
import { useIntercom } from "react-use-intercom";
// import LandingDemo1 from "./LandingDemo1";
import "./main.scss";
import FreeTrialDialog2 from "component/Dialog/FreeTrialDialog2/FreeTrialDialog2";
import HeaderNew from "component/Header/index.new";
import FooterNew from "component/Footer/index.new";

const LandingNew = () => {
  const history = useHistory();
  const [uemail, setUemail] = useState("");

  // const [firstName, setFirstName] = useState("");
  // const [contactEmail, setContactEmail] = useState("");
  // const [message, setMessage] = useState("");

  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (window.innerWidth > 1150) boot();
    return () => {
      if (window.innerWidth > 1150) shutdown();
    };
  }, []);

  // const validateEmail = (email) => {
  //   const regex =
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return regex.test(email);
  // };

  return (
    <div className="wrapper">
      {/* <ChromeExtensions hover={false} /> */}
      <FreeTrialDialog2 />
      <HeaderNew />
      <section className="top-section">
        <div className="container">
          <div className="row">
            <div className="col-12 top-section__content">
              <h1>
                Beat AI Detection:
                <br />
                You Write,
                <br />
                <span>Writely</span> completes!
              </h1>
              <h5>
                Write more content, faster than ever before and bypass AI
                detection. Just enter your email address and claim your trial
                today.
              </h5>
              <div className="top-section__form">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => setUemail(e.target.value)}
                  value={uemail}
                />
                <div className="d-flex flex-column align-items-center">
                  <button
                    className="top-section__button btn"
                    onClick={() => {
                      trackButtonClick("Landing", "Free Trial");
                      // if (uemail) {
                      history.push(Paths.signUp + "?email=" + uemail);
                      // }
                    }}
                  >
                    Try now
                  </button>
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3337 9.2333V9.99997C18.3326 11.797 17.7507 13.5455 16.6748 14.9848C15.5988 16.4241 14.0864 17.477 12.3631 17.9866C10.6399 18.4961 8.79804 18.4349 7.11238 17.8121C5.42673 17.1894 3.98754 16.0384 3.00946 14.5309C2.03138 13.0233 1.56682 11.24 1.68506 9.4469C1.80329 7.65377 2.498 5.94691 3.66556 4.58086C4.83312 3.21482 6.41098 2.26279 8.16382 1.86676C9.91665 1.47073 11.7505 1.65192 13.392 2.3833"
                        stroke="#CF5FA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.3333 3.33325L10 11.6749L7.5 9.17492"
                        stroke="#CF5FA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Cancel anytime
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="features">
        <h2>Our Features</h2>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="feature-item">
                <div className="feature-item__img">
                  <img src={LandingFileTextIcon} alt="File with text" />
                </div>
                <h4>Write article</h4>
                <p>Informative articles, blogs, etc</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="feature-item">
                <div className="feature-item__img">
                  <img src={LandingPenToolIcon} alt="Pencil" />
                </div>
                <h4>Write a short story</h4>
                <p>Poetry, story writing, etc</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="feature-item">
                <div className="feature-item__img">
                  <img src={LandingEmailIcon} alt="Email" />
                </div>
                <h4>Write emails</h4>
                <p>Cold emails, complaints, etc</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="feature-item">
                <div className="feature-item__img">
                  <img src={LandingChatIcon} alt="Chat icon" />
                </div>
                <h4>WritelyCHAT</h4>
                <p>Your very own assistant</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="writers-block">
        <div className="container">
          <div className="section__wrapper">
            <div className="row">
              <div className="col-12 col-lg-6 section__img">
                <picture>
                  <img src={LandingWritersImg} alt="Writers oreview" />
                </picture>
              </div>
              <div className="col-12 col-lg-6 section__text">
                <h2>Overcome your writer’s block with Writely</h2>
                <p>
                  Our innovative writing platform is designed to inspire
                  creativity and eliminate writer's block. With Writely, you can
                  access a range of writing prompts, exercises, and tools to
                  help you generate new ideas and stay on track with your
                  writing goals.
                </p>
                <a
                  href={Paths.signUp}
                  className="btn"
                  onClick={() => {
                    trackButtonClick("Landing", "Writers Section");
                  }}
                >
                  Start writing
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="teams">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h2>Teamwork makes the dream work.</h2>
              <p>
                Create a workspace. <br /> Invite collaborators.
                <br /> Start writing together in real time.
              </p>
              <p>It's that simple.</p>
              <a
                href={Paths.signUp}
                className="btn"
                onClick={() => {
                  trackButtonClick("Landing", "Teams Section");
                }}
              >
                Start now
              </a>
            </div>
            <div className="col-12 col-lg-8 section__img">
              <picture>
                <img src={LandingPresentationImg} alt="" />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section className="plans" id="plans">
        <div className="container">
          <h2>Pricing plans</h2>
          <div className="plans__items">
            <div className="plans__item card">
              <h4>Monthly Plan</h4>
              <ul className="card__list">
                <li>Human like AI completions</li>
                <li>Commands to help expand your ideas</li>
                <li>1 on 1 buddy scheme with our experts</li>
                <li>Collaboration with your team</li>
                <li>Unlimited usage</li>
                <li>Access to our partners for promotion (20M followers)*</li>
              </ul>
              <div className="plans__price">
                <h3>${paymentData.monthlyPrice}.00</h3>
                <p>per month, charged monthly</p>
              </div>
              <a
                href={Paths.signUp}
                className="btn"
                onClick={() => {
                  trackButtonClick("Landing", "Monthly Plan Card");
                }}
              >
                Get me monthly
              </a>
            </div>
            <div className="plans__item card">
              <h4>Annual Plan</h4>
              <ul className="card__list">
                <li>Human like AI completions</li>
                <li>Commands to help expand your ideas</li>
                <li>1 on 1 buddy scheme with our experts</li>
                <li>Collaboration with your team</li>
                <li>Unlimited usage</li>
                <li>Access to our partners for promotion (20M followers)*</li>
              </ul>
              <div className="plans__price">
                <h3>${paymentData.yearlyPrice / 12}.00</h3>
                <p>charged annually (${paymentData.yearlyPrice}.00/year)</p>
              </div>
              <a
                href={Paths.signUp}
                className="btn"
                onClick={() => {
                  trackButtonClick("Landing", "Annual Plan Card");
                }}
              >
                Get me annual
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="faq" id="faqs">
        <h2>FAQs</h2>
        <div className="container">
          <h2 className="faq__subtitle">Frequently asked questions</h2>
          <div className="faq__list">
            <AccordionItem
              className="faq__item"
              caption="How does it actually work?"
            >
              <p>
                Machine learning. This is when a machine is fed lots of data,
                guiding it to learn patterns. Using this approach, Writely uses
                models that are trained with a lot of text to generate
                sophisticated outputs, and can therefore closely replicate the
                work of a human being, although not as clever as us!
              </p>
            </AccordionItem>
            <AccordionItem
              className="faq__item"
              caption="Does the output pass plaigarism checks?"
            >
              <p>
                The risk of plagiarism on Writely is currently extremely
                minimal. This is because we use deep learning to enable the
                model to behave as close to a human being as possible. However,
                we will be incorporating 100% plagiarism-free outputs as part of
                our offering.
              </p>
            </AccordionItem>
            <AccordionItem
              className="faq__item"
              caption="What can I use this for?"
            >
              <p>
                You are free to be as creative as you like in terms of how you
                use it. Whether you are a blogger, a marketing manager,
                university student or anyone needing to write, this is the place
                to get rid of any mental block associated to writing.
              </p>
            </AccordionItem>
            <AccordionItem
              className="faq__item"
              caption="Will the output be detected by anti Al tools?"
            >
              <p>
                Our humanize feature allows users to bypass Al detection for
                almost all cases. You will have unlimited humanized generations,
                in case you aren't happy with the first result.
              </p>
            </AccordionItem>
          </div>
        </div>
      </section>
      {/* <section className="contact">
        <h2>Contact Us</h2>
        <div className="container contact__content">
          <div className="row">
            <div className="col-12 col-lg-5 contact__text">
              <h2>Contact us & get all your questions answered</h2>
              <p>Our team of experts is here to answer your questions day and night. We also love well wishes or constructive feedback. So, do not hesitate to contact us at any time. We will try to answer you within 2 hours.</p>
            </div>
            <div className="col-12 col-lg-7">
              <form className="contact-form">
                <div className="contact-form__inputs">
                  <div className="contact-form__input">
                    <label htmlFor="first-name">
                      Full name
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      placeholder="John Johanson"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className="contact-form__input">
                    <label htmlFor="email-input">
                      Email address
                    </label>
                    <input 
                      type="text"
                      id="email-input"
                      placeholder="johanson@example.com"
                      onChange={(e) => setContactEmail(e.target.value)}
                      value={contactEmail}
                    />
                  </div>
                </div>
                <textarea 
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                ></textarea>
                <button className="btn">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      <section className="for-free">
        <div className="container">
          <div className="section__wrapper">
            <div className="for-free__content">
              <h2>Try Writely!</h2>
              <h5>
                Get the most powerful AI assistant at your fingertips. Tap into
                your inner creativity that you’ve never explored!
              </h5>
              <a
                href={Paths.signUp}
                className="btn"
                onClick={() => {
                  trackButtonClick("Free Trial", "Free Plan Card");
                }}
              >
                Start your trial
              </a>
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3337 9.2333V9.99997C18.3326 11.797 17.7507 13.5455 16.6748 14.9848C15.5988 16.4241 14.0864 17.477 12.3631 17.9866C10.6399 18.4961 8.79804 18.4349 7.11238 17.8121C5.42673 17.1894 3.98754 16.0384 3.00946 14.5309C2.03138 13.0233 1.56682 11.24 1.68506 9.4469C1.80329 7.65377 2.498 5.94691 3.66556 4.58086C4.83312 3.21482 6.41098 2.26279 8.16382 1.86676C9.91665 1.47073 11.7505 1.65192 13.392 2.3833"
                    stroke="#CF5FA6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.3333 3.33325L10 11.6749L7.5 9.17492"
                    stroke="#CF5FA6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Cancel anytime
              </span>
            </div>
          </div>
        </div>
      </section>
      <FooterNew />
    </div>
  );
};

export default LandingNew;
