import {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Box } from "@material-ui/core";
import Header from "component/Header";
import SlidMenu from "component/SideMenu/SideMenu";
import WritingPad from "component/WritingPad/WritingPad";
import autosize from "autosize";
import { getAllNotes } from "services/note";
import "./style.css";
import _ from "lodash";
import { circleAdd } from "assestes";
import LoginModal from "component/LoginModal/LoginModal";
import TourModal from "component/TourModal/TourModal";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { trackButtonClick } from "utils";
import { useIntercom } from "react-use-intercom";
import AuthContext from "contexts/authContext";
import NoteFullView from "pages/TeamNoteFullView/NoteFullView";
import { RootContext } from "contexts/rootContext";
import FreeTrialDialog from "component/Dialog/FreeTrialDialog/FreeTrialDialog";
import WritelyCHATTag from "component/WritelyChatTag";

const Dashboard = () => {
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("articles");
  const [blogList, setBlogList] = useState({});
  const [storyList, setStoryList] = useState({});
  const [articleList, setArticleList] = useState({});
  const [activeNote, setActiveNote] = useState(
    _.get(location, "state.noteId", -1)
  );
  const [loaderblogs, setLoaderblogs] = useState(false);
  const [loaderstories, setLoaderstories] = useState(false);
  const [loaderarticles, setLoaderarticles] = useState(false);
  const [writePad, setWritePad] = useState(false);
  const [beginClick, setBeginClick] = useState(false);

  const [isRedirectFromRegister] = useState(
    _.get(location, "state.isRedirectFromRegister", false)
  );
  const [isRedirectFromLogin] = useState(
    _.get(location, "state.isRedirectFromLogin", false)
  );
  const [open, setOpen] = useState(
    // _.get(location, "state.isRedirectFromRegister", false) ? true : false
    true
  );

  const { auth } = useContext(AuthContext);
  const { isNightMode } = useContext(RootContext);

  const { boot, shutdown } = useIntercom();

  const [onNotePointerUpEvent, setOnNotePointerUpEvent] = useState({});

  const [freeTrialDialogOpen, setFreeTrialDialog] = useState(
    auth.userData.UserMustWithSubscription &&
      !(
        _.get(auth, "userData.subsciption[0].status", "") === "active" ||
        _.get(auth, "userData.subsciption[0].status", "") === "trialing"
      ) &&
      !auth.userData.TrialTaken
  );
  useEffect(() => {
    setFreeTrialDialog(
      auth.userData.UserMustWithSubscription &&
        !(
          _.get(auth, "userData.subsciption[0].status", "") === "active" ||
          _.get(auth, "userData.subsciption[0].status", "") === "trialing"
        ) &&
        !auth.userData.TrialTaken
    );
  }, [auth.userData]);
  const history = useHistory();
  useEffect(() => {
    if (
      _.get(location, "state.isRedirectFromRegister", false) ||
      _.get(location, "state.isRedirectFromLogin", false)
    )
      history.replace(location.pathname, {
        ..._.get(location, "state", {}),
        isRedirectFromRegister: false,
        isRedirectFromLogin: false,
      });
    if (window.innerWidth > 1150)
      boot({
        email: _.get(auth, "userData.Email", ""),
        name:
          _.get(auth, "userData.FirstName", "") +
          " " +
          _.get(auth, "userData.LastName", ""),
      });
    return () => {
      if (window.innerWidth > 1150) shutdown();
    };
  }, []);

  const typeId = activeTab === "blogs" ? 1 : activeTab === "stories" ? 2 : 3;

  const getAndSetblogNotes = async (isInit = false) => {
    setLoaderblogs(true);
    const page = isInit ? 1 : _.get(blogList, "pagination.currentPage", 1) + 1;
    const res = await getAllNotes(page, 1, search);
    if (isInit) setBlogList(_.get(res, "data.notes", {}));
    else {
      let notes = _.get(res, "data.notes", {});
      notes.rows = [..._.get(blogList, "rows", []), ...notes.rows];
      notes.rows = _.uniqWith(notes.rows, _.isEqual);
      setBlogList(notes);
    }
    setLoaderblogs(false);
  };
  const getAndSetStoryNotes = async (isInit = false) => {
    setLoaderstories(true);
    const page = isInit ? 1 : _.get(storyList, "pagination.currentPage", 1) + 1;
    const res = await getAllNotes(page, 2, search);
    if (isInit) setStoryList(_.get(res, "data.notes", {}));
    else {
      let notes = _.get(res, "data.notes", {});
      notes.rows = [..._.get(storyList, "rows", []), ...notes.rows];
      notes.rows = _.uniqWith(notes.rows, _.isEqual);
      setStoryList(notes);
    }
    setLoaderstories(false);
  };
  const getAndSetArticleNotes = async (isInit = false) => {
    setLoaderarticles(true);
    const page = isInit
      ? 1
      : _.get(articleList, "pagination.currentPage", 1) + 1;
    const res = await getAllNotes(page, 3, search);
    if (isInit) setArticleList(_.get(res, "data.notes", {}));
    else {
      let notes = _.get(res, "data.notes", {});
      notes.rows = [..._.get(articleList, "rows", []), ...notes.rows];
      notes.rows = _.uniqWith(notes.rows, _.isEqual);
      setArticleList(notes);
    }
    setLoaderarticles(false);
  };

  useEffect(() => {
    autosize(document.querySelectorAll("textarea"));
    getAndSetblogNotes(true);
    getAndSetStoryNotes(true);
    getAndSetArticleNotes(true);
  }, [search]);
  useLayoutEffect(() => {
    autosize.update(document.querySelectorAll("textarea"));
  });

  const noteList = () => {
    if (typeId === 1) return blogList;
    else if (typeId === 2) return storyList;
    return articleList;
  };

  const onLoadMore = () => {
    if (typeId === 1) return getAndSetblogNotes();
    else if (typeId === 2) return getAndSetStoryNotes();
    return getAndSetArticleNotes();
  };

  let getActiveTitleNote = {};

  const notes = _.filter(
    [
      ..._.get(blogList, "rows", []),
      ..._.get(articleList, "rows", []),
      ..._.get(storyList, "rows", []),
    ],
    (note) => {
      return note.id === activeNote;
    }
  );

  if (notes.length > 0) {
    getActiveTitleNote = notes[0];
  }

  const editCallBack = (data) => {
    const type = _.get(data, "noteType", 1);
    let oldList = blogList;
    let updateFn = setBlogList;
    if (type === 2) {
      oldList = storyList;
      updateFn = setStoryList;
    } else if (type === 3) {
      oldList = articleList;
      updateFn = setArticleList;
    }

    const index = _.findIndex(
      _.get(oldList, "rows", []),
      (item) => item.id === data.id
    );
    if (index > -1) {
      oldList.rows[index] = data;
      updateFn({ ...oldList });
    } else {
      let newList = _.filter(
        _.get(oldList, "rows", []),
        (item) => item.id !== data.id
      );
      updateFn({ ...oldList, rows: [data, ...newList] });
      setActiveNote(data.id);
    }
  };

  const deleteCallback = (id, type) => {
    let oldList = blogList;
    let updateFn = setBlogList;
    if (type === 2) {
      oldList = storyList;
      updateFn = setStoryList;
    } else if (type === 3) {
      oldList = articleList;
      updateFn = setArticleList;
    }

    if (_.isArray(id)) {
      const newList = _.filter(
        _.get(oldList, "rows", []),
        (item) => id.indexOf(item.id) === -1
      );
      updateFn({ ...oldList, rows: [...newList] });
      if (id.indexOf(activeNote) > -1) {
        setActiveNote(-1);
        setWritePad(true);
      }
    } else {
      const newList = _.filter(
        _.get(oldList, "rows", []),
        (item) => item.id !== id
      );
      updateFn({ ...oldList, rows: [...newList] });
      if (activeNote === id) {
        setActiveNote(-1);
        setWritePad(true);
      }
    }
  };

  const startNow = (selectedBox) => {
    if (selectedBox === "general") {
      setActiveTab("blogs");
    } else if (selectedBox === "story") {
      setActiveTab("stories");
    } else if (selectedBox === "article") {
      setActiveTab("articles");
    }

    document.getElementById("login-overlay-modal").style.width = "0%";
  };

  return (
    <div className={isNightMode ? "night-mode" : ""}>
      <SlidMenu
        noteList={noteList()}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onLoadMore={onLoadMore}
        loader={{
          blogs: loaderblogs,
          stories: loaderstories,
          articles: loaderarticles,
        }}
        activeNote={activeNote}
        setActiveNote={setActiveNote}
        search={search}
        setSearch={setSearch}
        deleteCallback={deleteCallback}
        setWritePad={setWritePad}
        open={open}
        setOpen={setOpen}
      >
        <Header bgWhite />
        {getActiveTitleNote.type === 2 ? (
          <NoteFullView
            noteId={getActiveTitleNote.id}
            deleteCallback={deleteCallback}
          />
        ) : (
          <>
            <div
              className="create-new-mobile"
              onClick={() => {
                trackButtonClick("Dashboard", "Create New");
                setWritePad(true);
                setActiveNote(-1);
              }}
            >
              <img src={circleAdd} alt="circleAdd" />
            </div>
            <Box
              className="Dashboard-wrapper"
              onPointerUp={(e) => {
                setOnNotePointerUpEvent({ ..._.cloneDeep(e) });
              }}
            >
              <WritingPad
                closeSideMenu={() => {
                  if (open) setOpen(false);
                }}
                activeNote={getActiveTitleNote}
                activeNoteId={activeNote}
                editCallBack={editCallBack}
                typeId={typeId}
                deleteCallback={deleteCallback}
                setWritePad={setWritePad}
                writePad={writePad}
                setActiveNote={setActiveNote}
                setActiveTab={setActiveTab}
                onNotePointerUpEvent={onNotePointerUpEvent}
                isRedirectFromRegister={isRedirectFromRegister}
              />
            </Box>
          </>
        )}
      </SlidMenu>

      {isRedirectFromRegister ? (
        <>
          <TourModal begin={() => setBeginClick(true)} />
          {beginClick && <LoginModal startNow={startNow} />}
        </>
      ) : (
        isRedirectFromLogin && <LoginModal startNow={startNow} />
      )}
      <FreeTrialDialog
        dialogOpen={freeTrialDialogOpen}
        setDialogOpen={setFreeTrialDialog}
      />
      <WritelyCHATTag />
    </div>
  );
};

export default Dashboard;
