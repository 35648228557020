import { Menu, MenuItem } from "@material-ui/core";
import AuthContext from "contexts/authContext";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Paths } from "utils/constant";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import _ from "lodash";
import "./style.css";
import Footer from "component/Footer";
import { trackButtonClick } from "utils";

const AccountLayout = (props) => {
  const { style = {}, hideFooter = false, backClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { pathname } = useLocation();
  const history = useHistory();
  const { changeAuth, auth } = useContext(AuthContext);

  const handleClick = (path) => {
    history.push(path, { dontWantTour: true });
  };

  return (
    <>
      <div className="account-sidemenu">
        <div className="header">
          <span className="user-mail">
            <ArrowBackIcon
              className="hover-zoom"
              onClick={() => {
                trackButtonClick("Account", "Back");
                if (backClick) {
                  backClick();
                } else {
                  handleClick(Paths.dashboard);
                }
              }}
            />
            <span
              className="hover-zoom"
              onClick={() => {
                trackButtonClick("Account", "Back");
                if (backClick) {
                  backClick();
                } else {
                  handleClick(Paths.dashboard);
                }
              }}
            >
              {" "}
              Dashboard
            </span>
          </span>
          <MenuIcon
            style={{ color: "#454041" }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            fontSize="large"
            className="pointer"
            onClick={handleOpenClick}
          />
        </div>
        <div className="flex">
          {false && (
            <div className="sidemenu">
              <div
                className="item"
                onClick={() => {
                  trackButtonClick("Account", "Dashboard");
                  handleClick(Paths.dashboard);
                }}
              >
                Dashboard
              </div>
              <div
                className={`item ${
                  pathname === Paths.upgradeAccount ? "active" : ""
                }`}
                onClick={() => {
                  trackButtonClick(
                    "Account",
                    _.get(auth, "userData.subsciption[0].status", "") ===
                      "active"
                      ? "Subscription"
                      : "Pricing"
                  );
                  handleClick(Paths.upgradeAccount);
                }}
              >
                {_.get(auth, "userData.subsciption[0].status", "") ===
                  "active" ||
                _.get(auth, "userData.subsciption[0].status", "") === "trialing"
                  ? "Subscription"
                  : "Pricing"}
              </div>
              <div
                className="item"
                onClick={() => {
                  trackButtonClick("Account", "Contact Us");
                  handleClick(Paths.contactUs);
                }}
              >
                Contact Us
              </div>
              <div
                className="item"
                onClick={() => {
                  trackButtonClick("Account", "Log out");
                  changeAuth({ isLoggedIn: false, userData: {} });
                  history.push(Paths.login);
                }}
              >
                Log out
              </div>
            </div>
          )}
          <div className="content" style={{ ...style }}>
            {props.children}
          </div>
        </div>
        <Menu
          id="top-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{
            marginTop: "45px",
            marginRight: "50px",
            borderRadius: "110px",
          }}
          disableAutoFocus
          onClose={handleClose}
        >
          <MenuItem
            style={{ width: "180px" }}
            onClick={() => {
              trackButtonClick(
                "Account",
                pathname === Paths.dashboard ? "Account" : "Dashboard"
              );
              handleClick(Paths.dashboard);
              handleClose();
            }}
          >
            {pathname === Paths.dashboard ? "Account" : "Dashboard"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              trackButtonClick(
                "Account",
                _.get(auth, "userData.subsciption[0].status", "") === "active"
                  ? "Subscription"
                  : "Pricing"
              );
              handleClick(Paths.upgradeAccount);
              handleClose();
            }}
          >
            {_.get(auth, "userData.subsciption[0].status", "") === "active" ||
            _.get(auth, "userData.subsciption[0].status", "") === "trialing"
              ? "Subscription"
              : "Pricing"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              trackButtonClick("Account", "Password");
              handleClick(Paths.resetPassword);
              handleClose();
            }}
          >
            Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              trackButtonClick("Account", "Contact");
              handleClick(Paths.contactUs);
              handleClose();
            }}
          >
            Contact
          </MenuItem>
          <MenuItem
            onClick={() => {
              trackButtonClick("Account", "Log out");
              changeAuth({ isLoggedIn: false, userData: {} });
              handleClick(Paths.login);
              handleClose();
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </div>
      {!hideFooter && <Footer></Footer>}
    </>
  );
};

export default AccountLayout;
