import AuthContext from "contexts/authContext";
import _ from "lodash";
import LetteredAvatar from "react-lettered-avatar";
import React, { useContext } from "react";
import { useLocation } from "react-router";

const ProfileSection = (props) => {
  const { onCloseClick } = props;
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  return (
    <div className="profile-section">
      <div className="profile-image">
        <LetteredAvatar
          name={_.get(auth, "userData.FirstName", "")}
          size={44}
        />
      </div>
      <div className="profile-name">
        <div className="greeting">
          Welcome {location?.state?.isRedirectFromRegister ? "Aboard" : "Back"}
        </div>
        👋
        <div className="name">
          {_.get(auth, "userData.FirstName", "")}{" "}
          {_.get(auth, "userData.LastName", "")}
        </div>
      </div>
      <div className="close-btn" onClick={onCloseClick}>
        <svg
          width="17"
          height="44"
          viewBox="0 0 17 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 10C0 4.47715 4.47715 0 10 0H17V44H10C4.47715 44 0 39.5228 0 34V10Z"
            fill="#CB70BA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.19201 22.006L9.86399 17.1976C10.136 16.9341 10.552 16.9341 10.808 17.1976C11.064 17.4611 11.064 17.8893 10.808 18.1692L6.59998 22.5L10.808 26.8308C11.064 27.0943 11.064 27.5389 10.808 27.8024C10.552 28.0659 10.136 28.0659 9.86399 27.8024L5.19201 22.9775C4.936 22.714 4.936 22.2859 5.19201 22.006V22.006Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default ProfileSection;
