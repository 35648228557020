import { footerImg } from "assestes";
import React from "react";
import { useHistory } from "react-router-dom";
import { trackButtonClick } from "utils";
import { Paths } from "utils/constant";
import "./style.css";

function Footer() {
  const history = useHistory();
  return (
    <div className="footer-section">
      <div className="section-container">
        <div className="img-part">
          <img
            className="left-part-img"
            src={footerImg}
            alt="landing page rephase"
          />
          <div className="img-part-text">© Writely AI 2024</div>
        </div>
        <div className="text-part">
          <div className="text-wrap">
            <div className="item">
              <p className="item-title">EXPLORE</p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Pricing");
                  document.getElementById("pricing").scrollIntoView();
                }}
              >
                Pricing
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "FAQs");
                  document.getElementById("faqs").scrollIntoView();
                }}
              >
                FAQs
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Contact");
                  history.push(Paths.contact);
                }}
              >
                Contact
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Log In");
                  history.push(Paths.login);
                }}
              >
                Log In
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Sign Up");
                  history.push(Paths.signUp);
                }}
              >
                Sign Up
              </p>
            </div>
            <div className="item px-94">
              <p className="item-title">LEGAL</p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Terms and Conditions");
                  window.open(
                    "https://app.termly.io/document/terms-of-use-for-website/6e8efdd6-19b4-4324-8a69-f326011728d8",
                    "_blank"
                  );
                }}
              >
                Terms and Conditions
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Privacy");
                  window.open(
                    "https://app.termly.io/document/privacy-policy/1df0da24-d9c9-44d7-a9f2-003bfc0ba730",
                    "_blank"
                  );
                }}
              >
                Privacy
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Cookies");
                  window.open(
                    "https://app.termly.io/document/cookie-policy/ecf43297-9c39-4d9f-abff-8546699e6b4b",
                    "_blank"
                  );
                }}
              >
                Cookies
              </p>
            </div>
            <div className="item">
              <p
                className="item-title"
                onClick={() => {
                  trackButtonClick("Footer", "FOLLOW");
                }}
              >
                FOLLOW
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "LinkedIn");
                }}
              >
                LinkedIn
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Instagram");
                }}
              >
                Instagram
              </p>
              <p
                className="item-text"
                onClick={() => {
                  trackButtonClick("Footer", "Twitter");
                }}
              >
                Twitter
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
