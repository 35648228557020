import { Box, Grid } from "@material-ui/core";
import React from "react";
import { trackButtonClick } from "utils";

const TabWrapper = (props) => {
  const { teamMode, activeTab, setActiveTab, pendingRequests = 0 } = props;
  return (
    <div className="tab-wrapper">
      <Grid container className="tab-grid">
        {teamMode ? (
          <>
            <Grid item xs={6}>
              <Box
                className={`tab-item team-mode-item ${
                  activeTab === "workspaces" ? "active" : ""
                }`}
                onClick={() => {
                  trackButtonClick("Side Menu", "Workspaces");
                  setActiveTab("workspaces");
                }}
              >
                Workspaces
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={`tab-item team-mode-item ${
                  activeTab === "requests" ? "active" : ""
                }`}
                onClick={() => {
                  trackButtonClick("Side Menu", "requests");
                  setActiveTab("requests");
                }}
              >
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  Requests
                  {pendingRequests > 0 && (
                    <>
                      <span
                        style={{
                          background: "#1eaaf1",
                          padding: "0px 5px",
                          borderRadius: "18px",
                          fontSize: "10px",
                          color: "white",
                          position: "absolute",
                          top: "50%",
                          transform: "translate(0px, -50%)",
                          right: "-23px",
                          lineHeight: "14px",
                        }}
                      >
                        1
                      </span>
                    </>
                  )}
                </span>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4}>
              <Box
                className={`tab-item ${
                  activeTab === "articles" ? "active" : ""
                }`}
                onClick={() => {
                  trackButtonClick("Side Menu", "Article");
                  setActiveTab("articles");
                }}
              >
                Article
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className={`tab-item ${
                  activeTab === "stories" ? "active" : ""
                }`}
                onClick={() => {
                  trackButtonClick("Side Menu", "Story");
                  setActiveTab("stories");
                }}
              >
                Story
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className={`tab-item ${activeTab === "blogs" ? "active" : ""}`}
                onClick={() => {
                  trackButtonClick("Side Menu", "Email");
                  setActiveTab("blogs");
                }}
              >
                Email
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default TabWrapper;
